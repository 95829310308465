import { Session } from '@/utils/storage.js';

const authsModule = {
    namespaced: true,
    state: {
        auths: {},
    },
    mutations: {
        // 设置权限信息
        getAuths(state, data) {
            state.auths = data;
        },
    },
    actions: {
        // 设置权限信息
        async setAuths({ commit }, data) {
            if (data) {
                commit('getAuths', data);
            } else {
                if (Session.get('auths')) commit('getAuths', Session.get('auths'));
            }
        },
    },
};

export default authsModule;