<template>
	<div>
		<div  style="padding: 15px;margin-bottom:25px;">
			<div class="header">
				<div class="mark-title" style="font-size: 16px; height: 36px; line-height: 36px;">会员信息</div>
			</div>
			<el-form :model="member" label-width="80px" v-loading="loading">
				<el-form-item label="名称">
					<el-input v-model="member.name" placeholder="请输入名称" clearable style="width: 380px"></el-input>
				</el-form-item>
				<el-form-item label="成长值">
					<el-input v-model="member.reach_growth" placeholder="请输入成长值" clearable style="width: 380px"></el-input>
				</el-form-item>
				<el-form-item label="会员权益">
					<el-select v-model="member.useful_ids" multiple style="width: 480px" placeholder="请选择">
						<el-option v-for="item in useful_lists" :key="item.id" :label="item.name" :value="item.id"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="状态"> <el-switch v-model="member.status"></el-switch></el-form-item>
				<el-form-item label="简介">
					<el-input type="textarea" v-model="member.remark" clearable :autosize="{ minRows: 4, maxRows: 6 }" maxlength="150" show-word-limit style="width: 530px"></el-input>
				</el-form-item>
			</el-form>
		</div>
		
		<div class="bottom-fixed">
			<div class="box-item">
				<el-button size="medium" @click="closeEvent()">取 消</el-button>
				<el-button size="medium" type="primary" @click="submitTap()">立即提交</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { member_post, member_get, useful_list } from '@/api/user';
export default {
	name: 'member_detail',
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: ()=>({}),
		},
	},
	data() {
		return {
			member: {},
			roles: [],
			useful_lists:[],
			loading: true,
			dialogImageUrl: '',
			dialogVisible: false,
			maxLimit: 1,
			treeData: [],
			activeName: 'info'
		};
	},
	created() {
		this.initData();
	},
	mounted() {
		if (Object.keys(this.data).length > 0) {
			member_get(this.data.id)
				.then((res) => {
					this.member = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
		}else{
			this.member = {};
		}
		useful_list()
			.then((res) => {
				this.useful_lists = res.data;
			})
			.finally(() => {
				this.loading = false;
			});
	},
	methods: {
		initData() {
			this.loading = false;
		},
		closeEvent() {
			this.$emit('closeTap');
		},
		submitTap() {
			member_post(this.member)
				.then((res) => {
					if (res.statusCode === 200) {
						Message.success(res.message);
						this.$emit('closeTap');
					}
				});
		},
	},
};
</script>

<style scoped lang="scss">
	.tb-edit .el-input,
	.tb-edit .el-select {
		display: none;
	}
	.tb-edit .current-row .el-input,
	.tb-edit .current-row .el-select {
		display: block;
	}
	.tb-edit .current-row .el-input + span,
	.tb-edit .current-row .el-select + span {
		display: none;
	}
	.el-radio__input {
		display: none !important;
	}
</style>
