import request from '@/utils/request';

export function goods_list(params) {
    return request({
        url: '/goods/lists',
        method: 'get',
        params: params,
    });
}

export function goods_index(params) {
    return request({
        url: '/goods',
        method: 'get',
        params: params,
    });
}

export function goods_post(params) {
    return request({
        url: '/goods',
        method: 'post',
        data: params,
    });
}

export function goods_get(id, params) {
    return request({
        url: '/goods/' + id,
        method: 'get',
        params: params,
    });
}

export function goods_del(id, params = '') {
    return request({
        url: '/goods/' + id,
        method: 'delete',
        params: params,
    });
}

export function goods_put(params) {
    return request({
        url: '/goods',
        method: 'put',
        params: params,
    });
}

export function category_index(params) {
    return request({
        url: '/category',
        method: 'get',
        params: params,
    });
}

export function category_import(params) {
    return request({
        url: '/category/import',
        method: 'get',
        params: params,
    });
}

export function category_list(params) {
    return request({
        url: '/category/lists',
        method: 'get',
        params: params,
    });
}

export function category_post(params) {
    return request({
        url: '/category',
        method: 'post',
        data: params,
    });
}

export function category_get(id, params) {
    return request({
        url: '/category/' + id,
        method: 'get',
        params: params,
    });
}

export function category_del(id, params = '') {
    return request({
        url: '/category/' + id,
        method: 'delete',
        params: params,
    });
}

export function category_put(params) {
    return request({
        url: '/category',
        method: 'put',
        params: params,
    });
}

export function freight_index(params) {
    return request({
        url: '/freight',
        method: 'get',
        params: params,
    });
}
export function freight_list(params) {
    return request({
        url: '/freight/lists',
        method: 'get',
        params: params,
    });
}

export function freight_post(params) {
    return request({
        url: '/freight',
        method: 'post',
        data: params,
    });
}

export function freight_get(id, params) {
    return request({
        url: '/freight/' + id,
        method: 'get',
        params: params,
    });
}

export function freight_del(id, params = '') {
    return request({
        url: '/freight/' + id,
        method: 'delete',
        params: params,
    });
}

export function freight_put(params) {
    return request({
        url: '/freight',
        method: 'put',
        params: params,
    });
}

export function coupon_index(params) {
    return request({
        url: '/coupon',
        method: 'get',
        params: params,
    });
}

export function coupon_import(params) {
    return request({
        url: '/coupon/import',
        method: 'get',
        params: params,
    });
}

export function coupon_list(params) {
    return request({
        url: '/coupon/lists',
        method: 'get',
        params: params,
    });
}

export function coupon_post(params) {
    return request({
        url: '/coupon',
        method: 'post',
        data: params,
    });
}

export function coupon_get(id, params) {
    return request({
        url: '/coupon/' + id,
        method: 'get',
        params: params,
    });
}

export function coupon_del(id, params = '') {
    return request({
        url: '/coupon/' + id,
        method: 'delete',
        params: params,
    });
}

export function coupon_put(params) {
    return request({
        url: '/coupon',
        method: 'put',
        params: params,
    });
}