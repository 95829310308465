<template>
	<div class="system-menu-container">
		<div style="padding: 15px">
			<el-form :model="lexicon" size="small" label-width="100px" class="mt15 mb15">
				<el-row>
					<el-col :span="6">
						<el-form-item label="名称">
							<el-input v-model="lexicon.name" placeholder="请输入名称" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="Key值">
							<el-input v-model="lexicon.key" placeholder="请输入Key值" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="Value值">
							<el-input v-model="lexicon.value" placeholder="请输入Value值" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="状态">
							<el-select v-model="lexicon.status" placeholder="请选择状态" clearable class="w100">
								<el-option label="开启" value="1"></el-option>
								<el-option label="禁用" value="2"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item align="right">
							<el-button icon="el-icon-refresh">清空</el-button>
							<el-button type="primary" icon="el-icon-search" @click="submitTap()">立即查询</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<el-card shadow="never">
				<div style="padding-bottom: 15px">
					<el-row>
						<el-button type="danger" size="small" icon="el-icon-delete">批量删除</el-button>
						<el-button type="primary" size="small" icon="el-icon-edit" @click="openVisible()">添加字典</el-button>
					</el-row>
				</div>
				<el-table v-loading="loading" :data="tableData" stripe style="width: 100%">
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column prop="name" label="名称"></el-table-column>
					<el-table-column label="状态">
						<template #default="scope">
							<span v-if="scope.row.status" class="color-primary">正常</span>
							<span v-else class="color-danger">禁用</span>
						</template>
					</el-table-column>
					<el-table-column label="Key值">
						<template #default="scope">
							<span>{{ scope.row.key }}</span>
						</template>
					</el-table-column>
					<el-table-column label="Value值">
						<template #default="scope">
							<span>{{ scope.row.value }}</span>
						</template>
					</el-table-column>
					<el-table-column label="排序" sortable>
						<template #default="scope">
							<span>{{ scope.row.sort }}</span>
						</template>
					</el-table-column>
					<el-table-column label="创建日期" sortable>
						<template #default="scope">
							<span>{{ scope.row.created_at }}</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="125">
						<template #default="scope">
							<el-button size="mini" type="text" @click="openVisible(scope.row)">修改</el-button>
							<el-button size="mini" class="color-danger" type="text" @click="deleteTap(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="block" style="margin-top: 15px">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="pageInfo.now"
						:page-sizes="[10, 20, 50, 100, 200]"
						:page-size="pageInfo.size"
						layout="total, sizes, prev, pager, next, jumper"
						:total="pageInfo.count"
					>
					</el-pagination>
				</div>
			</el-card>
		</div>

		<detail v-if="visible" :data="rowData" @closeTap="handleClose"></detail>
	</div>
</template>

<script>
import detail from './component/detail.vue';
import { lexicon_index,lexicon_del } from '@/api/common';
export default {
	name: 'lexicon',
	components: { detail },
	data() {
		return {
			tableData: [],
			lexicon: {},
			rowData: {},
			visible: false,
			loading: true,
			pageInfo: {
				now: 1,
				size: 20
			},
		};
	},
	created() {
		this.initData();
	},
	methods: {
		initData() {
			this.loading = true;
			var param = {
				name: this.lexicon.name || undefined,
				key: this.lexicon.key || undefined,
				value: this.lexicon.value || undefined,
				status: this.lexicon.status || undefined,
				pageSize: this.pageInfo.size || undefined,
				pageNow: this.pageInfo.now || undefined,
			};
			lexicon_index({ ...param })
				.then((res) => {
					if (res.statusCode !== 200) {
						this.$message({
							type: 'error',
							message: res.message,
						});
						return true;
					}
					this.pageInfo = res.page;
					this.tableData = res.lists;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		submitTap() {
			this.loading = true;
			this.initData();
		},
		handleClose() {
			this.visible = false;
			this.initData();
		},
		openVisible(data = null) {
			if (data !== null) {
				this.rowData = data;
			}
			this.visible = true;
		},
		handleSizeChange(val) {
			this.pageInfo.size = val;
			this.initData();
		},
		handleCurrentChange(val) {
			this.pageInfo.now = val;
			this.initData();
		},
		batchDelTap() {
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});
		},
		deleteTap(e) {
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.loading = true;
				lexicon_del(e.id).then((res) => {
					if (res.statusCode === 200) {
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
					}
					this.loading = false;
				}).catch(() => {});
			});
		}
	},
};
</script>
