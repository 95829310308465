<template>
	<div class="lum-dialog-mask">
		<el-container class="lum-dialog-box" :class="{ 'full-screen': fullscreen }">
			<el-header height="60px" class="header">
				<p>意见反馈</p>
				<p class="title">
					<span>意见反馈</span>
				</p>
				<p class="tools">
					<i
						class="iconfont"
						style="transform: scale(0.85)"
						:class="fullscreen ? 'icon-tuichuquanping' : 'icon-quanping'"
						@click="fullscreen = !fullscreen"
					/>
					<i class="el-icon-close" @click="closeEvent()" />
				</p>
			</el-header>
			<el-container class="full-height ov-hidden">
				<el-form :model="feedback" label-width="80px" style="padding: 15px; margin-bottom: 36px">
					<el-row :gutter="20">
						<el-col :span="24">
							<el-form-item label="反馈标题">
								<el-input v-model="feedback.title" placeholder="请输入标题" clearable style="width: 380px"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="反馈类型">
								<el-select v-model="feedback.type" placeholder="请选择类型" style="width: 280px">
									<el-option v-for="item in lexicons.bug_type" :key="item.id" :label="item.name" :value="item.value"> </el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="反馈内容">
								<mavon-editor
									ref="mavonEditor"
									:toolbars="markdown.toolbars"
									:subfield="false"
									:ishljs="false"
									:boxShadow="false"
									:externalLink="false"
									fontSize="14px"
									previewBackground="#fff"
									placeholder="请输入您的文档正文 ..."
									class="editor full-height"
									@save="editorSave"
									@change="editorChange"
									@imgAdd="editorUploadImage"
									style="border: 1px solid #dcdfe6"
								/>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</el-container>
			<el-footer height="60px" class="footer">
				<div style="width: 178px">
					<el-button size="medium" @click="closeEvent()">取消</el-button>
					<el-button size="medium" type="primary" @click="submitTap()">确认提交</el-button>
				</div>
			</el-footer>
		</el-container>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { feedback_post } from '@/api/feedback';
import { file_upload,lexicon_lists } from '@/api/common';
import { mavonEditor } from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
export default {
	name: 'fullscreen',
	components: {
		mavonEditor,
	},
	data() {
		return {
            lexicons:{},
			feedback: {},
			fullscreen: false,
			// 编辑器相关信息
			markdown: {
				toolbars: {
					bold: true, // 粗体
					italic: true, // 斜体
					header: true, // 标题
					underline: true, // 下划线
					strikethrough: true, // 中划线
					mark: true, // 标记
					ol: true, // 有序列表
					ul: true, // 无序列表
					link: true, // 链接
					imagelink: true, // 图片链接
					alignleft: true, // 左对齐
					aligncenter: true, // 居中
					alignright: true, // 右对齐
					subfield: true,
				},
			},
		};
	},
	created() {
		this.initData();
	},
	methods: {
		initData() {
			this.loading = true;
			var param = {
                key: 'bug_type'
            };
            lexicon_lists(param)
                .then((res) => {
					if (res.statusCode == 200) {
						this.lexicons = res.data;
					}
                })
                .finally(() => {
                    this.loading = false;
                });
		},

		// 编辑器保存回调事件
		editorSave() {
			this.triggerSave();
		},

		
		closeEvent() {
			this.$emit('closeTap');
		},

		// 编辑器修改回调事件
		editorChange(value, render) {
			this.feedback.md_content = value;
			this.feedback.content = render;
		},

		// 编辑器上传图片回调事件
		editorUploadImage(pos, file) {
			let formdata = new FormData();
			formdata.append('file', file);

			let save_path = '';
			file_upload(formdata)
				.then((res) => {
					if (res.statusCode != 200) return false;
					save_path = res.data.file_path || '';
				})
				.catch(() => {
					this.$refs.mavonEditor.$img2Url(pos, save_path);
				});
		},

		submitTap(){
			feedback_post(this.feedback)
				.then((res) => {
					if (res.statusCode == 200) {
						Message.success(res.message);
						this.$emit('closeTap');
					}
				})
				.catch(() => {});
		}
	},
};
</script>
<style lang="less" scoped>
/deep/.el-scrollbar__wrap {
	overflow-x: hidden;
}

.lum-dialog-mask {
	z-index: 9999;
}

.lum-dialog-box {
	width: 100%;
	height: 620px;
	max-width: 800px;
	transition: 1s ease;
	&.full-screen {
		width: 100%;
		height: 100%;
		max-width: unset;
		margin: 0;
		border-radius: 0px;
	}
	.select_box {
		margin: 15px;
		border: 1px solid #dddd;
		border-radius: 3px;
		height: 100px;
		.el-tag {
			margin: 3px;
		}
	}
}
</style>