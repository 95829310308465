<template>
	<div id="note-preview" :class="{ 'full-screen': fullscreen }">
		<div v-if="mode == 0" class="empty-mode">
			<p>
				<span>(空)</span>
			</p>
		</div>
		<div v-else class="other-mode">
			<el-container class="ov-hidden full-height">
				<!-- 编辑器模式 -->
				<el-container v-if="mode == 2" class="full-height ov-hidden editor-mode">
					<el-header height="53px">
						<span>标题：</span><input v-model="note.title" type="text" placeholder="标题不能为空..." @keydown.stop="handlerKeyChange" />
					</el-header>
					<div style="min-height: 53px; line-height: 43px">
						<span>收件人：</span>
						<el-button icon="el-icon-plus" size="mini" circle style="margin: 0 6px" @click="selectRang()"></el-button>
						<el-tag
							v-for="item in note.clump_ids"
							:key="item.id"
							:type="item.type == 'jobs' ? 'warning' : item.type == 'organic' ? 'danger' : ''"
							closable
							style="margin-right: 5px"
						>
							{{ item.name }}
						</el-tag>
					</div>
					<mavon-editor
						ref="mavonEditor"
						v-model="note.md_content"
						:toolbars="markdown.toolbars"
						:subfield="false"
						:ishljs="false"
						:boxShadow="false"
						:externalLink="false"
						fontSize="14px"
						previewBackground="#fff"
						placeholder="请输入您的消息正文 ..."
						class="editor full-height"
						@save="editorSave"
						@change="editorChange"
						@imgAdd="editorUploadImage"
					/>
				</el-container>

				<!-- 预览模式 -->
				<el-container v-else class="full-height preview-mode ov-hidden">
					<el-header height="53px" style="line-height: 53px"> <span>标题：</span>{{ note.title }} </el-header>
					<div style="height: 53px; line-height: 43px">
						<span>收件人：</span>
						<el-tag
							v-for="item in note.clump_ids"
							:key="item.id"
							:type="item.type == 'jobs' ? 'warning' : item.type == 'organic' ? 'danger' : ''"
							closable
							style="margin-right: 5px"
						>
							{{ item.name }}
						</el-tag>
					</div>
					<el-main id="note-detail" class="ov-hidden">
						<el-scrollbar class="full-height" tag="section" :native="false">
							<div v-html="note.content" v-code id="note-info" class="markdown-body" />
						</el-scrollbar>
					</el-main>
				</el-container>

				<!-- 右侧工具栏 -->
				<el-aside width="50px" id="note-tools" class="no-select">
					<div class="item" @click="triggerFullScreen">
						<i class="el-icon-full-screen" />
						<p>{{ fullscreen ? '取消全屏' : '全屏' }}</p>
					</div>

					<div v-if="saveStatus == 1" class="item">
						<i class="el-icon-loading" />
						<p v-if="note.status == 1">保存中..</p>
						<p v-else>存储中..</p>
					</div>

					<div v-if="mode == 2 || note.status == 1" @click="triggerSave(1)" class="item">
						<i class="el-icon-edit-outline" />
						<p>保存</p>
					</div>

					<div v-if="mode == 2 || note.status == 0" @click="triggerSave(0)" class="item">
						<i class="el-icon-receiving" />
						<p>存草稿</p>
					</div>

					<div v-if="note.status == 0 && mode == 1" @click="mode = 2" class="item">
						<i class="el-icon-edit-outline" />
						<p>编辑</p>
					</div>

					<el-tooltip v-show="note.status == 1 && mode == 1" effect="dark" content="消息撤回后，账号将无法再阅读到该消息" placement="left">
						<div class="item">
							<i class="el-icon-refresh" />
							<p>撤回</p>
						</div>
					</el-tooltip>
				</el-aside>
			</el-container>
		</div>

		<assembly v-if="select" :data="note.clump_ids" @multiplesTags="multiplesTags" @closeTap="closeTap"></assembly>
	</div>
</template>
<script>
import PreCode from '@/directive/PreCode';
import { file_upload } from '@/api/common';
import { notice_get, notice_post } from '@/api/notice';
import { mavonEditor } from 'mavon-editor';
import assembly from '@/components/dialog/assembly.vue';
import 'mavon-editor/dist/css/index.css';

export default {
	name: 'NotePreview',
	components: {
		mavonEditor,
		assembly,
	},
	directives: {
		code: PreCode,
	},
	data() {
		return {
			note_id: 0, // 消息ID

			// 选择范围
			select: false,

			// 是否全屏显示
			fullscreen: false,

			// 渲染模式[0:空模式;1:预览模式;2:编辑模式;]
			mode: 0,

			// 数据加载状态[-1:未加载;0:加载中;1:加载成功;2:加载失败;]
			loadStatus: -1,

			//消息保存状态[0:正常;1:保存中;]
			saveStatus: 0,

			// 消息信息
			note: {
				id: 0,
				title: '',
				class_id: 0,
				clump_ids: [],
				md_content: '',
				content: '',
				status: -1,
				created_at: '',
			},
			inputVisible: false,
			inputValue: '',

			// 编辑器相关信息
			markdown: {
				toolbars: {
					bold: true, // 粗体
					italic: true, // 斜体
					header: true, // 标题
					underline: true, // 下划线
					strikethrough: true, // 中划线
					mark: true, // 标记
					ol: true, // 有序列表
					ul: true, // 无序列表
					link: true, // 链接
					imagelink: true, // 图片链接
					code: true, // code
					table: true, // 表格
					alignleft: true, // 左对齐
					aligncenter: true, // 居中
					alignright: true, // 右对齐
					subfield: true,
				},
			},
		};
	},
	methods: {
		// 触发全屏或关闭全屏模式
		triggerFullScreen() {
			this.fullscreen = !this.fullscreen;
		},

		handlerKeyChange(e) {
			if ((e.ctrlKey || e.metaKey) && e.keyCode === 83) {
				this.triggerSave();
				e.preventDefault();
			}
		},
		open(note_id, isEditor = false, class_id = 0) {
			this.note_id = note_id;
			if (this.note_id == 0) {
				this.triggerCancel();
				this.note.class_id = class_id;
				this.mode = 2;
			} else {
				this.loadData(isEditor);
			}
		},

		close() {
			this.triggerCancel();
			this.mode = 0;
		},

		selectRang() {
			this.select = true;
		},

		multiplesTags(tags) {
			this.note.clump_ids = tags;
			this.select = false;
		},

		// 加载消息信息
		loadData(isEditor = false) {
			this.loadStatus = 0;
			notice_get(this.note_id)
				.then((res) => {
					if (res.statusCode != 200) return false;
					this.note = res.data;
					this.mode = isEditor ? 2 : 1;
				})
				.catch(() => {
					this.loadStatus = 1;
				});
		},

		// 编辑器保存回调事件
		editorSave() {
			this.triggerSave();
		},

		// 编辑器修改回调事件
		editorChange(value, render) {
			this.note.md_content = value;
			this.note.content = render;
		},

		// 编辑器上传图片回调事件
		editorUploadImage(pos, file) {
			let formdata = new FormData();
			formdata.append('file', file);

			let save_path = '';
			file_upload(formdata)
				.then((res) => {
					if (res.statusCode != 200) return false;
					save_path = res.data.file_path || '';
				})
				.catch(() => {
					this.$refs.mavonEditor.$img2Url(pos, save_path);
				});
		},

		// 获取当前编辑消息相关信息
		getEditData() {
			return {
				notice_id: this.note.id,
				clump_ids: this.note.clump_ids,
				class_id: this.note.class_id,
				title: this.note.title,
				md_content: this.note.md_content,
				content: this.note.content,
			};
		},

		// 添加或更新消息
		triggerSave(status = 1) {
			let data = this.getEditData();
			if (data.title == '') {
				this.$message('消息标题不能为空!');
				return false;
			}

			if (data.md_content == '' || data.content == '') {
				this.$message('消息内容不能为空!');
				return false;
			}
			data.status = status;

			this.saveStatus = 1;
			let isNewSave = data.notice_id == 0;
			this.note.md_content = data.md_content;
			this.note.content = data.content;
			this.note.status = status;
			this.note.class_id = data.class_id;
			notice_post(data)
				.then((res) => {
					if (res.statusCode != 200) return false;
					if (isNewSave) {
						this.note.id = res.data.id;
						this.$emit('create', this.note.id);
					} else {
						this.$emit('change', {
							id: this.note.id,
							title: this.note.title,
							abstract: this.note.md_content.substr(0, 100),
						});
					}
				})
				.catch(() => {})
				.finally(() => {
					this.mode = 1;
					this.saveStatus = 0;
				});
		},

		triggerCancel() {
			this.note = {
				id: 0,
				class_id: 0,
				title: '',
				tags: [],
				files: [],
				md_content: '',
				content: '',
				is_asterisk: 0,
				status: -1,
				created_at: '',
			};
		},
	},
};
</script>
<style lang="less" scoped>
@import '~@/assets/css/markdown.css';
.el-main {
	padding: 0 !important;
}

#note-detail {
	border-top: none;
	border-bottom: none;
	background: #fff;
	height: 100%;
	padding: 0 5px;

	.markdown-body {
		padding: 20px;
		font-size: 16px;
	}
}

#note-detail /deep/pre {
	position: relative;
	padding-top: 15px;
	background-color: #f6f8fa;
	padding-bottom: 5px;

	.fz-btn {
		position: absolute;
		right: 0px;
		top: 0px;
		min-width: 50px;
		padding: 0 10px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		background: #ecebeb;
		color: #333131;
		cursor: pointer;
		font-size: 12px;
		font-weight: 100;
		font-family: 'Microsoft YaHei';
		opacity: 0;
		visibility: hidden;
		transition: ease 1s;
	}

	.lang-name {
		position: absolute;
		right: 0;
		top: 0;
		padding: 0 10px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		color: #c5b6b6;
		font-size: 12px;
	}

	code {
		display: block;
		max-width: auto;
		margin: 0;
		overflow: visible;
		line-height: inherit;
		word-wrap: normal;
		background-color: initial;
		border: 0;
		overflow-x: auto;
		padding-bottom: 10px;
	}

	&:hover {
		.fz-btn {
			opacity: 1;
			visibility: visible;
		}

		.lang-name {
			display: none;
		}
	}
}

#note-detail /deep/p code {
	padding: 2px 4px;
	color: #c7254e;
	background-color: #ffe8e8;
	margin: 0 3px;
	white-space: nowrap;
	border-radius: 2px;
}

#note-detail /deep/.token.important,
#note-detail /deep/.token.bold {
	font-weight: unset;
}

#note-preview {
	&.full-screen {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
	}

	width: 100%;
	height: 100%;
	z-index: 11;
	background: white;
}

.empty-mode {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 268px;
	> p {
		text-align: center;
		font-size: 12px;
		color: #b9b4b4;
	}
}

.editor-mode,
.preview-mode {
	/deep/.el-header {
		border-bottom: 1px solid #efecec;

		input {
			width: 90%;
			height: 40px;
			margin-top: 8px;
			margin-left: -8px;
			font-size: 18px;
			padding: 3px;
			margin-left: 10px;

			&::-webkit-input-placeholder {
				color: rgb(158, 150, 150);
				font-size: 14px;
			}
		}
	}
}

.preview-mode {
	.preview-header {
		line-height: 60px;
		font-size: 20px;
		font-weight: 700;
		overflow: hidden;
	}
}

#note-tools {
	border-left: 1px solid rgb(255, 255, 255);
	box-shadow: rgb(246, 245, 245) 0px 0px 6px 0px;
	padding-top: 3px;
	flex-shrink: 0;
	overflow: hidden;

	.item {
		width: 50px;
		height: 50px;
		margin: 5px auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		.i-color {
			color: red;
		}

		p {
			font-size: 10px;
			margin-top: 5px;
			transform: scale(0.9);
		}

		&:hover {
			color: #409eff;
		}
	}
}
</style>
