<template>
	<div class="lum-dialog-mask">
		<el-container class="lum-dialog-box" :class="{ 'full-screen': fullscreen }">
			<el-header height="60px" class="header">
				<p class="title">
					<span>概率组</span>
				</p>
				<p class="tools">
					<i
						class="iconfont"
						style="transform: scale(0.85)"
						:class="fullscreen ? 'icon-tuichuquanping' : 'icon-quanping'"
						@click="fullscreen = !fullscreen"
					/>
					<i class="el-icon-close" @click="$emit('closeTap')" />
				</p>
			</el-header>

			<el-main class="full-height ov-hidden" style="padding: 15px">
				<div style="padding: 5px 5px">
					<el-form :model="deal_group" label-width="80px">
						<el-row :gutter="20">
							<el-col :span="24">
								<el-form-item label="名称">
									<el-input v-model="deal_group.name" placeholder="请输入名称" clearable></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="描述">
									<el-input v-model="deal_group.remark" placeholder="请输入描述" clearable></el-input>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
				</div>
			</el-main>
			<el-footer height="45px" class="footer">
				<div style="width: 178px">
					<el-button size="mini" @click="closeEvent()">取消</el-button>
					<el-button size="mini" type="primary" @click="submitTap()">确认提交</el-button>
				</div>
			</el-footer>
		</el-container>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { box_deal_group_post } from '@/api/blind_box';
export default {
	name: 'add_group',
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
	},

	data() {
		return {
			fullscreen: false,
			deal_group: {},
		};
	},
	mounted() {
		if (this.data !== null) {
			this.deal_group = this.data;
		}
		this.isVisible = this.visible;
	},
	methods: {
		closeEvent() {
			this.$emit('closeTap');
		},
		submitTap() {
			box_deal_group_post(this.deal_group)
				.then((res) => {
					if (res.statusCode == 200) {
						Message.success(res.message);
						this.$emit('closeTap');
					}
				})
				.catch(() => {});
		},
	},
};
</script>
<style lang="less" scoped>
/deep/.el-scrollbar__wrap {
	overflow-x: hidden;
}

.lum-dialog-mask {
	z-index: 99;
}

.lum-dialog-box {
	width: 100%;
	height: 470px;
	max-width: 800px;
	transition: 1s ease;
	&.full-screen {
		width: 100%;
		height: 100%;
		max-width: unset;
		margin: 0;
		border-radius: 0px;
	}
}
</style>
