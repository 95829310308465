<template>
	<div class="system-menu-container">
		<div :class="visible ? 'box-modal-hide' : 'box-modal-show'" style="padding: 15px">
			<el-form :model="coupon" size="small" label-width="100px" class="mt15 mb15">
				<el-row>
					<el-col :span="6">
						<el-form-item label="名称">
							<el-input v-model="coupon.name" placeholder="请输入名称" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="状态">
							<el-select v-model="coupon.status" placeholder="请选择状态" clearable class="w100">
								<el-option label="正常" value="1"></el-option>
								<el-option label="关闭" value="2"></el-option>
							</el-select>
						</el-form-item>
					</el-col>

					<el-col :span="6">
						<el-form-item label="优惠券类型">
							<el-select v-model="coupon.coupon_type" placeholder="请选择状态" clearable class="w100">
								<el-option label="直减现金券" value="1"></el-option>
								<el-option label="满减现金券" value="2"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item align="right">
							<el-button icon="el-icon-refresh">清空</el-button>
							<el-button type="primary" icon="el-icon-search" @click="submitTap()">立即查询</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<el-card shadow="never">
				<div style="padding-bottom: 15px">
					<el-row>
						<el-button type="danger" size="small" icon="el-icon-delete" @click="batchDelTap()">批量删除</el-button>
						<el-button size="small" type="primary" icon="el-icon-edit" @click="openVisible()">添加优惠券</el-button>
					</el-row>
				</div>
				<el-table v-loading="loading" :data="tableData" stripe style="width: 100%">
					<el-table-column fixed type="selection" width="55"></el-table-column>
					<el-table-column fixed label="ID" width="85">
						<template #default="scope">
							<span>{{ scope.row.id }}</span>
						</template>
					</el-table-column>
					<el-table-column label="名称">
						<template #default="scope">
							<span>{{ scope.row.name }}</span>
						</template>
					</el-table-column>
					<el-table-column label="状态">
						<template #default="scope">
							<span v-if="scope.row.status == 1" class="color-primary">正常</span>
							<span v-else class="color-danger">下架</span>
						</template>
					</el-table-column>
					<el-table-column label="优惠券类型">
						<template #default="scope">
							<span v-if="scope.row.coupon_type == 1">直减现金券</span>
							<span v-else>满减现金券</span>
						</template>
					</el-table-column>
					<el-table-column label="优惠券金额">
						<template #default="scope">
							<span class="color-info">{{ scope.row.coupon_amount }}</span>
						</template>
					</el-table-column>
					<el-table-column label="满减金额">
						<template #default="scope">
							<span class="color-info">{{ scope.row.full_amount }}</span>
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="操作" width="155">
						<template #default="scope">
							<el-button size="mini" type="text" @click="importGoodsTap(scope.row)">导入商品</el-button>
							<el-button size="mini" type="text" @click="openVisible(scope.row)">编辑</el-button>
							<el-button size="mini" class="color-danger" type="text" @click="deleteTap(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="block" style="margin-top: 15px">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="pageInfo.now"
						:page-sizes="[10, 20, 50, 100, 200]"
						:page-size="pageInfo.size"
						layout="total, sizes, prev, pager, next, jumper"
						:total="pageInfo.count"
					>
					</el-pagination>
				</div>
			</el-card>
		</div>
		<detail v-if="visible" :type="type" :data="rowData" @closeTap="handleCloseTap"></detail>
		<goods v-if="select" :data="coupon.goods_ids" @multiplesIds="multiplesIds" @closeTap="closeTap"></goods>
	</div>
</template>

<script>
import { coupon_index, coupon_import, coupon_del } from '@/api/goods';
import detail from './component/detail.vue';
import goods from '@/components/dialog/goods.vue';

export default {
	components: { detail, goods },
	name: 'coupon',
	data() {
		return {
			coupon: {},
			tableData: [],
			pageInfo: {
				now: 1,
				size: 20
			},
			type: 1,
			rowData: {},
			rowId: 0,
			jobsList: [],
			loading: true,
			visible: false,
			select: false,
			select_id: 0,
		};
	},
	created() {
		this.initData();
	},
	watch: {
		$route(to) {
			if (to.query.couponId !== undefined) {
				this.checkDetail(to.query.couponId);
			}
		},
	},
	methods: {
		initData() {
			var param = {
				name: this.coupon.name || undefined,
				pageSize: this.pageInfo.size || undefined,
				pageNow: this.pageInfo.now || undefined,
			};
			coupon_index({ ...param })
				.then((res) => {
					if (res.statusCode !== 200) {
						this.$message({
							type: 'error',
							message: res.message,
						});
						return true;
					}
					this.pageInfo = res.page;
					this.tableData = res.lists;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		checkDetail(id) {
			this.rowId = parseInt(id);
			this.visible = true;
		},
		openVisible(data = null) {
			if (data !== null) {
				this.type = 1;
				this.rowData = data;
			} else {
				this.rowData = {}
			}
			this.visible = true;
		},
		deleteTap(e) {
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.loading = true;
				coupon_del(e.id)
					.then((res) => {
						if (res.statusCode == 200) {
							this.$message({
								type: 'success',
								message: res.message
							});
							this.initData();
						}
					})
					.finally(() => {
						this.loading = false;
					});
			});
		},
		batchDelTap() {
			this.loading = true;
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.loading = false;
			});
		},
		handleCheckTap(data) {
			this.loading = true;
			this.couponData = data;
			this.visible = true;
		},
		handleEditTap() {
			this.loading = true;
			this.checkShow = true;
		},
		submitTap(){
			this.loading = true;
			this.initData();
		},
		handleCloseTap() {
			this.visible = false;
			this.loading = true;
			this.initData();
		},
		handleSizeChange(val) {
			this.loading = true;
			this.pageInfo.size = val;
			this.initData();
		},
		handleCurrentChange(val) {
			this.loading = true;
			this.pageInfo.now = val;
			this.initData();
		},
		closeTap() {
			this.select = false;
		},
		importGoodsTap(e){
			this.select = true;
			this.select_id = e.id;
		},
		multiplesIds(e){
			this.select = false;
			var param = {
				coupon_id: this.select_id,
				select_ids: e,
			};
			this.loading = true;
			coupon_import({ ...param })
				.then((res) => {
					if (res.statusCode !== 200) {
						this.$message({
							type: 'error',
							message: res.message,
						});
						return true;
					} else {
						this.$message({
							type: 'success',
							message: '操作成功',
						});
					}
				})
				.finally(() => {
					this.loading = false;
				});
		}
	},
};
</script>
