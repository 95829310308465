import request from '@/utils/request';

// 账号列表
export function staffer_lists(params) {
    return request({
        url: '/staffer/lists',
        method: 'get',
        params: params,
    });
}

export function staffer_index(params) {
    return request({
        url: '/staffer',
        method: 'get',
        params: params,
    });
}

export function staffer_get(id, params) {
    return request({
        url: '/staffer/' + id,
        method: 'get',
        params: params,
    });
}

export function staffer_post(params) {
    return request({
        url: '/staffer',
        method: 'post',
        data: params,
    });
}

export function staffer_del(id) {
    return request({
        url: '/staffer/' + id,
        method: 'delete',
    });
}