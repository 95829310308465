<template>
	<div class="lum-dialog-mask">
		<el-container class="lum-dialog-box" :class="{ 'full-screen': fullscreen }">
			<el-header height="60px" class="header">
				<p class="title">
					<span>选择文件</span>
				</p>
				<p class="tools">
					<i
						class="iconfont"
						style="transform: scale(0.85)"
						:class="fullscreen ? 'icon-tuichuquanping' : 'icon-quanping'"
						@click="fullscreen = !fullscreen"
					/>
					<i class="el-icon-close" @click="closeEvent()" />
				</p>
			</el-header>
			<el-main class="full-height ov-hidden">
				<div style="width: 100%; padding: 0 15px">
					<el-row>
						<el-col :span="5" style="height: 100vh - 180px; border-right: 1px solid #f5eeee">
							<div style="margin: 12px 12px">
								<div class="flex">
									<img
										class="icon"
										src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAgCAMAAABXc8oyAAAAbFBMVEUAAAD/6ZX/4oz/6ZX/6ZX//5n/6ZX/6ZX/6JX/6JP/6JD5xzT4yTb/6Jb6yDP/6Zf5xTP/6pX5xzX/6ZX5xzX/6JX4xjP5yDP5yjr5yDX/55T/6JX6xzP/55f/65n/6ZD/6ZX5xzT/6JH5yDnWZB6zAAAAIHRSTlMA/Qr2+wTmuKhY/vLqr4tbJ+7e29PHuKako6BlMiAZF9FhIv8AAACQSURBVDjL7dNHEsIwEETRlhklZ3KGEdz/joiCBZaxPQfwX7/qXQPtsQzfik19wVDtKvxW1NkA3IekXV9qxMpn2iHrpj/ykZbni25r59+QBSl3/wtVEhE74SLxGSySvAXLWoKFzXCGfahkTskXjcwZWFaSK1h4JiU4lwdclJPuBOBqiSck2RtiuqnMGDNVo4EXR0+KdXBuHAMAAAAASUVORK5CYII="
									/>
									<span style="margin-left: 8px">全部</span>
								</div>
							</div>
						</el-col>
						<el-col :span="19" style="border-left: 1px solid #f5eeee; height: 100vh - 180px">
							<div style="margin: 12px 12px">
								<div style="margin: 6px 6px">
									<el-button type="primary" size="small" icon="el-icon-upload" :loading="loadStatus" @click="$refs.uploads.click()"
										>{{ loadStatus ? '上传中...' : '上传附件' }}
									</el-button>
									<input ref="uploads" multiple type="file" @change="upload" style="display: none" />
								</div>
								<div
									style="margin: 6px 6px; float: left; overflow: hidden; width: 80px; text-align: center"
									@click="checkedTap(item)"
									v-for="item in tableData"
									:key="item.id"
								>
									<div class="material-img">
										<video width="100%" :src="item.path" v-if="['mp4', 'avi', 'mov', 'mpeg', 'mpg'].includes(item.ext)" />
										<img
											v-if="!['mp4', 'avi', 'mov', 'mpeg', 'mpg'].includes(item.ext)"
											class="images"
											:src="
												item.size
													? item.path
													: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAgCAMAAABXc8oyAAAAbFBMVEUAAAD/6ZX/4oz/6ZX/6ZX//5n/6ZX/6ZX/6JX/6JP/6JD5xzT4yTb/6Jb6yDP/6Zf5xTP/6pX5xzX/6ZX5xzX/6JX4xjP5yDP5yjr5yDX/55T/6JX6xzP/55f/65n/6ZD/6ZX5xzT/6JH5yDnWZB6zAAAAIHRSTlMA/Qr2+wTmuKhY/vLqr4tbJ+7e29PHuKako6BlMiAZF9FhIv8AAACQSURBVDjL7dNHEsIwEETRlhklZ3KGEdz/joiCBZaxPQfwX7/qXQPtsQzfik19wVDtKvxW1NkA3IekXV9qxMpn2iHrpj/ykZbni25r59+QBSl3/wtVEhE74SLxGSySvAXLWoKFzXCGfahkTskXjcwZWFaSK1h4JiU4lwdclJPuBOBqiSck2RtiuqnMGDNVo4EXR0+KdXBuHAMAAAAASUVORK5CYII='
											"
										/>
										<div class="item-selected" v-if="item.checked"><i class="el-icon-check" /></div>
									</div>
									<div style="margin-top: 6px; font-size: 12px; color: #8a8d8c">{{ item.name }}</div>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</el-main>
			<el-footer class="footer mt15">
				<div>
					<el-button size="mini" @click="closeEvent()">取消</el-button>
					<el-button size="mini" type="primary" @click="submitTap()">确认提交</el-button>
				</div>
			</el-footer>
		</el-container>
	</div>
</template>

<script>
import { Session } from '@/utils/storage';
import { material_list, material_post } from '@/api/material';
export default {
	name: 'fullscreen',
	props: {
		type: {
			type: String,
			default: '',
		},
		limit: {
			type: Number,
			default: 1,
		},
		selected: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			loadStatus: false,
			fullscreen: false,
			tableData: [],
			dir_id: 0,
			upload_url: process.env.VUE_APP_BASE_API + '/base/upload',
			headers: {
				Authorization: `Bearer ${Session.get('token')}`,
			},
			loading: false,
			multiplesIds: [],
			selectedData: [],
		};
	},
	mounted() {
		// if (Object.keys(this.data).length > 0) {
		// 	this.multiplesIds = this.data;
		// }
		this.tableData.map(function (item) {
			item.checked = false;
		});
	},
	created() {
		this.initData();
	},
	methods: {
		initData() {
			this.loading = true;
			var param = {
				dir_id: this.dir_id || 0,
			};
			material_list({ ...param })
				.then((res) => {
					if (res.statusCode !== 200) {
						this.$message({
							type: 'error',
							message: res.message,
						});
						return true;
					}
					this.tableData = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		upload(e) {
			if (e.target.files.length == 0) {
				return false;
			}
			let files = e.target.files;
			let formData = new FormData();
			files.forEach((file) => {
				formData.append('file[]', file);
			});
			formData.append('dir_id', this.dir_id);
			this.loadStatus = true;
			material_post(formData)
				.then((res) => {
					if (res.statusCode != 200) {
						this.$message({
							type: 'error',
							message: res.message,
						});
					}
					this.$message({
						type: 'success',
						message: '上传成功！',
					});
					this.initData();
				})
				.finally(() => {
					this.loadStatus = false;
				});
		},
		checkedTap(row) {
			let index = this.tableData.findIndex((item) => item.id === row.id);
			if (index !== -1) {
				this.tableData[index]['checked'] = !this.tableData[index]['checked'];
			}
			let cur = this.tableData[index];
			this.$set(this.tableData, index, cur);
			if (cur['checked']) {
				this.selectedData.push({
					id: cur.id,
					name: cur.name,
					url: cur.path,
				});
			} else {
				this.selectedData = this.selectedData.filter((v) => v.id !== row.id);
			}
		},
		closeEvent() {
			this.$emit('closeTap');
		},
		submitTap() {
			this.$emit('materialTap', this.selectedData);
		},
	},
};
</script>
<style lang="less" scoped>
/deep/.el-scrollbar__wrap {
	overflow-x: hidden;
}
.flex {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.icon {
	height: 16px;
}

.file-item .image {
	width: 80px;
	height: 80px;
}

.material-img {
	width: 80px;
	height: 80px;
	color: #999;
	justify-content: center;
	display: flex;
	flex-direction: row;
	position: relative;
	align-items: center;
	overflow: hidden;
	border-radius: 4px;
	.images {
		width: 80px;
		height: 80px;
		display: inline-block;
		overflow: hidden;
	}
	.item-selected {
		position: absolute;
		top: 0;
		left: 0;
		align-items: center;
		justify-content: center;
		display: flex;
		color: #fff;
		font-size: 28px;
		width: 80px;
		height: 80px;
		border-radius: 4px;
		background-color: rgba(0, 0, 0, 0.5);
		box-sizing: border-box;
	}
}

.lum-dialog-mask {
	z-index: 9999;
}

.lum-dialog-box {
	width: 100%;
	height: 620px;
	max-width: 1160px;
	transition: 1s ease;
	&.full-screen {
		width: 100%;
		height: 100%;
		max-width: unset;
		margin: 0;
		border-radius: 0px;
	}
	.select_box {
		margin: 15px;
		border: 1px solid #dddd;
		border-radius: 3px;
		height: 100px;
		.el-tag {
			margin: 3px;
		}
	}
}

.el-table--scrollable-y {
	/deep/.el-table__body-wrapper::-webkit-scrollbar {
		width: 4px;
		height: 6px;
	}
	/deep/.gutter {
		width: 4px !important;
	}
}

.el-table--scrollable-y {
	/deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
		background-color: #d8d8d8ff;
		border-radius: 7px;
		// box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
.el-table--scrollable-y {
	/deep/.el-table__body-wrapper::-webkit-scrollbar-track {
		// box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: #fff;
		border-radius: 3px;
	}
}
</style>
