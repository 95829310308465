import request from '@/utils/request';

export function material_tree(params) {
    return request({
        url: '/material/tree',
        method: 'get',
        params: params,
    });
}

export function material_list(params) {
    return request({
        url: '/material',
        method: 'get',
        params: params,
    });
}

export function material_dowload(id, params) {
    return request({
        url: 'material/dowload/' + id,
        method: 'get',
        params: params,
    });
}

export function material_post(params) {
    return request({
        url: '/material',
        method: 'post',
        data: params,
    });
}

export function material_dir(params) {
    return request({
        url: '/material/dir',
        method: 'post',
        data: params,
    });
}

export function material_put(id, params) {
    return request({
        url: '/material/' + id,
        method: 'put',
        data: params,
    });
}

export function material_del(id, params) {
    return request({
        url: '/material/' + id,
        method: 'delete',
        data: params
    });
}

export function material_dir_post(params) {
    return request({
        url: '/material/dir',
        method: 'post',
        data: params,
    });
}