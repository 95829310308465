<template>
	<div>
		<div style="padding: 15px">
			<div class="header">
				<div class="mark-title" style="font-size: 16px; height: 36px; line-height: 36px;">广告信息</div>
			</div>
			<div style="margin-bottom: 52px;">
				<el-form :model="advert" style="padding:15px;" label-width="80px" v-loading="loading">
					<el-form-item label="标题">
						<el-input v-model="advert.name" placeholder="请输入标题" style="width: 380px"></el-input>
					</el-form-item>
					<el-form-item label="背景图">
						<div style="float:left;">
							<div style="margin:6px 6px;float:left;overflow: hidden;width:120px;" v-for="(item, index) in advert.thumb" :key="index">
								<div class="material-img" v-on:mouseenter="visibleEven(advert.thumb, item)" @mouseleave="visibleEven(advert.thumb, item)">
									<img class="images" :src="item.url" />
									<div class="item-selected" v-if="item.selected" @click="removeTap(advert.thumb, index)"><i class="el-icon-delete"/></div>
								</div>
							</div>
						</div>
						<div class="material-upload" style="margin:6px 6px;">
							<span @click="showMaterialTap()">添加图片</span>
						</div>
						<span class="sub-title"><i style="padding-right: 5px;color: red;">*</i>建议尺寸：800*800</span>
					</el-form-item>
					<el-form-item label="有效期">
						<el-date-picker
							v-model="advert.valid_time"
							type="datetimerange"
							value-format="yyyy-MM-dd HH:mm:ss"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="排序">
						<el-input-number v-model="advert.sort" controls-position="right" :min="0"></el-input-number>
					</el-form-item>
					<el-form-item label="状态"> <el-switch v-model="advert.status"></el-switch></el-form-item>
					<el-form-item label="跳转类型">
						<el-select v-model="advert.jump_type" style="width: 380px" placeholder="请选择跳转类型">
							<el-option v-for="item in jump_data" :key="item.id" :label="item.label" :value="item.id"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="模块">
						<el-select v-model="advert.module" style="width: 380px" placeholder="请选择模块">
							<el-option v-for="item in module_data" :key="item.id" :label="item.label" :value="item.id"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="跳转参数">
						<el-input v-model="advert.jump_url" placeholder="请输入跳转参数" clearable style="width: 380px"></el-input>
					</el-form-item>
					<el-form-item label="简介">
						<el-input type="textarea" v-model="advert.remark" clearable :autosize="{ minRows: 4, maxRows: 6 }" maxlength="150" show-word-limit style="width: 530px"></el-input>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<div class="bottom-fixed">
			<div class="box-item">
				<el-button size="medium" @click="closeEvent()">取 消</el-button>
				<el-button size="medium" type="primary" @click="submitTap()">立即提交</el-button>
			</div>
		</div>

		<material v-if="is_material" :selected="selected" :type="material_type" :limit="limit" @materialTap="materialTap" @closeTap="showMaterialTap"></material>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { Session } from '@/utils/storage';
import material from '@/components/dialog/material.vue';
import { advert_post, advert_get } from '@/api/common';

export default {
	name: 'advert_detail',
	components: {
		material
	},
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: ()=>({}),
		},
	},
	data() {
		return {
			upload_url: process.env.VUE_APP_BASE_API + '/base/upload',
			headers: {
				"Authorization":  `Bearer ${Session.get('token')}`
			},
			advert: {
				thumb:[]
			},
			limit: 1,
			is_material: false,
			material_type: 'image',
			selected: [],
			roles: [],
			loading: true,
			dialogImageUrl: '',
			dialogVisible: false,
			maxLimit: 1,
			treeData: [],
			jump_data:[{
				id: 1,
				label: '商品详情'
			},{
				id: 2,
				label: '活动页面'
			}],
			module_data:[{
				id: 1,
				label: '首页弹窗'
			},{
				id: 2,
				label: '轮播图'
			},{
				id: 3,
				label: '中部栏'
			}]
		};
	},
	created() {
		this.initData();
	},
	mounted() {
		if (Object.keys(this.data).length > 0) {
			advert_get(this.data.id)
				.then((res) => {
					this.advert = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
		}else{
			this.advert = {};
		}
	},
	methods: {
		initData() {
			this.loading = false;
		},
		beforeUpload(res) {
			if (res.data.statusCode === 200) {
				Message.error(res.message);
				return false;
			}
			this.advert.thumb = [{name: res.data.file_name, url: res.data.file_url}];
		},
		imageRemove() {
			this.advert.thumb = [];
        },
		closeEvent() {
			this.$emit('closeTap');
		},
		removeTap(data, index){
			data.splice(index, 1);
		},
		showMaterialTap(){
			this.is_material = !this.is_material;
		},
		visibleEven(data, row){
			let index = data.findIndex((item) => item.name === row.name);
			if (index !== -1) {
				data[index]['selected'] = !data[index]['selected'];
			};
			this.$set(data, index, data[index]);
		},
		materialTap(data) {
			this.advert.thumb = data;
			this.is_material = false;
		},
		submitTap() {
			advert_post(this.advert)
				.then((res) => {
					if (res.statusCode === 200) {
						Message.success(res.message);
						this.$emit('closeTap');
					}
				});
		},
	},
};
</script>

<style scoped lang="scss">
.tb-edit .el-input,
.tb-edit .el-select {
	display: none;
}
.tb-edit .current-row .el-input,
.tb-edit .current-row .el-select {
	display: block;
}
.tb-edit .current-row .el-input + span,
.tb-edit .current-row .el-select + span {
	display: none;
}
.el-radio__input {
	display: none !important;
}
</style>
