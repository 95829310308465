<template>
	<div class="lum-dialog-mask">
		<el-container class="lum-dialog-box" :class="{ 'full-screen': fullscreen }">
			<el-header height="60px" class="header">
				<p>权限分配</p>
				<p class="title">
					<span>个人权限分配</span>
				</p>
				<p class="tools">
					<i
						class="iconfont"
						style="transform: scale(0.85)"
						:class="fullscreen ? 'icon-tuichuquanping' : 'icon-quanping'"
						@click="fullscreen = !fullscreen"
					/>
					<i class="el-icon-close" @click="closeEvent()" />
				</p>
			</el-header>
			<div class="select_box">
				<el-scrollbar style="height: 100%; padding: 3px 8px">
					<el-tag
						v-for="(tag, i2) in multiplesTags"
						:key="i2"
						size="medium"
						:type="tag.type == 'jobs' ? 'warning' : tag.type == 'organic' ? 'danger' : ''"
						effect="plain"
						closable
						>{{ tag.name }}</el-tag
					>
				</el-scrollbar>
			</div>
			<div class="block">
				<el-tabs v-model="scope" @tab-click="handleClick" style="padding: 0 15px">
					<el-tab-pane v-for="tab in tabs" :key="tab.name" :label="tab.title" :disabled="loading" :name="tab.name"></el-tab-pane>
				</el-tabs>
			</div>
			<el-container class="full-height ov-hidden">
				<div class="block" style="width: 100%; padding: 0 15px">
					<el-table
						ref="multipleTable"
						v-loading="loading"
						:data="tableData"
						height="250"
						style="width: 100%"
						@selection-change="handleSelectionChange"
					>
						<el-table-column type="selection" width="55"></el-table-column>
						<el-table-column prop="name" label="账号姓名"></el-table-column>
						<el-table-column prop="staffer_no" label="账号编号"> </el-table-column>
						<el-table-column prop="mobile" label="联系方式"> </el-table-column>
						<el-table-column prop="jobs_name" label="岗位"> </el-table-column>
						<el-table-column prop="organic_names" label="所属组织" show-overflow-tooltip></el-table-column>
					</el-table>

					<div class="block" style="width: 100%; margin-top: 5px">
						<el-pagination
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page="pageInfo.now"
							:page-sizes="[10, 20, 50, 100, 200]"
							:page-size="pageInfo.size"
							layout="total, sizes, prev, pager, next, jumper"
							:total="pageInfo.count"
						>
						</el-pagination>
					</div>
				</div>
			</el-container>
			<el-footer height="60px" class="footer">
				<div style="width: 178px">
					<el-button size="medium" @click="closeEvent()">取消</el-button>
					<el-button size="medium" type="primary" @click="submitTap()">确认提交</el-button>
				</div>
			</el-footer>
		</el-container>
	</div>
</template>

<script>
import { staffer_index } from '@/api/staffer';
export default {
	name: 'fullscreen',
	props: {
		data: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			scope: 'staffer',
			tabs: [
				{
					name: 'staffer',
					title: '选个人',
				},
			],
			fullscreen: false,
			tableData: [],
			pageInfo: {
	now: 1,
	size: 20
},
			loading: false,
			tags: [],
			multiplesTags: [],
		};
	},
	mounted() {
		if (Object.keys(this.data).length > 0) {
			this.multiplesTags = this.data;
		}
	},
	created() {
		this.initData();
	},
	methods: {
		handleClick(tab) {
			this.tableData = [];
			this.pageInfo = {};
			this.scope = tab.name;
			this.initData();
		},
		initData() {
			this.loading = true;
			var param = {
				pageSize: this.pageInfo.size || undefined,
				pageNow: this.pageInfo.now || undefined,
			};
			if (this.scope == 'staffer') {
				staffer_index({ ...param })
					.then((res) => {
						this.pageInfo = res.page;
						this.tableData = res.lists;
					})
					.finally(() => {
						let tags = [];
						this.tableData.forEach((row) => {
							let index = this.multiplesTags.findIndex((item) => item.id === row.id && item.type === this.scope);
							if (index !== -1) tags.push(row);
						});
						tags.forEach((row) => {
							this.$refs.multipleTable.toggleRowSelection(row);
						});
						this.loading = false;
					});
			} 
		},
		handleSelectionChange(rows) {
			if (rows.length > 0) {
				let tags = [];
				this.tableData.forEach((row) => {
					let index = rows.findIndex((item) => item.id === row.id);
					if (index === -1) {
						let index = this.multiplesTags.findIndex((item) => item.id === row.id && item.type === this.scope);
						if (index !== -1) this.multiplesTags.splice(index, 1);
					} else {
						tags.push({ name: rows[index].name, type: this.scope, id: rows[index].id });
					}
				});
				tags.forEach((tag) => {
					let index = this.multiplesTags.findIndex((item) => item.id === tag.id && item.type === this.scope);
					if (index === -1) {
						this.multiplesTags.push({ name: tag.name, type: this.scope, id: tag.id });
					}
				});
			} else {
				this.tableData.forEach((row) => {
					let index = this.multiplesTags.findIndex((item) => item.id === row.id && item.type === this.scope);
					if (index !== -1) {
						this.multiplesTags.splice(index, 1);
					}
				});
			}
		},
		handleSizeChange(val) {
			this.pageInfo.size = val;
			this.initData();
		},
		handleCurrentChange(val) {
			this.pageInfo.now = val;
			this.initData();
		},
		closeEvent() {
			this.$emit('closeTap');
		},
		submitTap() {
			if (this.multiplesTags.length <= 0) {
				this.$message({
					type: 'error',
					message: '不能为空！',
				});
				return;
			}
			this.multiplesTags.map((row) => {
				this.tags.push({ id: row.id, name: row.name, type: row.type });
			});
			this.multiplesTags = this.tags;
			this.$emit('multiplesTags', this.tags);
		},
	},
};
</script>
<style lang="less" scoped>
/deep/.el-scrollbar__wrap {
	overflow-x: hidden;
}

.lum-dialog-mask {
	z-index: 9999;
}

.lum-dialog-box {
	width: 100%;
	height: 620px;
	max-width: 800px;
	transition: 1s ease;
	&.full-screen {
		width: 100%;
		height: 100%;
		max-width: unset;
		margin: 0;
		border-radius: 0px;
	}
	.select_box {
		margin: 15px;
		border: 1px solid #dddd;
		border-radius: 3px;
		height: 100px;
		.el-tag {
			margin: 3px;
		}
	}
}

.el-table--scrollable-y {
  /deep/.el-table__body-wrapper::-webkit-scrollbar {
    width: 4px;
	height: 6px;
  }
  /deep/.gutter {
    width: 4px !important;
  }
}

.el-table--scrollable-y {
  /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: #d8d8d8ff;
    border-radius: 7px;
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
.el-table--scrollable-y {
  /deep/.el-table__body-wrapper::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    border-radius: 3px;
  }
}

</style>