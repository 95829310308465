<template>
	<div class="lum-dialog-mask">
		<el-container class="lum-dialog-box" :class="{ 'full-screen': fullscreen }">
			<el-header height="60px" class="header">
				<p class="title">
					<span>字典</span>
				</p>
				<p class="tools">
					<i
						class="iconfont"
						style="transform: scale(0.85)"
						:class="fullscreen ? 'icon-tuichuquanping' : 'icon-quanping'"
						@click="fullscreen = !fullscreen"
					/>
					<i class="el-icon-close" @click="$emit('closeTap')" />
				</p>
			</el-header>

			<el-container class="full-height ov-hidden" style="padding: 15px">
				<div style="padding: 5px 5px">
					<el-form :model="lexicon" label-width="40px">
						<el-row :gutter="20">
							<el-col :span="24">
								<el-form-item label="名称">
									<el-input v-model="lexicon.name" placeholder="请输入名称" clearable></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="状态">
									<el-switch v-model="lexicon.status"></el-switch>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="排序">
									<el-input v-model="lexicon.sort" placeholder="请输入Key" clearable></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="Key">
									<el-input v-model="lexicon.key" placeholder="请输入Key" clearable></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="Value">
									<el-input v-model="lexicon.value" placeholder="请输入Value" clearable></el-input>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
				</div>
			</el-container>
			<el-footer height="45px" class="footer">
				<div style="width: 178px">
					<el-button size="mini" @click="closeEvent()">取消</el-button>
					<el-button size="mini" type="primary" @click="submitTap()">确认提交</el-button>
				</div>
			</el-footer>
		</el-container>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { lexicon_post } from '@/api/common';
export default {
	name: 'fullscreen',
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
	},

	data() {
		return {
			fullscreen: false,
			lexicon: {},
		};
	},
	mounted() {
		if (this.data !== null) {
			this.lexicon = this.data;
		}
		this.isVisible = this.visible;
	},
	methods: {
		closeEvent() {
			this.$emit('closeTap');
		},
		submitTap() {
			lexicon_post(this.lexicon)
				.then((res) => {
					if (res.statusCode == 200) {
						Message.success(res.message);
						this.$emit('closeTap');
					}
				})
				.catch(() => {});
		},
	},
};
</script>
<style lang="less" scoped>
/deep/.el-scrollbar__wrap {
	overflow-x: hidden;
}

.lum-dialog-mask {
	z-index: 99;
}

.lum-dialog-box {
	width: 100%;
	height: 470px;
	max-width: 600px;
	transition: 1s ease;
	&.full-screen {
		width: 100%;
		height: 100%;
		max-width: unset;
		margin: 0;
		border-radius: 0px;
	}
}
</style>
