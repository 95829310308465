<template>
	<div>
		<div style="margin: 12px 12px 58px 12px">
			<div style="brefund-radius: 4px; brefund: 1px solid #ebeef5; height: calc(100% - 10px)">
				<el-divider content-position="left">订单信息</el-divider>
				<div style="padding: 0 15px 0 15px">
					<el-descriptions class="margin-top" :column="3">
						<el-descriptions-item label="订单号">{{ refund.refund_no }}</el-descriptions-item>
						<el-descriptions-item label="订单状态">{{ refund.status_name }}</el-descriptions-item>
						<el-descriptions-item label="应付金额">{{ refund.raw_amount }}</el-descriptions-item>
						<el-descriptions-item label="实付金额">{{ refund.amount }}</el-descriptions-item>
						<el-descriptions-item label="优惠券">{{ refund.coupon_discount }}</el-descriptions-item>
						<!-- <el-descriptions-item label="抵扣积分">{{refund.deductt_point}}</el-descriptions-item> -->
						<el-descriptions-item label="奖励积分">{{ refund.gift_point }}</el-descriptions-item>
						<el-descriptions-item label="奖励成长值">{{ refund.gift_growth }}</el-descriptions-item>
						<el-descriptions-item label="收件人">{{ refund.contact }}</el-descriptions-item>
						<el-descriptions-item label="联系方式">{{ refund.moblie }}</el-descriptions-item>
						<el-descriptions-item label="收件地址">{{ refund.address }}</el-descriptions-item>
						<el-descriptions-item label="备注">{{ refund.remark }}</el-descriptions-item>
					
					</el-descriptions>
				</div>
				
				<el-tabs v-model="activeName" type="card" size="mini">
					<el-tab-pane label="商品信息" name="info">
						<div style="padding: 0 15px 0 15px">
							<el-table v-loading="loading" :data="refund.order_goods" stripe style="width: 100%">
								<el-table-column label="商品名称">
									<template #default="scope">
										<span>{{ scope.row.goods_name }}</span>
									</template>
								</el-table-column>
								<el-table-column label="封面">
									<template #default="scope">
										<el-avatar shape="square" :size="50" :src="scope.row.goods_thumb"></el-avatar>
									</template>
								</el-table-column>
								<el-table-column label="实付金额">
									<template #default="scope">
										<span>{{ scope.row.goods_price }}</span>
									</template>
								</el-table-column>
								<el-table-column label="原价">
									<template #default="scope">
										<span>{{ scope.row.line_price }}</span>
									</template>
								</el-table-column>
								<el-table-column label="优惠券">
									<template #default="scope">
										<span>{{ scope.row.coupon_discount }}</span>
									</template>
								</el-table-column>
								<!-- <el-table-column label="抵扣积分">
									<template #default="scope">
										<span>{{ scope.row.deductt_point }}</span>
									</template>
								</el-table-column> -->
								<el-table-column label="奖励积分">
									<template #default="scope">
										<span>{{ scope.row.gift_point }}</span>
									</template>
								</el-table-column>
								<el-table-column label="奖励成长值">
									<template #default="scope">
										<span>{{ scope.row.gift_growth }}</span>
									</template>
								</el-table-column>
								<el-table-column label="Sku">
									<template #default="scope">
										<span>{{ scope.row.sku_name }}</span>
									</template>
								</el-table-column>
								<el-table-column label="购买数量">
									<template #default="scope">
										<span>{{ scope.row.number }}</span>
									</template>
								</el-table-column>
								<!-- <el-table-column label="操作" width="165"  fixed="right">
									<template #default="scope">
										<el-button size="mini" type="text" @click="checkDetail(scope.row)">退款</el-button>
									</template>
								</el-table-column> -->
							</el-table>
						</div>
					</el-tab-pane>
					<el-tab-pane label="物流信息" name="spec">
						<el-divider content-position="left">refund 数据</el-divider>
						<pre><code>{{ refund }}</code></pre>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>

		<div class="bottom-fixed">
			<div class="box-item">
				<el-button size="medium" @click="closeTap()">取 消</el-button>
				<el-button size="medium" type="primary" @click="admin_refund(refund)">退 款</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { refund_get, refund_do } from '@/api/order';
import { Session } from '@/utils/storage';

export default {
	name: 'detail',
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			upload_url: process.env.VUE_APP_BASE_API + '/base/upload',
			headers: {
				Authorization: `Bearer ${Session.get('token')}`,
			},
			loading: true,
			couponData: [],
			activeName: 'info',
			new_attribute: '',
			select: false,
			refund: {},
		};
	},
	mounted() {
		this.initData();	
	},
	methods: {
		initData() {
			if (Object.keys(this.data).length > 0) {
				refund_get(this.data.order_id)
					.then((res) => {
						this.refund = res.data;
					})
					.finally(() => {
						this.loading = false;
					});
			}
		},
		closeTap() {
			this.$emit('closeTap');
		},
		submitTap() {
			this.$emit('closeTap');
		},
		admin_refund(e) {
			this.loading = true;
			this.$confirm('此操作将执行退款操作, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				refund_do(e.order_id)
					.then((res) => {
						if (res.statusCode !== 200) {
							this.$message({
								type: 'error',
								message: res.message,
							});
							return true;
						}
						this.$message({
							type: 'success',
							message: "操作成功",
						});
						this.closeTap();
					})
					.finally(() => {
						this.loading = false;
					});
			}).finally(() => {
				this.loading = false;
			});;
		},
	},
};
</script>

<style lang="less" scoped>
/deep/ .el-upload-dragger {
	width: initial;
	height: initial;
	brefund: 0;
	brefund-radius: 0;
	background-color: initial;
	overflow: auto;
}
/deep/ .image-upload-container {
	.el-image {
		vertical-align: middle;
		margin: 0 5px;
		width: 30px;
		height: 30px;
	}
	.images-upload,
	> .el-button {
		display: inline-block;
		margin: 0 5px;
		vertical-align: middle;
	}
}
</style>
