<template>
	<div class="lum-dialog-mask">
		<el-container class="lum-dialog-box" :class="{ 'full-screen': fullscreen }">
			<el-header height="60px" class="header">
				<p class="title">
					<span>修改密码</span>
				</p>
				<p class="tools">
					<i
						class="iconfont"
						style="transform: scale(0.85)"
						:class="fullscreen ? 'icon-tuichuquanping' : 'icon-quanping'"
						@click="fullscreen = !fullscreen"
					/>
					<i class="el-icon-close" @click="closeEvent()" />
				</p>
			</el-header>
			<el-container class="full-height ov-hidden">
				<el-form :model="personal" label-width="80px" style="padding: 15px;">
					<el-row :gutter="20">
						<el-col :span="24">
							<el-form-item label="原密码">
								<el-input v-model="personal.original_pw" placeholder="请输入原密码" type="password" autocomplete="off" :show-password="true" clearable style="width: 340px"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="新密码">
								<el-input v-model="personal.new_pw" placeholder="请输入新密码" clearable style="width: 340px"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="确认密码">
								<el-input v-model="personal.confirm_pw" placeholder="请输入确认密码" clearable style="width: 340px"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</el-container>
			<el-footer height="60px" class="footer">
				<div style="width: 178px">
					<el-button size="medium" @click="closeEvent()">取消</el-button>
					<el-button size="medium" type="primary" @click="submitTap()">确认修改</el-button>
				</div>
			</el-footer>
		</el-container>
	</div>
</template>

<script>
import { Message } from 'element-ui';
// import { personal_post } from '@/api/personal';
export default {
	name: 'fullscreen',
	data() {
		return {
            lexicons:{},
			personal: {},
			fullscreen: false,
		};
	},
	methods: {
		submitTap(){
			// personal_post(this.personal)
			// 	.then((res) => {
			// 		if (res.statusCode == 200) {
			// 			Message.success(res.message);
			// 			this.$emit('closeTap');
			// 		}
			// 	})
			// 	.catch(() => {});
		},
		closeEvent() {
			this.$emit('closeTap');
		},
	},
};
</script>
<style lang="less" scoped>
/deep/.el-scrollbar__wrap {
	overflow-x: hidden;
}

.lum-dialog-mask {
	z-index: 9999;
}

.lum-dialog-box {
	width: 100%;
	height: 340px;
	max-width: 480px;
	transition: 1s ease;
	&.full-screen {
		width: 100%;
		height: 100%;
		max-width: unset;
		margin: 0;
		border-radius: 0px;
	}
	.select_box {
		margin: 15px;
		border: 1px solid #dddd;
		border-radius: 3px;
		height: 100px;
		.el-tag {
			margin: 3px;
		}
	}
}
</style>