<template>
	<div>
		<div :class="showDeal ? 'box-modal-hide' : 'box-modal-show'" style="margin: 12px 12px 58px 12px">
			<div>
				<el-button size="small" type="primary" icon="el-icon-edit" @click="addDeal()">添加概率</el-button>
				<el-button size="small" type="primary" icon="el-icon-edit" @click="initData()">刷新</el-button>
			</div>
			<el-table v-loading="loading" :data="tableData" stripe style="width: 100%">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column label="名称">
					<template #default="scope">
						<el-input v-model="scope.row.title" placeholder="请输入名称" clearable></el-input>
					</template>
				</el-table-column>
				<el-table-column label="展示概率">
					<template #default="scope">
						<el-input-number v-model="scope.row.virtual_rate" placeholder="请输入展示概率" :min="0"></el-input-number> %
					</template>
				</el-table-column>
				<el-table-column label="实际概率">
					<template #default="scope">
						<el-input-number v-model="scope.row.reality_rate" placeholder="请输入实际概率" :min="0"></el-input-number> %
					</template>
				</el-table-column>
				<el-table-column label="描述" sortable>
					<template #default="scope">
						<el-input v-model="scope.row.remark" placeholder="描述" clearable></el-input>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="125">
					<template #default="scope">
						<el-button size="mini" type="text" @click="saveDeal(scope.row)">保存</el-button>
						<el-button size="mini" type="text" @click="deleteDeal(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-card shadow="none">
				<div slot="header">
					<span>总计</span>
				</div>
				<div class="info">
					<span>展示概率: {{ totalVirtualRate }}%, 真实概率: {{ totalRealRate }}%</span>
				</div>
			</el-card>
		</div>

		<div class="bottom-fixed" style="z-index: 2000">
			<div class="box-item">
				<el-button size="medium" @click="closeTap()">返 回</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { box_deal_post, box_deal_list, box_deal_del } from '@/api/blind_box';
export default {
	name: 'detail',
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			deal_group: {},
			loading: false,
			tableData: [],
			temp_key: 0,
		};
	},
	computed: {
		totalVirtualRate: function () {
			return this.tableData.reduce((pre, cur) => pre + cur.virtual_rate, 0);
		},
		totalRealRate: function () {
			return this.tableData.reduce((pre, cur) => pre + cur.reality_rate, 0);
		},
	},
	mounted() {
		if (this.data !== null) {
			this.deal_group = this.data;
		}
		this.initData();
	},
	methods: {
		initData() {
			this.loading = true;
			var param = {
				group_id: this.data.id ?? undefined,
			};
			box_deal_list({ ...param })
				.then((res) => {
					if (res.statusCode !== 200) {
						this.$message({
							type: 'error',
							message: res.message,
						});
						return true;
					}
					this.tableData = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		closeTap() {
			this.$emit('closeTap');
		},
		addDeal() {
			this.tableData.push({
				id: undefined,
				name: '未命名',
				virtual_rate: 0,
				reality_rate: 0,
				remark: '',
				key: this.temp_key,
			});
			this.temp_key += 1;
		},
		deleteDeal(e) {
			if (e.id === undefined && e.key !== undefined) {
				this.tableData = this.tableData.filter((item) => item.key != e.key);
				return;
			}
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				this.loading = true;
				box_deal_del(e.id)
					.then((res) => {
						if (res.statusCode != 200) {
							this.$message({
								type: 'error',
								message: res.message,
							});
							return false;
						}
						this.initData();
					})

					.finally(() => {
						this.loading = false;
					});
			});
		},
		saveDeal(e) {
			this.loading = true;
			delete e.key;
			box_deal_post({ ...e, group_id: this.data.id })
				.then((res) => {
					if (res.statusCode != 200) {
						this.$message({
							type: 'error',
							message: res.message,
						});
						return false;
					}
					this.initData();
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
<style lang="less" scoped>
/deep/.el-scrollbar__wrap {
	overflow-x: hidden;
}

.lum-dialog-mask {
	z-index: 99;
}

.lum-dialog-box {
	width: 100%;
	height: 470px;
	max-width: 800px;
	transition: 1s ease;
	&.full-screen {
		width: 100%;
		height: 100%;
		max-width: unset;
		margin: 0;
		border-radius: 0px;
	}
}
</style>
