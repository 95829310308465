import request from '@/utils/request';

// 用户登录
export function signIn(params) {
    return request({
        url: '/base/login',
        method: 'post',
        data: params,
    });
}

// 用户退出登录
export function signOut() {
    return request({
        url: '/base/logout',
        method: 'get'
    });
}

export function getVerifyGuid(params) {
    return request({
        url: '/base/verifyGuid',
        method: 'get',
        params: params,
    });
}

export function lang_list(params) {
    return request({
        url: '/setting/lang',
        method: 'get',
        params: params,
    });
}

export function lang_post(params) {
    return request({
        url: '/setting/lang',
        method: 'post',
        data: params,
    });
}

export function lang_get(id, params) {
    return request({
        url: '/setting/lang/' + id,
        method: 'get',
        params: params,
    });
}


export function log_list(params) {
    return request({
        url: '/log/lists',
        method: 'get',
        params: params,
    });
}

export function lexicon_lists(params) {
    return request({
        url: '/setting/lexicon/lists',
        method: 'get',
        params: params,
    });
}

export function lexicon_index(params) {
    return request({
        url: '/setting/lexicon',
        method: 'get',
        params: params,
    });
}


export function lexicon_post(params) {
    return request({
        url: '/setting/lexicon',
        method: 'post',
        data: params,
    });
}

export function lexicon_get(id, params) {
    return request({
        url: '/setting/lexicon/' + id,
        method: 'get',
        params: params,
    });
}

export function lexicon_del(id, params) {
    return request({
        url: '/setting/lexicon/' + id,
        method: 'delete',
        params: params,
    });
}

export function file_upload(params) {
    return request({
        url: '/base/upload',
        method: 'post',
        data: params,
    });
}

export function advert_index(params) {
    return request({
        url: '/advert',
        method: 'get',
        params: params,
    });
}

export function advert_post(params) {
    return request({
        url: '/advert',
        method: 'post',
        data: params,
    });
}

export function advert_get(id, params) {
    return request({
        url: '/advert/' + id,
        method: 'get',
        params: params,
    });
}

export function advert_del(id, params) {
    return request({
        url: '/advert/' + id,
        method: 'delete',
        params: params,
    });
}

export function activity_index(params) {
    return request({
        url: '/activity',
        method: 'get',
        params: params,
    });
}

export function activity_post(params) {
    return request({
        url: '/activity',
        method: 'post',
        data: params,
    });
}

export function activity_get(id, params) {
    return request({
        url: '/activity/' + id,
        method: 'get',
        params: params,
    });
}

export function activity_del(id, params) {
    return request({
        url: '/activity/' + id,
        method: 'delete',
        params: params,
    });
}

// 帮助中心
export function help_list(params) {
    return request({
        url: '/help/lists',
        method: 'get',
        params: params,
    });
}

export function help_index(params) {
    return request({
        url: '/help',
        method: 'get',
        params: params,
    });
}

export function help_post(params) {
    return request({
        url: '/help',
        method: 'post',
        data: params,
    });
}

export function help_get(id, params) {
    return request({
        url: '/help/' + id,
        method: 'get',
        params: params,
    });
}

export function help_del(id, params) {
    return request({
        url: '/help/' + id,
        method: 'delete',
        params: params,
    });
}