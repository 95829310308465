<template>
	<div>
		<div style="padding: 15px">
			<el-form :model="help" style="padding:15px;" label-width="80px" v-loading="loading">
				<el-form-item label="标题">
					<el-input v-model="help.title" placeholder="请输入标题" clearable style="width: 380px"></el-input>
				</el-form-item>
				<el-form-item label="排序">
					<el-input v-model="help.sort" placeholder="请输入排序" clearable style="width: 380px"></el-input>
				</el-form-item>
				<el-form-item label="上级分类">
					<el-select v-model="help.parent_id" style="width: 280px" placeholder="请选择">
						<el-option v-for="item in help_lists" :key="item.id" :label="item.name" :value="item.id"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="状态"> <el-switch v-model="help.status"></el-switch></el-form-item>
				<el-form-item label="描述">
					<el-input	type="textarea"	v-model="help.description"	clearable	:autosize="{ minRows: 4, maxRows: 6 }"	maxlength="150"	show-word-limit	style="width: 530px"></el-input>
				</el-form-item>
			</el-form>
		</div>
		<div class="bottom-fixed">
			<div class="box-item">
				<el-button size="medium" @click="closeEvent()">取 消</el-button>
				<el-button size="medium" type="primary" @click="submitTap()">立即提交</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { help_post, help_get, help_list } from '@/api/common';
export default {
	name: 'help_detail',
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: ()=>({}),
		},
	},
	data() {
		return {
			help: {},
			loading: true,
			maxLimit: 1,
			help_lists: []
		};
	},
	created() {
		this.initData();
	},
	mounted() {
		if (Object.keys(this.data).length > 0) {
			help_get(this.data.id)
				.then((res) => {
					this.help = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
		}else{
			this.help = {};
		}
	},
	methods: {
		initData() {
			this.loading = false;
			help_list()
				.then((res) => {
					this.help_lists = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		closeEvent() {
			this.$emit('closeTap');
		},
		submitTap() {
			help_post(this.help)
				.then((res) => {
					if (res.statusCode === 200) {
						Message.success(res.message);
						this.$emit('closeTap');
					}
				});
		},
	},
};
</script>

<style scoped lang="scss">
.tb-edit .el-input,
.tb-edit .el-select {
	display: none;
}
.tb-edit .current-row .el-input,
.tb-edit .current-row .el-select {
	display: block;
}
.tb-edit .current-row .el-input + span,
.tb-edit .current-row .el-select + span {
	display: none;
}
.el-radio__input {
	display: none !important;
}
</style>
