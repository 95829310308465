import request from '@/utils/request';

export function doc_list(params) {
    return request({
        url: '/doc',
        method: 'get',
        params: params,
    });
}

export function doc_post(params) {
    return request({
        url: '/doc',
        method: 'post',
        data: params,
    });
}

export function doc_get(id, params) {
    return request({
        url: '/doc/' + id,
        method: 'get',
        params: params,
    });
}

export function doc_asterisk(id) {
    return request({
        url: '/doc/asterisk/' + id,
        method: 'get'
    });
}

export function doc_del(id, params = '') {
    return request({
        url: '/doc/' + id,
        method: 'delete',
        params: params,
    });
}

export function doc_put(id, params) {
    return request({
        url: '/doc/' + id,
        method: 'put',
        data: params,
    });
}

export function doc_class_list(params) {
    return request({
        url: '/doc/class',
        method: 'get',
        params: params,
    });
}

export function doc_class_post(params) {
    return request({
        url: '/doc/class',
        method: 'post',
        data: params,
    });
}

export function doc_class_del(id, params) {
    return request({
        url: '/doc/class/' + id,
        method: 'delete',
        params: params,
    });
}

export function doc_class_put(id, params) {
    return request({
        url: '/doc/class/' + id,
        method: 'put',
        data: params,
    });
}

export function doc_annex_upload(params) {
    return request({
        url: '/doc/annex/upload',
        method: 'post',
        data: params,
    });
}

export function doc_annex_list(params) {
    return request({
        url: '/doc/annex',
        method: 'get',
        params: params,
    });
}

export function doc_annex_del(id) {
    return request({
        url: '/doc/annex/' + id,
        method: 'delete'
    });
}