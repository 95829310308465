<template>
	<div>
		<div style="padding:15px;margin-bottom: 52px;">
			<div class="header">
				<div class="mark-title" style="font-size: 16px; height: 36px; line-height: 36px;">菜单信息</div>
			</div>
			<el-form :model="menu" label-width="80px" v-loading="loading">
				<el-form-item label="菜单名称">
					<el-input v-model="menu.title" placeholder="请输入菜单名称" clearable style="width: 380px"></el-input>
				</el-form-item>
				<el-form-item label="是否目录">
					<el-select v-model="menu.is_dir" style="width: 280px" placeholder="请选择">
						<el-option label="是" :value="1"> </el-option>
						<el-option label="否" :value="0"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="菜单标识">
					<el-input v-model="menu.name" placeholder="请输入菜单标识" clearable style="width: 280px"></el-input>
				</el-form-item>
				<el-form-item label="权限标识" v-if="menu.type == 2">
					<el-input v-model="menu.auth_code" placeholder="请输入权限标识" clearable style="width: 280px"></el-input>
				</el-form-item>
				<el-form-item label="状态"> <el-switch v-model="menu.status"></el-switch></el-form-item>
				<el-form-item label="icon">
					<el-input v-model="menu.icon" placeholder="请选择icon" clearable style="width: 280px"></el-input>
				</el-form-item>
				<el-form-item label="上级菜单">
					<el-cascader
						v-model="menu.parent_ids"
						:options="treeData"
						:props="{ value: 'id', label: 'title', expandTrigger: 'hover', emitPath: true }"
						ref="menuTree"
						clearable
						style="width: 280px"
					></el-cascader>
				</el-form-item>
				<el-form-item label="路由地址">
					<el-input v-model="menu.path" placeholder="请输入路由地址" clearable style="width: 380px"></el-input>
					<!-- <div class="sub-title">激活即列出输入建议</div> -->
				</el-form-item>
				<el-form-item label="菜单描述">
					<el-input
						type="textarea"
						v-model="menu.description"
						clearable
						:autosize="{ minRows: 4, maxRows: 6 }"
						maxlength="150"
						show-word-limit
						style="width: 530px"
					></el-input>
				</el-form-item>
			</el-form>
			<!-- <div v-if="menu.is_dir == 0">
				<div class="header">
					<div class="mark-title" style="font-size: 16px; height: 36px; line-height: 36px;">权限管理</div>
				</div>
				<div style="padding-bottom: 15px;margin-top:12px;">
					<el-button type="danger" size="small" icon="iconfont icon-biaodan">批量删除</el-button>
					<el-button type="primary" size="small" icon="iconfont icon-biaodan" @click="addAction()">添加权限</el-button>
				</div>
				<el-table :data="menu.meta" class="tb-edit" border style="width: 85%" highlight-current-row>
					<el-table-column type="selection" width="55" align="center"></el-table-column>
					<el-table-column label="权限名称" align="center">
						<template #default="scope">
							<el-input size="small" v-model="scope.row.title" placeholder="请输入权限名称"></el-input>
							<span>{{ scope.row.title }}</span>
						</template>
					</el-table-column>
					<el-table-column label="权限标识" align="center">
						<template #default="scope">
							<el-input size="small" v-model="scope.row.auth_code" placeholder="请输入权限标识"></el-input>
							<span>{{ scope.row.auth_code }}</span>
						</template>
					</el-table-column>
					<el-table-column label="状态" align="center">
						<template #default="scope">
							<el-select size="small" v-model="scope.row.status" placeholder="请选择">
								<el-option label="开启" value="1"> </el-option>
								<el-option label="关闭" value="2"> </el-option>
							</el-select>
							<span>{{ scope.row.status == 1 ? '开启' : scope.row.status == 2 ? '关闭' : '' }}</span>
						</template>
					</el-table-column>
					<el-table-column label="是否慎用" align="center">
						<template #default="scope">
							<el-select size="small" v-model="scope.row.is_identify" placeholder="请选择">
								<el-option label="是" value="1"> </el-option>
								<el-option label="否" value="2"> </el-option>
							</el-select>
							<span>{{ scope.row.is_identify == 1 ? '是' : scope.row.is_identify == 2 ? '否' : '' }}</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" align="center">
						<template slot-scope="scope">
							<el-button size="mini" class="color-danger" type="text" @click="del(scope.$index)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div> -->
		</div>
		<div class="bottom-fixed">
			<div class="box-item">
				<el-button size="medium" @click="closeEvent()">取 消</el-button>
				<el-button size="medium" type="primary" @click="submitTap()">立即提交</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { menu_tree, menu_post, menu_get } from '@/api/auth';
export default {
	name: 'menu_detail',
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: ()=>({}),
		},
	},
	data() {
		return {
			menu: {},
			loading: true,
			treeData: [],
			actionData: [],
		};
	},
	created() {
		this.initData();
	},
	mounted() {
		if (Object.keys(this.data).length > 0) {
			menu_get(this.data.id)
				.then((res) => {
					this.menu = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
		}else{
			this.menu = {};
			this.actionData = [];
		}
	},
	methods: {
		initData() {
			menu_tree({is_dir: 1})
				.then((res) => {
					this.treeData = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		del(index) {
			this.actionData.splice(index, 1)
		},
		addAction() {
			this.actionData.push({
				name: '',
				auth_code: '',
				status: '',
				is_identify: '',
			});
		},
		closeEvent() {
			this.$emit('closeTap');
		},
		submitTap() {
			this.menu.auth_action = this.actionData;
			menu_post(this.menu)
				.then((res) => {
					if (res.statusCode === 200) {
						Message.success(res.message);
						this.$emit('closeTap');
					}
				});
		},
	},
};
</script>

<style scoped lang="scss">
.tb-edit .el-input,
.tb-edit .el-select {
	display: none;
}
.tb-edit .current-row .el-input,
.tb-edit .current-row .el-select {
	display: block;
}
.tb-edit .current-row .el-input + span,
.tb-edit .current-row .el-select + span {
	display: none;
}
.el-radio__input {
	display: none !important;
}
</style>
