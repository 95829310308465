<template>
	<div>
		<el-container slot="container" class="note-container" style="padding: 15px">
			<!-- 左侧工具栏 -->
			<el-aside width="230px" class="el-aside-one lum-scrollbar">
				<el-header class="btn-header">
					<el-dropdown class="btn-dropdown-menu" split-button type="primary" @click="insertNote" @command="handleCommand">
						<div>+ 站内消息</div>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="a">消息类型</el-dropdown-item>
							<el-dropdown-item command="b">创建草稿</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</el-header>

				<div class="note-headline">我的消息</div>
				<div style="padding: 0 5px">
					<el-input size="medium" style="margin: 5px 0" placeholder="搜索站内消息">
						<template #prefix>
							<i class="el-input__icon el-icon-search"></i>
						</template>
					</el-input>
				</div>

				<el-scrollbar :native="false" tag="section" class="note-aside">
					<div v-for="(menu, i) in menus" :key="i">
						<div
							class="note-list-first"
							:class="{ 'note-list-active': menu.isActive }"
							@click="clickNoteMenu(1, i)"
							@contextmenu.prevent="noteClassMenu($event, i)"
						>
							<i :class="menu.icon" :style="{ color: menu.color }" />
							<span>{{ menu.name }}</span>
							<i
								v-show="menu.submenus.length"
								class="icon-menu-nav"
								:class="{
									'el-icon-arrow-down': menu.isShowSub,
									'el-icon-arrow-up': !menu.isShowSub,
								}"
								style="float: right; padding: 14px 5px"
							/>
						</div>

						<div
							v-for="(submenu, i2) in menu.submenus"
							v-show="menu.isShowSub"
							:key="i2"
							class="note-list-two"
							:class="{ 'note-list-active': submenu.isActive }"
						>
							<p @click="clickNoteMenu(2, i, i2)" @contextmenu.prevent="noteClassMenu($event, i, i2)">
								<span v-if="i > 0 && i < 3">
									<i class="el-icon-folder" style="margin-right: 5px; color: red" />
									<span>{{ submenu.name }}</span>
									<span v-if="submenu.count">({{ submenu.count }})</span>
									<i
										class="icon-menu-nav"
										:class="{
											'el-icon-arrow-down': submenu.isShowSub,
											'el-icon-arrow-up': !submenu.isShowSub,
										}"
										style="float: right; padding: 14px 5px"
									/>
								</span>
								<span v-else @click="catNote(submenu)">
									<i class="iconfont icon-dian" />
									<span>{{ submenu.name }}</span>
								</span>
							</p>
							<div
								v-for="(child, i3) in submenu.children"
								v-show="submenu.isShowSub"
								:key="i3"
								class="note-list-three"
								:class="{ 'note-list-active': child.isActive }"
								@click="clickNoteMenu(3, i, i2, i3)"
								@contextmenu.prevent="noteClassMenu($event, i, i2, i3)"
							>
								<p @click="catNote(child)">
									<i class="iconfont icon-dian" />
									<span>{{ child.name }}</span>
								</p>
							</div>
						</div>
					</div>
				</el-scrollbar>
			</el-aside>
			<preview
				ref="refNotePreview"
				@create="
					(note_id) => {
						this.note_id = note_id;
						this.loadNoticeList();
					}
				"
				@recover="
					(note_id) => {
						this.removeListNote(note_id);
					}
				"
				@change="
					(note) => {
						this.notes.forEach((value, index) => {
							if (value.id == note.id) {
								value.title = note.title;
								value.abstract = note.abstract;
							}
						});
					}
				"
				@recycle="
					(note_id) => {
						this.removeListNote(note_id);
					}
				"
				@del="
					(note_id) => {
						this.removeListNote(note_id);
					}
				"
			/>
		</el-container>
	</div>
</template>
<script>
import { notice_list, notice_del, notice_put } from '@/api/notice';
import preview from './component/preview.vue';
export default {
	name: 'notice',
	components: {
		preview,
	},
	data() {
		return {
			menus: [
				{
					name: '未读消息',
					icon: 'el-icon-finished',
					color: 'rgb(255, 181, 169)',
					isShowSub: false,
					isActive: true,
					submenus: [],
				},
				{
					name: '消息管理',
					icon: 'el-icon-receiving',
					color: '#ffc152',
					isShowSub: false,
					isActive: false,
					submenus: [
						{
							name: '草稿箱',
							icon: 'el-icon-finished',
							color: 'rgb(255, 181, 169)',
							isShowSub: false,
							isActive: true,
							submenus: [],
						},
						{
							name: '已发送',
							icon: 'el-icon-finished',
							color: 'rgb(255, 181, 169)',
							isShowSub: false,
							isActive: true,
							submenus: [],
						},
					],
				},
				{
					name: '站内消息',
					icon: 'el-icon-chat-line-round',
					color: '#ffc152',
					isShowSub: false,
					isActive: false,
					submenus: [],
				},
				{
					name: '回收站',
					icon: 'el-icon-delete',
					color: '#fd6f41',
					isShowSub: false,
					isActive: false,
					submenus: [],
				},
			],

			// 消息列表
			loadNoteStatus: 0,
			notes: [],

			// 当前查看的消息ID
			note_id: 0,

			recycleAnnexBox: false,
		};
	},
	computed: {},
	watch: {},
	mounted() {
		this.loadNoticeList();
	},
	methods: {
		// 查看消息详情
		catNote(info, isEditor = false) {
			this.note_id = info.id;
			this.$refs.refNotePreview.open(this.note_id, isEditor);
		},
		loadNoticeList() {
			notice_list()
				.then((res) => {
					if (res.statusCode != 200) return false;

					if (res.data.unread.length > 0) {
						this.menus[0].submenus = res.data.unread.map((item) => {
							return {
								id: item.id,
								name: item.title,
								icon: 'icon-dian',
								count: 0,
								isEdit: false,
								isDefault: 0,
								isActive: false,
							};
						});
					}

					if (res.data.draft_box.length > 0) {
						this.menus[1].submenus = res.data.draft_box.map((item) => {
							return {
								id: item.id,
								name: item.title,
								icon: 'icon-dian',
								count: 0,
								isEdit: false,
								isDefault: 0,
								isActive: false,
							};
						});
					}

					if (res.data.station.length > 0) {
						this.menus[2].submenus = res.data.station.map((item) => {
							var children = [];
							if (item.children.length > 0) {
								children = item.children.map((v) => {
									return {
										id: v.id,
										name: v.title,
										icon: 'icon-dian',
										count: 0,
										isEdit: false,
										isDefault: 0,
										isActive: false,
									};
								});
							}
							return {
								id: item.id,
								name: item.class_name,
								icon: 'icon-dian',
								count: 0,
								isEdit: false,
								isDefault: item.is_default,
								isShowSub: false,
								isActive: false,
								children: children || [],
							};
						});
					}

					if (res.data.recycle.length > 0) {
						this.menus[3].submenus = res.data.recycle.map((item) => {
							return {
								id: item.id,
								name: item.title,
								icon: 'icon-dian',
								count: 0,
								isEdit: false,
								isDefault: item.is_default,
								isActive: false,
							};
						});
					}
				})
				.catch(() => {});
		},
		// 左侧菜单栏点击事件
		clickNoteMenu(type, index, index2 = -1, index3 = null) {
			if (type == 1) {
				this.menus[index].isShowSub = !this.menus[index].isShowSub;
				return;
			}

			if (type == 2 && index > 1) {
				this.menus[index].submenus[index2].isShowSub = !this.menus[index].submenus[index2].isShowSub;
				return;
			}

			// 点击查看附件回收站事件
			if (index == 3 && index2 != 0) {
				this.recycleAnnexBox = true;
				return;
			}

			this.setSelectMenu(index, index2, index3);
		},
		// 获取当前选中的菜单选项
		getSelectMenu() {
			for (let [i, item] of this.menus.entries()) {
				if (item.isActive) return [i, -1, -1];
				if (!item.submenus) continue;
				for (let [i2, item2] of item.submenus.entries()) {
					if (item2.isActive) return [i, i2, -1];
					if (!item2.children) continue;
					for (let [i3, item3] of item2.children.entries()) {
						if (item3.isActive) return [i, i2, i3];
					}
				}
			}

			return [-1, -1, -1];
		},
		handleCommand(command) {
			if (command == 'a') {
				this.insertNote();
			} else if (command == 'b') {
				this.menus[2].isShowSub = true;
				this.menus[2].submenus.unshift({
					id: null,
					name: '',
					icon: 'icon-dian',
					count: 0,
					isEdit: true,
					isDefault: 0,
					isActive: false,
				});
			}
		},
		// 添加新的消息
		insertNote() {
			let [i1, i2, i3] = this.getSelectMenu();
			if (i1 != 2) {
				i1 = 2;
				i2 = this.defaultNoteClassIdx();
				this.setSelectMenu(i1, i2);
				this.loadNoticeList();
			}
			if (this.menus[i1].submenus[i2] == undefined) {
				this.$message.error('未找到文件夹！');
				return;
			}
			let class_id = this.menus[i1].submenus[i2].id;
			this.$refs.refNotePreview.open(0, true, class_id);
		},
		// 获取默认消息分类的索引
		defaultNoteClassIdx() {
			return this.menus[2].submenus.findIndex((item) => {
				return item.isDefault == 1;
			});
		},
		// 设置当前选中的菜单选项
		setSelectMenu(index1, index2, index3 = null) {
			if (this.menus.submenus !== undefined && this.menus.submenus[index2].children.length > 0) return;

			this.menus.map((item, i) => {
				item.isActive = i == index1 && index2 == -1;
				if (i == index1) item.isShowSub = true;
				item.submenus.map((item2, i2) => {
					if (index3 == null) {
						item2.isActive = i == index1 && index2 == i2;
						return item2;
					} else {
						if (item2.children == undefined) return item2;
						item2.children.map((item3, i3) => {
							item3.isActive = i == index1 && index2 == i2 && index3 == i3;
							return item3;
						});
					}
				});

				return item;
			});
		},

		// 消息分类的自定义右键菜单栏
		noteClassMenu(event, i, i2 = null, i3 = null) {
			let menu = [];
			if (i >= 0 && i2 == null) {
				menu = [
					{
						label: '重新加载',
						icon: 'el-icon-refresh',
						onClick: () => {
							this.menus[2].submenus = [];
							this.loadNoticeList();
						},
					},
				];
			} else if (i == 2 && i2 >= 0 && i3 >= 0) {
				menu = [
					{
						label: '删除消息',
						icon: 'el-icon-delete',
						onClick: () => {
							notice_del(this.menus[i].submenus[i2].children[i3].id)
								.then((res) => {
									if (res.statusCode == 200) {
										this.$delete(this.menus[i].submenus[i2].children, i3);
									}
								})
								.catch(() => {});
						},
					},
				];
			} else if (i == 3 && i2 >= 0 && i3 >= 0) {
				menu = [
					{
						label: '恢复',
						icon: 'el-icon-refresh',
						onClick: () => {
							notice_put(this.menus[i].submenus[i2].children[i3].id, {
								status: 1,
							})
								.then((res) => {
									if (res.statusCode == 200) {
										this.$delete(this.menus[i].submenus[i2].children, i3);
									}
								})
								.catch(() => {});
						},
					},
					{
						label: '永久删除',
						icon: 'el-icon-delete',
						onClick: () => {
							notice_del(this.menus[i].submenus[i2].children[i3].id)
								.then((res) => {
									if (res.statusCode == 200) {
										this.$delete(this.menus[i].submenus[i2].children, i3);
									}
								})
								.catch(() => {});
						},
					},
				];
			} else {
				return;
			}

			this.$contextmenu({
				items: menu,
				event,
				zIndex: 999,
				minWidth: 150,
			});

			return false;
		},
	},
};
</script>
<style scoped lang="less">
@import '~@/assets/css/page/note-page.less';
</style>
