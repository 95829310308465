// 定义内容
export default {
    router: {
        home: '首頁',
        system: '系統設置',
        systemMenu: '選單管理',
        systemUser: '用戶管理',
        limits: '許可權管理',
        limitsFrontEnd: '前端控制',
        limitsFrontEndPage: '頁面許可權',
        limitsFrontEndBtn: '按鈕許可權',
        limitsBackEnd: '後端控制',
        limitsBackEndEndPage: '頁面許可權',
        menu: '選單嵌套',
        menu1: '選單1',
        menu11: '選單11',
        menu12: '選單12',
        menu121: '選單121',
        menu122: '選單122',
        menu13: '選單13',
        menu2: '選單2',
        jobs: '角色管理',
        organic: '组织管理',
        lang: '语种管理',
        log: '日志管理',
        article: '文档管理',
        material: '素材管理',
        notice: '消息管理',
        lexicon: '字典管理',
        staffer: '账号管理',
        approval: '审批业务',
        funIndex: '功能',
        funTagsView: 'tagsView 操作',
        funSignCanvas: '線上簽名',
        funCountup: 'countup 數位滾動',
        funEchartsTree: 'echartsTree 樹圖',
        funSelector: '圖標選擇器',
        funWangEditor: 'wangEditor 編輯器',
        funCropper: 'cropper 圖片裁剪',
        funMindMap: 'G6 心智圖',
        funQrcode: 'qrcode 二維碼生成',
        funEchartsMap: '地理座標/地圖',
        funPrintJs: '頁面列印',
        funClipboard: '複製剪切',
        funScreenShort: '自定義截圖',
        pagesIndex: '頁面',
        pagesFiltering: '過濾篩選組件',
        pagesFilteringDetails: '過濾篩選組件詳情',
        pagesFilteringDetails1: '過濾篩選組件詳情111',
        pagesIocnfont: 'iconfont 字體圖標',
        pagesElement: 'element 字體圖標',
        pagesAwesome: 'awesome 字體圖標',
        pagesCityLinkage: '都市多級聯動',
        pagesFormAdapt: '表單自我調整',
        pagesListAdapt: '清單自我調整',
        pagesWaterfall: '瀑布屏',
        pagesSteps: '步驟條',
        chartIndex: '大資料圖表',
        personal: '個人中心',
        tools: '工具類集合',
        layoutLinkView: '外鏈',
        layoutIfameView: '内嵌 iframe',
    },
    user: {
        title0: '組件大小',
        title1: '語言切換',
        title2: '選單蒐索',
        title3: '佈局配寘',
        title4: '消息',
        title5: '開全屏',
        title6: '關全屏',
        dropdownDefault: '默認',
        dropdownMedium: '中等',
        dropdownSmall: '小型',
        dropdownMini: '超小',
        dropdown1: '首頁',
        dropdown2: '個人中心',
        dropdown3: '404',
        dropdown4: '401',
        dropdown5: '登出',
        dropdown6: '程式碼倉庫',
        searchPlaceholder: '選單蒐索：支援中文、路由路徑',
        newTitle: '通知',
        newBtn: '全部已讀',
        newGo: '前往通知中心',
        newDesc: '暫無通知',
        logOutTitle: '提示',
        logOutMessage: '此操作將登出，是否繼續？',
        logOutConfirm: '確定',
        logOutCancel: '取消',
        logOutExit: '退出中',
        logOutSuccess: '安全退出成功！',
    },
    tagsView: {
        refresh: '重繪',
        close: '關閉',
        closeOther: '關閉其它',
        closeAll: '全部關閉',
        fullscreen: '當前頁全屏',
    },
    notFound: {
        foundTitle: '地址輸入錯誤，請重新輸入地址~',
        foundMsg: '您可以先檢查網址，然後重新輸入或給我們迴響問題。',
        foundBtn: '返回首頁',
    },
    noAccess: {
        accessTitle: '您未被授權，沒有操作許可權~',
        accessMsg: '聯繫方式：加QQ群探討665452019',
        accessBtn: '重新授權',
    },
    layout: {
        configTitle: '佈局配寘',
        oneTitle: '全域主題',
        twoTitle: '選單 / 頂欄',
        twoTopBar: '頂欄背景',
        twoMenuBar: '選單背景',
        twoColumnsMenuBar: '分欄選單背景',
        twoTopBarColor: '頂欄默認字體顏色',
        twoMenuBarColor: '選單默認字體顏色',
        twoColumnsMenuBarColor: '分欄選單默認字體顏色',
        twoIsTopBarColorGradual: '頂欄背景漸變',
        twoIsMenuBarColorGradual: '選單背景漸變',
        twoIsMenuBarColorHighlight: '選單字體背景高亮',
        threeTitle: '介面設定',
        threeIsCollapse: '選單水准折疊',
        threeIsUniqueOpened: '選單手風琴',
        threeIsFixedHeader: '固定 Header',
        threeIsClassicSplitMenu: '經典佈局分割選單',
        threeIsLockScreen: '開啟鎖屏',
        threeLockScreenTime: '自動鎖屏(s/秒)',
        fourTitle: '介面顯示',
        fourIsShowLogo: '側邊欄 Logo',
        fourIsBreadcrumb: '開啟 Breadcrumb',
        fourIsBreadcrumbIcon: '開啟 Breadcrumb 圖標',
        fourIsTagsview: '開啟 Tagsview',
        fourIsTagsviewIcon: '開啟 Tagsview 圖標',
        fourIsCacheTagsView: '開啟 TagsView 緩存',
        fourIsSortableTagsView: '開啟 TagsView 拖拽',
        fourIsFooter: '開啟 Footer',
        fourIsGrayscale: '灰色模式',
        fourIsInvert: '色弱模式',
        fourIsWartermark: '開啟浮水印',
        fourWartermarkText: '浮水印文案',
        fiveTitle: '其它設定',
        fiveTagsStyle: 'Tagsview 風格',
        fiveAnimation: '主頁面切換動畫',
        fiveColumnsAsideStyle: '分欄高亮風格',
        fiveColumnsAsideLayout: '分欄佈局風格',
        sixTitle: '佈局切換',
        sixDefaults: '默認',
        sixClassic: '經典',
        sixTransverse: '橫向',
        sixColumns: '分欄',
        tipText: '點擊下方按鈕，複製佈局配寘去`src/store/modules/themeConfig.js`中修改。',
        copyText: '一鍵複製配寘',
        copyTextSuccess: '複製成功！',
        copyTextError: '複製失敗！',
    },
};