<template>
	<div>
		<div>
			<!--START HOME-->
			<section class="section bg-home home-half" id="home">
				<div class="bg-overlay" :style="{ 'background-image': 'url(' + require('@/assets/image/background/005.png') + ')' }"></div>
				<div class="login-main">
					<div class="logo-title">后台管理系统</div>
					<div style="float: left">
						<div style="width:340px;height:300px; margin-right:35px;float: left">
							<div :style="{ 'background-image': 'url(' + require('@/assets/image/background_login.png') + ')' }" style="width: 380px;height:300px;background-repeat: no-repeat;background-size: 100% 100%;"></div>
						</div>
						<div style="width: 280px; float: left">
							<el-form class="el-form login-form" :model="ruleForm" :rules="rule" ref="ruleForm">
								<el-form-item style="margin-left: 0px" prop="userName">
									<el-input
										type="text"
										placeholder="请输入账号/邮箱"
										prefix-icon="el-icon-user"
										v-model="ruleForm.userName"
										clearable
										autocomplete="off"
									>
									</el-input>
								</el-form-item>
								<el-form-item style="margin-left: 0px" prop="password">
									<el-input
										type="password"
										placeholder="请输入密码"
										prefix-icon="el-icon-lock"
										v-model="ruleForm.password"
										autocomplete="off"
										:show-password="true"
									>
									</el-input>
								</el-form-item>
								<el-form-item prop="verifyGuid">
									<el-input type="text" v-model="ruleForm.verifyGuid" @keyup.enter.native="submitForm('ruleForm')" auto-complete="off" placeholder="验证码">
										<template slot="append">
											<img :src="imgVerifySrc" alt="" class="imgVerifySrc" @click="getImgVerify" />
										</template>
									</el-input>
								</el-form-item>
								<el-form-item style="margin: 40px 0px 0">
									<el-button type="primary" class="login-submit" @click="submitForm('ruleForm')">
										<span v-if="!submit.loading">登 录</span>
										<span v-else>登录中...</span>
									</el-button>
								</el-form-item>
							</el-form>
						</div>
					</div>
				</div>
				<div class="wave-effect wave-anim">
					<div class="waves-shape shape-one">
						<div class="wave wave-one" :style="{ 'background-image': 'url(' + require('@/assets/image/wave-shape/wave1.png') + ')' }"></div>
					</div>
					<div class="waves-shape shape-two">
						<div class="wave wave-two" :style="{ 'background-image': 'url(' + require('@/assets/image/wave-shape/wave2.png') + ')' }"></div>
					</div>
					<div class="waves-shape shape-three">
						<div class="wave wave-three" :style="{ 'background-image': 'url(' + require('@/assets/image/wave-shape/wave3.png') + ')' }"></div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>


<script>
import { Session } from '@/utils/storage';
import { formatAxis } from '@/utils/formatTime';
import { PrevLoading } from '@/utils/loading.js';
import { signIn, getVerifyGuid } from '@/api/common';
import router from '@/router/index';

export default {
	name: 'login',
	data() {
		return {
			quotations: {},
			isView: false,
			submit: {
				loading: false,
			},
			ruleForm: {
				userName: '',
				password: '',
				verifyGuid: '',
			},
			time: {
				txt: '',
				fun: null,
			},
			imgVerifySrc: '',
			guidCode: this.createGuid(),
			rule: {
				userName: [{ required: true, message: '请输入账号/邮箱', trigger: 'blur' }],
				password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
				verifyGuid: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
			},
		};
	},
	computed: {
		// 获取当前时间
		currentTime() {
			return formatAxis(new Date());
		},
		// 获取布局配置信息
		getThemeConfig() {
			return this.$store.state.themeConfig.themeConfig;
		},
	},
	mounted() {
		this.getImgVerify();
		// 检查是否已登录
		this.checkLogin();
	},
	methods: {
		createGuid() {
			return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
				var r = (Math.random() * 16) | 0,
					v = c == 'x' ? r : (r & 0x3) | 0x8;
				return v.toString(16);
			});
		},
		// 登录按钮点击
		submitForm(ruleForm) {
			this.$refs[ruleForm].validate((valid) => {
				if (valid) {
					this.submit.loading = true;
					let param = {
						account: this.ruleForm.userName,
						password: this.ruleForm.password,
						guidCode: this.guidCode,
						verifyGuid: this.ruleForm.verifyGuid,
					};
					signIn({ ...param })
						.then((res) => {
							// 存储 token 到浏览器缓存
							Session.set('token', res.data.token);
							res.data.userInfo.time = new Date().getTime();
							// 存储用户信息到浏览器缓存
							Session.set('userInfo', res.data.userInfo);
							// 存储用户信息到vuex
							this.$store.dispatch('userInfos/setUserInfos', res.data.userInfo);
							// 权限信息
							Session.set('auths', res.data.auths);
							this.$store.dispatch('authority/setAuths', res.data.auths);
							PrevLoading.start();
							window.location.href = `${window.location.origin}${window.location.pathname}`;
							setTimeout(() => {
								this.$message.success(`${this.currentTime}，登录成功！`);
							}, 300);
						})
						.finally(() => {
							this.submit.loading = false;
						});
				}
			});
		},
		getImgVerify() {
			let param = {
				guidCode: this.guidCode,
				_rand: parseInt(Math.random() * 9900000),
			};
			getVerifyGuid({ ...param })
				.then((res) => {
					this.imgVerifySrc = res.data.verifyGuid;
				})
				.catch(() => {});
		},
		checkLogin() {
			let token = Session.get('token');
			if (token !== null) {
				PrevLoading.start();
				window.location.href = `${window.location.origin}${window.location.pathname}`;
				setTimeout(() => {
					this.$message.success(`${this.currentTime}，登录成功！`);
				}, 300);
			}
		}
	},
	destroyed() {
		clearInterval(this.time.fun);
	},
};
</script>

<style scoped lang="scss">
/* ==========
   1.BASIC
   ========== */

body {
	font-family: 'Rubik', sans-serif;
	overflow-x: hidden !important;
	position: relative;
}

a,
a:hover,
a:focus,
button {
	text-decoration: none !important;
	outline: none !important;
}

p {
	font-size: 14.4px;
	line-height: 24px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Sarabun', sans-serif;
	font-weight: 600;
}

.h1,
h1 {
	font-size: 36px;
}

.h2,
h2 {
	font-size: 30px;
}

.h3,
h3 {
	font-size: 24px;
}

.h4,
h4 {
	font-size: 18px;
}

.h5,
h5 {
	font-size: 14px;
}

.h6,
h6 {
	font-size: 12px;
}

b {
	font-weight: 500;
}

::-moz-selection {
	background-color: #2e3138;
	color: #fff;
}

::selection {
	background-color: #2e3138;
	color: #fff;
}

/*====================
  2.HELPER
  ===================*/

.margin-t-20 {
	margin-top: 20px;
}

.margin-t-30 {
	margin-top: 30px;
}

.margin-t-50 {
	margin-top: 50px;
}

.margin-b-0 {
	margin-bottom: 0px;
}

.padding-t-15 {
	padding-top: 15px;
}

.padding-t-30 {
	padding-top: 30px;
}

.padding-b-10 {
	padding-bottom: 10px;
}

.height-100vh {
	height: 100vh;
}

.vertical-content {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	-webkit-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	flex-direction: row;
	-webkit-flex-direction: row;
}

.bg-overlay {
	// background: linear-gradient(to right, #94c8e7a6, #339adc); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	opacity: 0.95;
	position: absolute;
	height: 100%;
	width: 100%;
	right: 0;
	bottom: 0;
	left: 0;
	top: 0;
}

.bg-overlay-light {
	background-color: rgba(0, 0, 0, 0.5);
}

.login-main {
	width: 730px;
	position: relative;
	margin: 0 auto;
	background: #fff;
	height: 420px;
	padding: 15px;
	border-radius: 3px;
}

.logo-title {
	text-align: center;
	font-size: 23px;
	margin-top: 12px;
	line-height: 38px;
}

.login-form {
	margin-top: 35px;
}

.login-submit {
	width: 100%;
	height: 45px;
	letter-spacing: 2px;
	font-weight: 300;
}

.imgVerifySrc {
	height: 30px;
}

.section {
	padding-top: 80px;
	padding-bottom: 80px;
	position: relative;
}

.section-sm {
	padding: 50px 0;
}

.section-lg {
	padding: 100px 0;
}

.section-title {
	text-transform: uppercase;
	letter-spacing: 2px;
	margin-bottom: 0px;
	padding-bottom: 1rem;
	line-height: 1;
	font-size: 1.75rem;
	font-weight: 700;
}

.section-title-border {
	width: 50px;
	height: 2px;
	margin-right: auto;
	margin-left: auto;
}

.section-subtitle {
	letter-spacing: 0.02em;
}

.svg-pattern {
	width: 100%;
	position: relative;
}

iframe {
	max-width: 100%;
}

.hover-effect {
	transition: all 0.3s ease-in-out;
}
.hover-effect:hover {
	transform: translateY(-1rem);
}

/*----BACKGROUND COLOR-----*/

.bg-gray {
	background-color: #f7f7f7;
}

.bg-white {
	background-color: #fff;
}

.bg-dark {
	background-color: #393f4f !important;
}

.bg-light {
	background-color: #fafbfe !important;
}

/*=====================
  3. BOOTSTRAP RESET
  ======================*/

/*--Text color--- */

.text-muted {
	color: #858d96 !important;
}

.text-white {
	color: #fff !important;
}

.text-gray {
	color: #777 !important;
}

.text-dark {
	color: #212121 !important;
}

/*=======================================
          5.HOME 
  =====================================*/

.bg-home {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.home-title {
	font-size: 46px;
	line-height: 64px;
}

.home-small-title {
	font-size: 16px;
	text-transform: uppercase;
	letter-spacing: 7px;
}

.display-table-cell {
	display: table-cell;
	vertical-align: middle;
}

.display-table {
	display: table;
	width: 100%;
	height: 100%;
}

.home-up {
	z-index: 10;
	position: relative;
}

.play-btn {
	border: 2px solid;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	display: block;
	font-size: 30px;
	margin-right: auto;
	margin-left: auto;
	line-height: 60px;
	margin-top: 10px;
	text-align: center;
}

.play-shadow {
	border-radius: 50%;
	border: 1px solid transparent;
	width: 82px;
	height: 82px;
	margin: 20px auto 0px auto;
}

.home-desc {
	color: rgba(255, 255, 255, 0.7);
	font-size: 15px;
	max-width: 600px;
}

/* ------------- Demo 1 bg-pattern ------------- */

.home-half {
	padding-bottom: 150px;
	padding-top: 8%;
}

/* ------- Demo 9 wave animation --------- */

.wave-effect {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	height: 150px;
	overflow: hidden;
}

.shape-one {
	z-index: 15;
	opacity: 0.5;
}
.shape-two {
	z-index: 10;
	opacity: 0.75;
}
.shape-three {
	z-index: 5;
}

.wave {
	position: absolute;
	left: 0;
	width: 200%;
	height: 100%;
	background-repeat: repeat no-repeat;
	background-position: 0 bottom;
	transform-origin: center bottom;
}

.waves-shape {
	position: absolute;
	bottom: -1px;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.wave-one {
	background-size: 50% 100px;
}

.wave-anim .wave-one {
	animation: move-wave 3s;
	-webkit-animation: move-wave 3s;
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}
.wave-two {
	background-size: 50% 120px;
}
.wave-anim .wave-two {
	animation: move_wave 10s linear infinite;
}
.wave-three {
	background-size: 50% 100px;
}
.wave-anim .wave-three {
	animation: move_wave 15s linear infinite;
}

@keyframes move_wave {
	0% {
		transform: translateX(0) translateZ(0) scaleY(1);
	}
	50% {
		transform: translateX(-25%) translateZ(0) scaleY(0.55);
	}
	100% {
		transform: translateX(-50%) translateZ(0) scaleY(1);
	}
}

::v-deep .modal-content {
	background-color: #000000;
	color: white;
	background: none;
	border: none;
}
::v-deep .modal-header {
	border: none;
}
::v-deep .modal-backdrop {
	opacity: 0.5;
}
</style>