import request from '@/utils/request';

export function order_index(params) {
    return request({
        url: '/order',
        method: 'get',
        params: params,
    });
}

export function order_get(id, params) {
    return request({
        url: '/order/' + id,
        method: 'get',
        params: params,
    });
}

export function order_del(id, params = '') {
    return request({
        url: '/order/' + id,
        method: 'delete',
        params: params,
    });
}

export function order_put(params) {
    return request({
        url: '/order',
        method: 'put',
        params: params,
    });
}

export function order_ship(id, params) {
    return request({
        url: '/order/ship/' + id,
        method: 'put',
        params: params,
    });
}

export function order_analyze(params) {
    return request({
        url: '/order/analyze',
        method: 'get',
        params: params,
    });
}

export function refund_lists(params) {
    return request({
        url: '/refund/lists',
        method: 'get',
        params: params,
    });
}

export function refund_index(params) {
    return request({
        url: '/refund',
        method: 'get',
        params: params,
    });
}

export function refund_get(id, params) {
    return request({
        url: '/refund/' + id,
        method: 'get',
        params: params,
    });
}

export function refund_post(params) {
    return request({
        url: '/refund',
        method: 'post',
        data: params,
    });
}

export function refund_put(params) {
    return request({
        url: '/refund',
        method: 'put',
        params: params,
    });
}

export function refund_del(id, params = '') {
    return request({
        url: '/refund/' + id,
        method: 'delete',
        params: params,
    });
}

export function refund_do(id, params='') {
    return request({
        url: '/refund/do/' + id,
        method: 'put',
        params: params,
    });
}