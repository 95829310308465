<template>
	<div>
		<div class="header">
			<div class="mark-title" style="font-size: 16px; height: 36px; line-height: 36px;">活动信息</div>
		</div>
		<div style="margin-bottom: 52px;">
			<el-form :model="activity" style="padding:15px;" label-width="80px" v-loading="loading">
				<el-form-item label="名称">
					<el-input v-model="activity.name" placeholder="请输入名称" clearable style="width: 380px"></el-input>
				</el-form-item>
				<el-form-item label="跳转类型">
					<el-input v-model="activity.jump_type" placeholder="请输入跳转类型" clearable style="width: 380px"></el-input>
				</el-form-item>
				<el-form-item label="跳转链接">
					<el-input v-model="activity.jump_url" placeholder="请输入跳转链接" clearable style="width: 380px"></el-input>
				</el-form-item>
				<el-form-item label="有效期">
					<el-date-picker
						v-model="activity.valid_time"
						type="datetimerange"
						value-format="yyyy-MM-dd HH:mm:ss"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="排序">
					<el-input-number v-model="activity.sort" controls-position="right" :min="0"></el-input-number>
				</el-form-item>
				<el-form-item label="状态"> <el-switch v-model="activity.status"></el-switch></el-form-item>
				<el-form-item label="背景图">
					<el-upload
						:action="upload_url" 
						:headers="headers"
						list-type="picture-card"
						:on-success="beforeUpload"
						:limit="maxLimit"
						:file-list="activity.thumb"
						:on-remove="imageRemove">
						<i class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="账号简介">
					<el-input type="textarea" v-model="activity.remark" clearable :autosize="{ minRows: 4, maxRows: 6 }" maxlength="150" show-word-limit style="width: 530px"></el-input>
				</el-form-item>
			</el-form>
		</div>
		<div class="bottom-fixed">
			<div class="box-item">
				<el-button size="medium" @click="closeEvent()">取 消</el-button>
				<el-button size="medium" type="primary" @click="submitTap()">立即提交</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { Session } from '@/utils/storage';
import { activity_post, activity_get } from '@/api/common';
export default {
	name: 'activity_detail',
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: ()=>({}),
		},
	},
	data() {
		return {
			upload_url: process.env.VUE_APP_BASE_API + '/base/upload',
			headers: {
				"Authorization":  `Bearer ${Session.get('token')}`
			},
			activity: {
				thumb:[]
			},
			roles: [],
			loading: true,
			dialogImageUrl: '',
			dialogVisible: false,
			maxLimit: 1,
			treeData: [],
		};
	},
	created() {
		this.initData();
	},
	mounted() {
		if (Object.keys(this.data).length > 0) {
			activity_get(this.data.id)
				.then((res) => {
					this.activity = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
		}else{
			this.activity = {};
		}
	},
	methods: {
		initData() {
			this.loading = false;
		},
		beforeUpload(res) {
			if (res.data.statusCode === 200) {
				Message.error(res.message);
				return false;
			}
			this.activity.thumb = [{name: res.data.file_name, url: res.data.file_url}];
		},
		imageRemove() {
			this.activity.thumb = [];
        },
		closeEvent() {
			this.$emit('closeTap');
		},
		submitTap() {
			activity_post(this.activity)
				.then((res) => {
					if (res.statusCode === 200) {
						Message.success(res.message);
						this.$emit('closeTap');
					}
				});
		},
	},
};
</script>

<style scoped lang="scss">
.tb-edit .el-input,
.tb-edit .el-select {
	display: none;
}
.tb-edit .current-row .el-input,
.tb-edit .current-row .el-select {
	display: block;
}
.tb-edit .current-row .el-input + span,
.tb-edit .current-row .el-select + span {
	display: none;
}
.el-radio__input {
	display: none !important;
}
</style>
