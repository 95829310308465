import request from '@/utils/request';

export function notice_list(params) {
    return request({
        url: '/notice/lists',
        method: 'get',
        params: params,
    });
}

export function notice_index(params) {
    return request({
        url: '/notice',
        method: 'get',
        params: params,
    });
}

export function notice_get(id, params) {
    return request({
        url: '/notice/' + id,
        method: 'get',
        params: params,
    });
}

export function notice_post(params) {
    return request({
        url: '/notice',
        method: 'post',
        data: params,
    });
}

export function notice_put(id, params) {
    return request({
        url: '/notice/' + id,
        method: 'put',
        data: params,
    });
}

export function notice_del(id) {
    return request({
        url: '/notice/' + id,
        method: 'delete',
    });
}

export function notice_class_list(params) {
    return request({
        url: '/notice/class',
        method: 'get',
        params: params,
    });
}

export function notice_class_post(params) {
    return request({
        url: '/notice/class',
        method: 'post',
        data: params,
    });
}

export function notice_class_del(id, params = '') {
    return request({
        url: '/notice/class/' + id,
        method: 'delete',
        params: params,
    });
}