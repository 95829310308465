<template>
	<div class="home" style="padding: 15px">
		<!-- 用户信息 -->
		<el-row :gutter="15">
			<el-col :md="24" :lg="16" :xl="16" class="mb15">
				<el-card shadow="none">
					<div slot="header">
						<span>我的工作台</span>
					</div>
					<div class="user-item">
						<div class="user-item-left">
							<!-- <img :src="getUserInfos.avatar_url[0].url" /> -->
						</div>
						<div class="user-item-right overflow">
							<el-row>
								<el-col :span="24" class="right-title mb15 one-text-overflow">{{ currentTime }}，{{ getUserInfos.name }}，{{ dailyMessage }} </el-col>
								<el-col :span="24">
									<el-col :xs="12" :sm="12" :md="8" class="right-l-v">
										<div class="right-label">姓名：</div>
										<div class="right-value">{{ getUserInfos.name }}</div>
									</el-col>
									<el-col :xs="12" :sm="12" :md="16" class="right-l-v">
										<div class="right-label">角色：</div>
										<div class="right-value">{{ userInfo.userName === 'admin' ? '超级管理' : '普通用户' }}</div>
									</el-col>
								</el-col>
								<el-col :span="24" class="mt5">
									<!-- <el-col :xs="12" :sm="12" :md="8" class="right-l-v">
										<div class="right-label one-text-overflow">IP：</div>
										<div class="right-value one-text-overflow">192.168.1.1</div>
									</el-col> -->
									<el-col :xs="12" :sm="12" :md="16" class="right-l-v">
										<div class="right-label one-text-overflow">时间：</div>
										<div class="right-value one-text-overflow">{{ userInfo.time }}</div>
									</el-col>
								</el-col>

								<!-- <el-col :span="24" class="mt15 one-text-overflow" style="font-size: 16px">
									<el-col :xs="12" :sm="12" :md="8"> 在售商品: 12 </el-col>
									<el-col :xs="12" :sm="12" :md="8"> 下架商品: 16 </el-col>
								</el-col> -->
							</el-row>
						</div>
					</div>
				</el-card>
			</el-col>
			<el-col :md="24" :lg="8" :xl="8" class="mb15">
				<el-card shadow="none">
					<div slot="header">
						<span>消息通知</span>
						<el-button class="home-card-more" type="text" @click="onOpenNotice">更多</el-button>
					</div>
					<div class="info">
						<Scroll :data="newsInfoList" class="info-scroll" :class-option="optionSingleHeight">
							<ul class="info-ul">
								<li v-for="(v, k) in newsInfoList" :key="k" class="info-item" @click="onNewsInfoListClick(v)">
									<i class="iconfont icon-dian" />
									<div class="info-item-left" v-text="v.title"></div>
									<div class="info-item-right" v-text="v.date"></div>
								</li>
							</ul>
						</Scroll>
					</div>
				</el-card>
			</el-col>
		</el-row>

		<!-- 推荐 -->
		<!-- <el-card shadow="none">
			<div slot="header">
				<span>营销推荐</span>
				<el-button class="home-card-more" type="text" @click="onOpenNotice">更多</el-button>
			</div>
			<el-row :gutter="15" class="home-recommend-row">
				<el-col :sm="24" :md="12" :lg="6" :xl="6" v-for="(v, k) in recommendList" :key="k">
					<div class="home-recommend" :style="{ 'background-color': v.bg }">
						<i :class="v.icon" :style="{ color: v.iconColor }"></i>
						<div class="home-recommend-auto">
							<div>{{ v.title }}</div>
							<div class="home-recommend-msg">{{ v.msg }}</div>
						</div>
					</div>
				</el-col>
			</el-row>
		</el-card> -->

		<!-- v-charts -->
		<!-- <el-row :gutter="15" class="mb15">
			<el-col :md="24" :lg="16" :xl="16" class="home-lg">
				<el-card shadow="none">
					<div slot="header">
						<span>订单监控</span>
					</div>
					<div class="charts">
						<div class="charts-left mr7">
							<div ref="homeOvertimeRef" class="h100"></div>
						</div>
					</div>
				</el-card>
			</el-col>
			<el-col :md="24" :lg="8" :xl="8">
				<el-card shadow="none">
					<div slot="header">
						<span>待办事项</span>
					</div>
					<div class="home-charts">
						<div class="home-charts-item" v-for="(v, k) in chartsRightList" :key="k">
							<div class="home-charts-item-left">
								<div class="home-charts-item-title">{{ v.title }}</div>
								<div class="home-charts-item-num" :style="{ color: v.color }" :id="`titleNum${k + 1}`"></div>
							</div>
							<div class="home-charts-item-right">
								<i :class="v.icon" :style="{ 'background-color': v.iconBg, color: v.color }"></i>
							</div>
						</div>
					</div>
				</el-card>
			</el-col>
		</el-row> -->
	</div>
</template>

<script>
import * as echarts from 'echarts';
import Scroll from 'vue-seamless-scroll';
import { CountUp } from 'countup.js';
import { Session } from '@/utils/storage';
import { order_analyze } from '@/api/order';
import { formatAxis, formatDate } from '@/utils/formatTime';
import { notice_index } from '@/api/notice';
import { recommendList, chartsRightList, dailyMessage } from './mock';
export default {
	name: 'home',
	components: { Scroll },
	data() {
		return {
			recommendList,
			chartsRightList,
			newsInfoList: [],
			userInfo: {},
			dailyMessage: {},
		};
	},
	created() {
		this.initData();
		this.initDailyMessage();
	},
	computed: {
		currentTime() {
			return formatAxis(new Date());
		},
		optionSingleHeight() {
			return {
				singleHeight: 28,
				limitMoveNum: 8,
				waitTime: 2000,
			};
		},
		getUserInfos() {
			return this.$store.state.userInfos.userInfos;
		},
	},
	mounted() {
		// this.initHomeStock();
		// this.initHomeLaboratory();
		this.initHomeOvertime();
		this.initNumCountUp();
	},
	methods: {
		// 初始化数字滚动
		initNumCountUp() {
			this.$nextTick(() => {
				new CountUp('titleNum1', Math.random() * 100000).start();
				new CountUp('titleNum2', Math.random() * 100000).start();
				new CountUp('titleNum3', Math.random() * 100000).start();
			});
		},
		// 缺货监控
		initHomeOvertime() {
			var param = {
				time: ['2022-05-04 00:00:00', '2022-06-01 23:59:59'],
			};
			order_analyze({ ...param })
				.then((res) => {
					if (res.statusCode !== 200) {
						this.$message({
							type: 'error',
							message: res.message,
						});
						return true;
					}
					const myChart = echarts.init(this.$refs.homeOvertimeRef);
					const option = {
						grid: {
							top: 50,
							right: 20,
							bottom: 30,
							left: 30,
						},
						tooltip: {
							trigger: 'axis',
						},
						legend: {
							data: ['每日销售金额'],
							right: 13,
						},
						xAxis: {
							data: res.data.datadate,
						},
						yAxis: [
							{
								type: 'value',
								name: '金额',
							},
						],
						series: [
							{
								name: '每日销售金额',
								type: 'line',
								data: res.data.order_amount,
							},
						],
					};
					myChart.setOption(option);
					window.addEventListener('resize', () => {
						myChart.resize();
					});
				})
				.finally(() => {});
		},
		// 随机语录
		initDailyMessage() {
			this.dailyMessage = dailyMessage[Math.floor(Math.random() * dailyMessage.length)];
		},
		// 初始化登录信息
		initData() {
			if (!Session.get('userInfo')) return false;
			this.userInfo = Session.get('userInfo');
			this.userInfo.time = formatDate(new Date(this.userInfo.time), 'YYYY-mm-dd HH:MM:SS');
			var param = {};
			notice_index({ ...param })
				.then((res) => {
					this.newsInfoList = res.data;
				})
				.catch(() => {});
		},
		// 消息通知当前项点击
		onNewsInfoListClick(v) {
			// this.$router.push(v.jump_url);
		},
		// 跳转到
		onOpenNotice() {
			// this.$router.push('/notice');
		},
	},
};
</script>

<style scoped lang="scss">
@import './index.scss';
</style>
