<template>
	<div class="lum-dialog-mask">
		<el-container class="lum-dialog-box" :class="{ 'full-screen': fullscreen }">
			<el-header height="60px" class="header">
				<p class="title">
					<span>二联概率组</span>
				</p>
				<p class="tools">
					<i
						class="iconfont"
						style="transform: scale(0.85)"
						:class="fullscreen ? 'icon-tuichuquanping' : 'icon-quanping'"
						@click="fullscreen = !fullscreen"
					/>
					<i class="el-icon-close" @click="$emit('closeTap')" />
				</p>
			</el-header>

			<el-main class="full-height ov-hidden" style="padding: 15px">
				<div :class="'box-modal-show'" style="margin: 12px 12px 58px 12px">
					<div>
						<el-button size="small" type="primary" icon="el-icon-edit" @click="addDeal()">添加概率</el-button>
						<el-button size="small" type="primary" icon="el-icon-edit" @click="initData()">刷新</el-button>
					</div>
					<el-table v-loading="loading" :data="tableData" stripe style="width: 100%">
						<el-table-column type="selection" width="55"></el-table-column>
						<el-table-column label="ID">
							<template #default="scope">
								<span>{{ scope.row.id }}</span>
							</template>
						</el-table-column>
						<el-table-column label="名称">
							<template #default="scope">
								<el-input v-model="scope.row.title" placeholder="请输入名称" clearable></el-input>
							</template>
						</el-table-column>
						<el-table-column label="概率">
							<template #default="scope"> <el-input-number v-model="scope.row.rate" placeholder="请输入概率" :min="0"></el-input-number> % </template>
						</el-table-column>
						<el-table-column label="图片" sortable>
							<template #default="scope">
								<div class="image-upload-container">
									<el-image v-if="scope.row.url" :src="scope.row.url" :preview-src-list="[scope.row.url]" fit="cover" title="点击预览" />
									<el-upload
										:show-file-list="false"
										:action="upload_url"
										:headers="headers"
										:before-upload="beforeUpload"
										:on-success="(res) => imageUpload(res, scope)"
										class="images-upload"
									>
										<el-button size="small" icon="el-icon-upload2">{{ scope.row.url ? '重新上传' : '上传图片' }}</el-button>
									</el-upload>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="125">
							<template #default="scope">
								<el-button size="mini" type="text" @click="saveDeal(scope.row)">保存</el-button>
								<el-button size="mini" type="text" @click="deleteDeal(scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<el-card shadow="none">
						<div slot="header">
							<span>总计</span>
						</div>
						<div class="info">
							<span>中奖概率: {{ shotRate }}%, 不中奖概率: {{ 100 - shotRate }}%</span>
						</div>
					</el-card>
				</div>
			</el-main>
		</el-container>
	</div>
</template>

<script>
import { second_pool_post, second_pool_list, second_pool_del } from '@/api/blind_box';
import { Session } from '@/utils/storage';
export default {
	name: 'secondPool',
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			deal_group: {},
			loading: false,
			tableData: [],
			temp_key: 0,
			curIndex: 0,
			fullscreen: false,
			upload_url: process.env.VUE_APP_BASE_API + '/base/upload',
			headers: {
				Authorization: `Bearer ${Session.get('token')}`,
			},
		};
	},
	computed: {
		shotRate: function () {
			return this.tableData.reduce((pre, cur) => pre + cur.rate, 0);
		},
	},
	mounted() {
		if (this.data !== null) {
			this.deal_group = this.data;
		}
		this.initData();
	},
	methods: {
		initData() {
			this.loading = true;
			var param = {
				rule_id: this.data.id ?? undefined,
			};
			second_pool_list({ ...param })
				.then((res) => {
					if (res.statusCode !== 200) {
						this.$message({
							type: 'error',
							message: res.message,
						});
						return true;
					}
					this.tableData = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		closeTap() {
			this.$emit('closeTap');
		},
		addDeal() {
			this.tableData.push({
				id: undefined,
				title: '',
				rate: 0,
				url: '',
				key: this.temp_key,
			});
			this.temp_key += 1;
		},
		deleteDeal(e) {
			if (e.id === undefined && e.key !== undefined) {
				this.tableData = this.tableData.filter((item) => item.key != e.key);
				return;
			}
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				this.loading = true;
				second_pool_del(e.id)
					.then((res) => {
						if (res.statusCode != 200) {
							this.$message({
								type: 'error',
								message: res.message,
							});
							return false;
						}
						this.initData();
					})

					.finally(() => {
						this.loading = false;
					});
			});
		},
		saveDeal(e) {
			this.loading = true;
			delete e.key;
			second_pool_post({ ...e, rule_id: this.data.id })
				.then((res) => {
					if (res.statusCode != 200) {
						this.$message({
							type: 'error',
							message: res.message,
						});
						return false;
					}
					this.initData();
				})
				.finally(() => {
					this.loading = false;
				});
		},
		showMaterialTap(index) {
			this.curIndex = index;
			this.is_material = !this.is_material;
		},
		materialTap(data) {
			if (data && data.length > 0) {
				this.tableData[this.curIndex].url = data[data.length - 1].url;
			}
			this.is_material = false;
		},
		beforeUpload(file) {
			const ext = ['jpg', 'png', 'gif', 'bmp'];
			const size = 5;
			const fileName = file.name.split('.');
			const fileExt = fileName[fileName.length - 1];
			const isTypeOk = ext.indexOf(fileExt) >= 0;
			const isSizeOk = file.size / 1024 / 1024 < size;
			if (!isTypeOk) {
				this.$message.error(`上传图片只支持 ${ext.join(' / ')} 格式！`);
			}
			if (!isSizeOk) {
				this.$message.error(`上传图片大小不能超过 ${size}MB！`);
			}
			return isTypeOk && isSizeOk;
		},
		// 图片上传
		imageUpload(res, data) {
			data.row.url = res.data.file_url;
			this.$message.success('图片上传成功');
		},
	},
};
</script>
<style lang="less" scoped>
/deep/.el-scrollbar__wrap {
	overflow-x: hidden;
}

.lum-dialog-mask {
	z-index: 999;
}

.lum-dialog-box {
	width: 100%;
	height: 800px;
	max-width: 1000px;
	transition: 1s ease;
	&.full-screen {
		width: 100%;
		height: 100%;
		max-width: unset;
		margin: 0;
		border-radius: 0px;
	}
}
</style>
