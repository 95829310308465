<template>
	<div class="lum-dialog-mask">
		<el-container class="lum-dialog-box" :class="{ 'full-screen': fullscreen }">
			<el-header height="60px" class="header">
				<p class="title">
					<span>发货</span>
				</p>
				<p class="tools">
					<i
						class="iconfont"
						style="transform: scale(0.85)"
						:class="fullscreen ? 'icon-tuichuquanping' : 'icon-quanping'"
						@click="fullscreen = !fullscreen"
					/>
					<i class="el-icon-close" @click="$emit('closeTap')" />
				</p>
			</el-header>

			<el-container class="full-height ov-hidden scrollbar-wrapper">
				<div style="padding: 15px 15px">
					<el-divider content-position="left">订单信息</el-divider>
					<div style="padding: 0 15px 0 15px;">
						<el-descriptions class="margin-top" :column="2">
							<el-descriptions-item label="订单号">{{order.order_no}}</el-descriptions-item>
							<el-descriptions-item label="订单状态">{{order.status_name}}</el-descriptions-item>
							<el-descriptions-item label="应付金额">{{order.raw_amount}}</el-descriptions-item>
							<el-descriptions-item label="实付金额">{{order.amount}}</el-descriptions-item>
							<el-descriptions-item label="收件人">{{order.contact}}</el-descriptions-item>
							<el-descriptions-item label="联系方式">{{order.moblie}}</el-descriptions-item>
							<el-descriptions-item label="收件地址">{{order.address}}</el-descriptions-item>
						</el-descriptions>
					</div>
					<el-divider content-position="left">物流信息</el-divider>
					<el-form :model="refund" label-width="80px">
						<el-row :gutter="20">
							<el-col :span="24">
								<el-form-item label="快递名称">
									<el-input v-model="refund.shipping_name" placeholder="请输入快递名称" clearable></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="快递单号">
									<el-input v-model="refund.shipping_code" placeholder="快递单号" clearable></el-input>
								</el-form-item>
							</el-col>
							<!--<el-col :span="24">
								<el-form-item label="收件人">
									<el-input v-model="order.key" placeholder="请输入收件人" clearable></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="联系方式">
									<el-input v-model="order.value" placeholder="请输入联系方式" clearable></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="收货地址">
									<el-input v-model="order.value" placeholder="请输入收货地址" clearable></el-input>
								</el-form-item>
							</el-col>-->
						</el-row>
					</el-form>
				</div>
			</el-container>
			<el-footer style="height:45px; line-height:45px;" class="footer">
				<div style="width: 178px">
					<el-button size="mini" @click="closeEvent()">取消</el-button>
					<el-button size="mini" type="primary" @click="submitTap()">确认提交</el-button>
				</div>
			</el-footer>
		</el-container>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { order_ship } from '@/api/order';
export default {
	name: 'fullscreen',
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
	},

	data() {
		return {
			fullscreen: false,
			order: {},
			refund: {}
		};
	},
	mounted() {
		if (this.data !== null) {
			this.order = this.data;
		}
		this.isVisible = this.visible;
	},
	methods: {
		closeEvent() {
			this.$emit('closeTap');
		},
		submitTap() {
			order_ship(this.order.order_id, this.refund)
				.then((res) => {
					if (res.statusCode == 200) {
						Message.success(res.message);
						this.$emit('closeTap');
					}
				})
				.catch(() => {});
		},
	},
};
</script>
<style lang="less" scoped>
/deep/.el-scrollbar__wrap {
	overflow-x: hidden;
}

.scrollbar-wrapper {
   overflow-x: hidden !important;
 
   &::-webkit-scrollbar {
      width: 17px;
   }
 }

.lum-dialog-mask {
	z-index: 99;
}

.lum-dialog-box {
	width: 100%;
	height: 470px;
	max-width: 720px;
	transition: 1s ease;
	&.full-screen {
		width: 100%;
		height: 100%;
		max-width: unset;
		margin: 0;
		border-radius: 0px;
	}
}
</style>
