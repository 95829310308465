<template>
	<div>
		<div style="padding: 15px">
			<div style="border-radius: 4px; border: 1px solid #ebeef5; height: calc(100% - 10px)">
				<el-divider content-position="left">用户信息</el-divider>
				<div style="padding: 15px 15px 0 15px">
					<el-descriptions class="margin-top" :column="3">
						<el-descriptions-item label="昵称">{{ user.nickname }}（{{ user.invite_code }}）</el-descriptions-item>
						<el-descriptions-item label="性别">{{ user.sex_name }}</el-descriptions-item>
						<el-descriptions-item label="注册IP">{{ user.register_ip }}</el-descriptions-item>
						<el-descriptions-item label="状态">{{ user.status_name }}</el-descriptions-item>
						<el-descriptions-item label="余额">{{ user.balance }}</el-descriptions-item>
						<el-descriptions-item label="成长值">{{ user.growth }}</el-descriptions-item>
						<el-descriptions-item label="积分">{{ user.point }}</el-descriptions-item>
						<el-descriptions-item label="会员等级">{{ user.vip_name }}</el-descriptions-item>
						<el-descriptions-item label="手机号码">{{ user.mobile }}</el-descriptions-item>
						<el-descriptions-item label="注册时间">{{ user.created_at }}</el-descriptions-item>
						<el-descriptions-item label="OpenId">{{ user.openid }}</el-descriptions-item>
					</el-descriptions>
				</div>
				<el-tabs v-model="activeName" type="card" size="mini" @tab-click="handleClick">
					<el-tab-pane label="积分记录" name="point">
						<div style="padding: 0 15px 0 15px">
							积分变动：<el-input-number label="增加" v-model="changed_point" controls-position="right"></el-input-number>
							<el-button style="margin-left: 15px" size="medium" type="primary" @click="modifyPoint(user.uid)">保存</el-button>
							<el-table :data="pointData" stripe style="width: 100%">
								<el-table-column fixed type="selection" width="55"></el-table-column>
								<el-table-column fixed label="ID" width="85">
									<template #default="scope">
										<span>{{ scope.row.id }}</span>
									</template>
								</el-table-column>
								<el-table-column label="类型">
									<template #default="scope">
										<span v-if="scope.row.symbol == 'in'" class="color-primary">增加</span>
										<span v-else class="color-danger">扣减</span>
									</template>
								</el-table-column>
								<el-table-column label="积分值">
									<template #default="scope">
										<span>{{ scope.row.point }}</span>
									</template>
								</el-table-column>
								<el-table-column label="时间">
									<template #default="scope">
										<span>{{ scope.row.created_at }}</span>
									</template>
								</el-table-column>
								<el-table-column label="来源">
									<template #default="scope">
										<span>{{ point_map[scope.row.source_type] }}</span>
									</template>
								</el-table-column>
							</el-table>
							<div class="block" style="margin-top: 15px">
								<el-pagination
									@size-change="handleSizeChange"
									@current-change="handleCurrentChange"
									:current-page="pageInfo.now"
									:page-sizes="[10, 20, 50, 100, 200]"
									:page-size="pageInfo.size"
									layout="total, sizes, prev, pager, next, jumper"
									:total="pageInfo.count"
								>
								</el-pagination>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="地址信息" name="address">
						<div style="padding: 0 15px 0 15px">
							<el-table :data="addressData" stripe style="width: 100%">
								<el-table-column fixed type="selection" width="55"></el-table-column>
								<el-table-column fixed label="ID" width="85">
									<template #default="scope">
										<span>{{ scope.row.id }}</span>
									</template>
								</el-table-column>
								<el-table-column label="收件人">
									<template #default="scope">
										<span>{{ scope.row.contact }}</span>
									</template>
								</el-table-column>
								<el-table-column label="联系方式">
									<template #default="scope">
										<span>{{ scope.row.moblie }}</span>
									</template>
								</el-table-column>
								<el-table-column label="是否默认">
									<template #default="scope">
										<span v-if="scope.row.is_default == 1" class="color-primary">是</span>
										<span v-else class="color-info">否</span>
									</template>
								</el-table-column>
								<el-table-column label="收件地址">
									<template #default="scope">
										<span class="color-info">{{ scope.row.province }}{{ scope.row.city }}{{ scope.row.region }}{{ scope.row.address }}</span>
									</template>
								</el-table-column>
								<el-table-column fixed="right" label="操作" width="155">
									<template #default="scope">
										<el-button size="mini" type="text">移除</el-button>
									</template>
								</el-table-column>
							</el-table>
							<div class="block" style="margin-top: 15px">
								<el-pagination
									@size-change="handleSizeChange"
									@current-change="handleCurrentChange"
									:current-page="pageInfo.now"
									:page-sizes="[10, 20, 50, 100, 200]"
									:page-size="pageInfo.size"
									layout="total, sizes, prev, pager, next, jumper"
									:total="pageInfo.count"
								>
								</el-pagination>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="优惠券信息" name="coupon">
						<div style="padding: 0 15px 0 15px">
							<el-table :data="couponData" stripe style="width: 100%">
								<el-table-column fixed type="selection" width="55"></el-table-column>
								<el-table-column fixed label="ID" width="85">
									<template #default="scope">
										<span>{{ scope.row.id }}</span>
									</template>
								</el-table-column>
								<el-table-column label="名称">
									<template #default="scope">
										<span>{{ scope.row.name }}</span>
									</template>
								</el-table-column>
								<el-table-column label="简介">
									<template #default="scope">
										<span>{{ scope.row.remark }}</span>
									</template>
								</el-table-column>
								<el-table-column label="状态">
									<template #default="scope">
										<span v-if="scope.row.status == 1" class="color-primary">已使用</span>
										<span v-else class="color-danger">未使用</span>
									</template>
								</el-table-column>
								<el-table-column label="优惠券类型">
									<template #default="scope">
										<span v-if="scope.row.coupon_type == 1">直减现金券</span>
										<span v-else>满减现金券</span>
									</template>
								</el-table-column>
								<el-table-column label="优惠券金额">
									<template #default="scope">
										<span class="color-info">{{ scope.row.coupon_amount }}</span>
									</template>
								</el-table-column>
								<el-table-column label="满减金额">
									<template #default="scope">
										<span class="color-info">{{ scope.row.full_amount }}</span>
									</template>
								</el-table-column>
								<el-table-column fixed="right" label="操作" width="155">
									<template #default="scope">
										<el-button size="mini" type="text">移除</el-button>
									</template>
								</el-table-column>
							</el-table>
							<div class="block" style="margin-top: 15px">
								<el-pagination
									@size-change="handleSizeChange"
									@current-change="handleCurrentChange"
									:current-page="pageInfo.now"
									:page-sizes="[10, 20, 50, 100, 200]"
									:page-size="pageInfo.size"
									layout="total, sizes, prev, pager, next, jumper"
									:total="pageInfo.count"
								>
								</el-pagination>
							</div>
						</div>
					</el-tab-pane>

					<!--<el-tab-pane label="商品足迹" name="browse">
						<div style="padding: 0 15px 0 15px;">
							<el-table :data="user.coupon" stripe style="width: 100%">
								<el-table-column fixed type="selection" width="55"></el-table-column>
									<el-table-column fixed label="ID" width="85">
										<template #default="scope">
											<span>{{ scope.row.id }}</span>
										</template>
									</el-table-column>
									<el-table-column label="商品名称">
										<template #default="scope">
											<span>{{ scope.row.name }}</span>
										</template>
									</el-table-column>
									<el-table-column label="商品封面">
										<template #default="scope">
											<span>{{ scope.row.remake }}</span>
										</template>
									</el-table-column>
									<el-table-column label="浏览时间">
										<template #default="scope">
											<span class="color-info">{{ scope.row.coupon_amount }}</span>
										</template>
									</el-table-column>
									<el-table-column fixed="right" label="操作" width="155">
										<template #default="scope">
											<el-button size="mini" type="text">移除</el-button>
										</template>
									</el-table-column>
							</el-table>
							<div class="block" style="margin-top: 15px">
								<el-pagination
									@size-change="handleSizeChange"
									@current-change="handleCurrentChange"
									:current-page="pageInfo.now"
									:page-sizes="[10, 20, 50, 100, 200]"
									:page-size="pageInfo.size"
									layout="total, sizes, prev, pager, next, jumper"
									:total="pageInfo.count"
								>
								</el-pagination>
							</div>
						</div>
					</el-tab-pane>-->
					<el-tab-pane label="订单信息" name="order">
						<div style="padding: 0 15px 0 15px">
							<el-table :data="orderData" stripe style="width: 100%">
								<el-table-column fixed type="selection" width="55"></el-table-column>
								<el-table-column label="订单号">
									<template #default="scope">
										<span>{{ scope.row.order_no }}</span>
									</template>
								</el-table-column>
								<el-table-column label="状态">
									<template #default="scope">
										<span>{{ scope.row.status_name }}</span>
									</template>
								</el-table-column>
								<el-table-column label="总金额">
									<template #default="scope">
										<span>{{ scope.row.amount }}</span>
									</template>
								</el-table-column>
								<el-table-column label="收件人">
									<template #default="scope">
										<span>{{ scope.row.contact }}</span>
									</template>
								</el-table-column>
								<el-table-column label="下单时间">
									<template #default="scope">
										<span>{{ scope.row.create_time }}</span>
									</template>
								</el-table-column>
								<el-table-column label="支付时间">
									<template #default="scope">
										<span>{{ scope.row.payment_time }}</span>
									</template>
								</el-table-column>
								<el-table-column fixed="right" label="操作" width="155">
									<template #default="scope">
										<el-button size="mini" type="text">移除</el-button>
									</template>
								</el-table-column>
							</el-table>
							<div class="block" style="margin-top: 15px">
								<el-pagination
									@size-change="handleSizeChange"
									@current-change="handleCurrentChange"
									:current-page="pageInfo.now"
									:page-sizes="[10, 20, 50, 100, 200]"
									:page-size="pageInfo.size"
									layout="total, sizes, prev, pager, next, jumper"
									:total="pageInfo.count"
								>
								</el-pagination>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
		<div class="bottom-fixed">
			<div class="box-item">
				<el-button size="medium" @click="closeEvent()">取 消</el-button>
				<el-button size="medium" type="primary" @click="submitTap()">返 回</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { Session } from '@/utils/storage';
import { order_index } from '@/api/order';
import { user_get, user_coupon, address_index, user_point, add_point } from '@/api/user';
export default {
	name: 'user_detail',
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			selected: [],
			material_type: 'image',
			limit: 1,
			point_map: ['未知', '任务', '系统', '订单', '签到', '兑换奖品', '盲盒转积分'],
			changed_point: 0,
			upload_url: process.env.VUE_APP_BASE_API + '/base/upload',
			headers: {
				Authorization: `Bearer ${Session.get('token')}`,
			},
			user: {
				avatar_url: [],
			},
			couponData: [],
			addressData: [],
			orderData: [],
			roles: [],
			pointData: [],
			pageInfo: {
				now: 1,
				size: 20,
			},
			loading: true,
			treeData: [],
			activeName: 'point',
		};
	},
	mounted() {
		if (Object.keys(this.data).length > 0) {
			user_get(this.data.uid)
				.then((res) => {
					this.user = res.data;
					this.pointInit();
				})
				.finally(() => {
					this.loading = false;
				});
		} else {
			this.user = {};
		}
	},
	methods: {
		handleClick(tab, event) {
			if (tab.name == 'order') {
				this.orderInit();
			} else if (tab.name == 'address') {
				this.addressInit();
			} else if (tab.name == 'coupon') {
				this.couponInit();
			} else if (tab.name == 'point') {
				this.pointInit();
			}
		},
		modifyPoint(uid) {
			var param = {
				point: this.changed_point,
			};
			add_point(uid, { ...param })
				.then((res) => {
					if (res.statusCode == 200) {
						Message.success(res.message);
						this.pointInit();
					} else {
						Message.error('改动失败');
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
		addressInit() {
			var param = {
				uid: this.user.uid || undefined,
				pageSize: this.pageInfo.size || undefined,
				pageNow: this.pageInfo.now || undefined,
			};
			address_index({ ...param })
				.then((res) => {
					this.pageInfo = res.page;
					this.addressData = res.lists;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		pointInit() {
			this.loading = true;
			var param = {
				uid: this.user.uid || undefined,
				pageSize: this.pageInfo.size || undefined,
				pageNow: this.pageInfo.now || undefined,
			};
			user_point({ ...param })
				.then((res) => {
					this.pageInfo = res.page;
					this.pointData = res.lists;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		couponInit() {
			var param = {
				uid: this.user.uid || undefined,
				pageSize: this.pageInfo.size || undefined,
				pageNow: this.pageInfo.now || undefined,
			};
			user_coupon({ ...param })
				.then((res) => {
					this.pageInfo = res.page;
					this.couponData = res.lists;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		orderInit() {
			var param = {
				uid: this.user.uid || undefined,
				pageSize: this.pageInfo.size || undefined,
				pageNow: this.pageInfo.now || undefined,
			};
			order_index({ ...param })
				.then((res) => {
					this.pageInfo = res.page;
					this.orderData = res.lists;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		// removeThumbEven(file) {
		// 	this.user.avatar_url = [];
		// 	// var thumbs = [];
		// 	// fileList.forEach(function(item){
		// 	// 	thumbs.push({name: item.response.data.file_name, url: item.response.data.file_url});
		// 	// });
		// 	// this.user.avatar_url = thumbs;
		// },
		// beforeUploadThumb(response, file){
		// 	// this.user.avatar_url.push({name: response.data.file_name, url: response.data.file_url});
		// },
		closeEvent() {
			this.$emit('closeTap');
		},
		submitTap() {
			this.$emit('closeTap');
		},
		handleSizeChange(val) {
			this.loading = true;
			this.pageInfo.size = val;
			this.initData();
		},
		handleCurrentChange(val) {
			this.loading = true;
			this.pageInfo.now = val;
			this.initData();
		},
	},
};
</script>

<style scoped lang="scss">
.tb-edit .el-input,
.tb-edit .el-select {
	display: none;
}
.tb-edit .current-row .el-input,
.tb-edit .current-row .el-select {
	display: block;
}
.tb-edit .current-row .el-input + span,
.tb-edit .current-row .el-select + span {
	display: none;
}
.el-radio__input {
	display: none !important;
}
</style>
