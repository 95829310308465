<template>
	<div class="system-menu-container" style="padding:15px;">
		<el-row>
			<el-col :span="4">
				<el-input placeholder="输入关键字进行过滤" size="medium" v-model="filterText"> </el-input>
				<el-tree
					class="filter-tree mt15"
					:data="materialTreeData"
					label="name"
					highlight-current
					:props="{ label: 'name' }"
					default-expand-all
					:filter-node-method="filterNode"
					ref="tree"
				>
				</el-tree>
			</el-col>
			<el-col :span="20" style="padding-left:15px;">
				<el-card shadow="never">
					<div style="padding-bottom: 15px">
						<el-row>
							<el-button type="danger" size="small" icon="el-icon-edit" @click="handleStore(2)">新建文件夹</el-button>
							<el-button type="primary" size="small" icon="el-icon-upload" :loading="loadStatus" @click="$refs.uploads.click()"
								>{{ loadStatus ? '上传中...' : '上传附件' }}
							</el-button>
							<input ref="uploads" type="file" @change="upload" style="display: none" />
						</el-row>
					</div>

					<el-input type="hidden" v-model="dir_id"> </el-input>
					<el-table  v-loading="loading" :data="tableData" stripe style="width: 100%">
						<el-table-column fixed type="selection" width="55" align="center"></el-table-column>
						<el-table-column fixed label="文件名称" sortable show-overflow-tooltip>
							<template #default="scope">
								<span v-if="scope.row.size">
									<i class="el-icon-picture-outline"></i>
									<!-- <i class="iconfont icon-yangan"></i> -->
									<span class="ml10">{{ scope.row.name }}</span>
								</span>
								<span v-else>
									<i class="el-icon-folder-opened" style="color:rgb(159 156 141)"></i>
									<el-link class="ml10" @click="handleLoad(scope.row)">{{ scope.row.name }}</el-link>
								</span>
							</template>
						</el-table-column>
						<el-table-column label="大小" show-overflow-tooltip>
							<template #default="scope">
								<span v-if="scope.row.size">{{ scope.row.size }}</span>
								<span v-else>-</span>
							</template>
						</el-table-column>
						<el-table-column prop="updated_at" label="修改时间" sortable></el-table-column>
						<el-table-column fixed="right" label="操作" width="145">
							<template #default="scope">
								<el-button size="mini" type="text" @click="handleDowload(scope.row)">下载</el-button>
								<!-- <el-button size="mini" type="text" @click="handleShare(scope.row)">分享</el-button> -->
								<el-button size="mini" type="text" @click="handleStore(1, scope.row)">重命名</el-button>
								<el-button size="mini" class="color-danger" type="text" @click="handleDel(scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import { material_list, material_tree, material_dir, material_post, material_del, material_put,material_dowload } from '@/api/material';
export default {
	name: 'material',
	data() {
		return {
			dialogVisible: false,
			loadStatus: false,
			tableData: [],
			filterText: '',
			pageInfo: {
				now: 1,
				size: 20
			},
			loading: true,
			dir_id: 0,
			materialData: [],
			visible: false,
			materialTreeData: [],
		};
	},
	created() {
		this.initData();
	},
	watch: {
		filterText(val) {
			this.$refs.tree.filter(val);
		},
	},
	methods: {
		initData() {
			var param = {
				dir_id: this.dir_id || 0,
			};
			material_list({ ...param })
				.then((res) => {
					if (res.statusCode !== 200) {
						this.$message({
							type: 'error',
							message: res.message
						});
						return true;
					}
					this.tableData = res.data;
				})
				.finally(() => {
					this.loading = false;
				});

			material_tree({ ...param })
				.then((res) => {
					if (res.statusCode !== 200) {
						this.$message({
							type: 'error',
							message: res.message
						});
						return true;
					}
					this.materialTreeData = res.data;
				})
				.catch(() => {});
		},
		handleStore(type, e) {
			if (type == 1) {
				this.$prompt('重命名', '提示', {
					inputValue: e.name,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				})
					.then(({ value }) => {
						let params = {
							type: e.size == undefined ? 2 : 1,
							name: value,
						};
						material_put(e.id, params)
							.then((res) => {
								if (res.statusCode == 200) {
									this.initData();
									this.$message({
										type: 'success',
										message: res.message,
									});
								} else {
									this.$message({
										type: 'error',
										message: res.message,
									});
								}
							})
							.catch(() => {});
					})
					.catch(() => {});
			} else {
				this.$prompt('新建文件夹', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				})
					.then(({ value }) => {
						let params = {
							dir_id: this.dir_id || 0,
							name: value,
						};
						material_dir(params)
							.then((res) => {
								if (res.statusCode == 200) {
									this.initData();
									this.$message({
										type: 'success',
										message: res.message,
									});
								} else {
									this.$message({
										type: 'error',
										message: res.message,
									});
								}
							})
							.catch(() => {});
					})
					.catch(() => {});
			}
		},
		handleDowload(e) {
			let params = {
				type: e.size == undefined ? 2 : 1,
			}
			material_dowload(e.id, params)
				.then((res) => {
					if (res.statusCode !== 200) {
						this.$message({
							type: 'error',
							message: res.message,
						});
					}
				})
				.catch(() => {});
		},
		filterNode(value, data) {
			if (!value) return true;
			return data.label.indexOf(value) !== -1;
		},
		upload(e) {
			if (e.target.files.length == 0) {
				return false;
			}
			
			let file = e.target.files[0];
			let fileData = new FormData();
			fileData.append('file[]', file);
			fileData.append('dir_id', this.dir_id);

			this.loadStatus = true;
			material_post(fileData)
				.then((res) => {
					if (res.statusCode == 200) {
						this.$message({
							type: 'success',
							message: '上传成功！',
						});
						this.initData();
					} else {
						this.$message({
							type: 'error',
							message: res.message,
						});
					}
					this.loadStatus = false;
				})
				.catch(() => {});
		},
		handleLoad(e) {
			if (e.size == undefined) {
				this.dir_id = e.id;
				this.initData();
				return;
			}
		},
		handleDel(e) {
			this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					let params = {
						type: e.size == undefined ? 2 : 1,
					};
					material_del(e.id, params)
						.then((res) => {
							if (res.statusCode == 200) {
								this.$message({
									type: 'success',
									message: res.message,
								});
								this.initData();
							} else {
								this.$message({
									type: 'error',
									message: res.message,
								});
							}
							this.loadStatus = false;
						})
						.catch(() => {});
				})
				.catch(() => {});
		},
		handleChange(data, checked, node) { 
			this.loadStatus = true;
		},
		handleShare(e) {			
			this.$alert('<div style="padding:5px 10px;background:#f5f0f0;border-radius: 3px;display: flex;justify-content: space-between;line-height: 32px;"><span style="color:#554747">'+ e.path +'</span></div>', '文件分享', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '复制链接',
				beforeClose: (action, instance, done) => {
					if (action === 'confirm') {
						instance.confirmButtonLoading = true;
						instance.confirmButtonText = '执行中...';
						setTimeout(() => {
							done();
							setTimeout(() => {
							instance.confirmButtonLoading = false;
							}, 300);
						}, 3000);
					} else {
						done();
					}
				}
			});
		},
	},
};
</script>