<template>
	<div class="layout-scrollbar">
		<div class="layout-view-bg-white flex layout-view-link">
			<a :href="is_link" target="_blank" class="flex-margin">{{ title }}：{{ is_link }}</a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'layoutLinkView',
	props: {
		meta: {
			type: Object,
			default: () => {},
		},
	},
};
</script>
