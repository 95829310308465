import { render, staticRenderFns } from "./fixPassWord.vue?vue&type=template&id=466f0464&scoped=true&"
import script from "./fixPassWord.vue?vue&type=script&lang=js&"
export * from "./fixPassWord.vue?vue&type=script&lang=js&"
import style0 from "./fixPassWord.vue?vue&type=style&index=0&id=466f0464&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "466f0464",
  null
  
)

export default component.exports