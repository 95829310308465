// 首页模拟数据
export const recommendList = [{
        title: '优惠券',
        msg: '现金券、折扣券、营销必备',
        icon: 'el-icon-food',
        bg: '#48D18D',
        iconColor: '#64d89d',
    },
    {
        title: '多人拼团',
        msg: '社交电商、开辟流量',
        icon: 'el-icon-shopping-bag-1',
        bg: '#F95959',
        iconColor: '#F86C6B',
    },
    {
        title: '分销中心',
        msg: '轻松招募分销员，成功推广奖励',
        icon: 'el-icon-school',
        bg: '#8595F4',
        iconColor: '#92A1F4',
    },
    {
        title: '秒杀',
        msg: '超低价抢购引导更多销量',
        icon: 'el-icon-alarm-clock',
        bg: '#FEBB50',
        iconColor: '#FDC566',
    },
];

export const chartsRightList = [
];

export const newsInfoList = [{
        title: '[发布] 一瞬盲盒正式上线',
        date: '02/28',
        link: 'https://gitee.com/lyt-top/vue-next-admin',
    },

];

export const dailyMessage = [
    // '我解的开薛定谔方程，也解不开你中的毒。',
    // '梦想有多大，摔的就有多惨',
    '世间美好，与你环环相扣',
    // '永远相信美好的事情即将发生，反正以前没发生。',
    // '每一天，遇见更好的自己，比如少摆烂一分钟',
    // '你让我保持热爱，可我只觉得热，没觉得爱',
    // '我叫小强，从小要强',
    // '谁说眼皮合上看不到东西，我明明看见了黑',
    // '没有奋斗，就没有疲惫',
    // '万有引力，我有魅力',
    // '没有谁能击垮你，他们也没看着你。',
    // '微笑着的人并非没有痛苦，也可能是嘴疼。',
    // '你有多辛苦，我就有多赚钱。',
    // '人有多大胆，地有多大产。',
];