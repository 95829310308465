import request from '@/utils/request';

// 用户列表
export function tenant_list(params) {
    return request({
        url: '/tenant/lists',
        method: 'get',
        params: params,
    });
}

export function tenant_index(params) {
    return request({
        url: '/tenant',
        method: 'get',
        params: params,
    });
}

export function tenant_get(id, params) {
    return request({
        url: '/tenant/' + id,
        method: 'get',
        params: params,
    });
}

export function tenant_post(params) {
    return request({
        url: '/tenant',
        method: 'post',
        data: params,
    });
}

export function tenant_del(id) {
    return request({
        url: '/tenant/' + id,
        method: 'delete',
    });
}