<template>
	<div>
		<div style="padding: 15px">
			<el-tabs v-model="activeName" type="card"  size="mini">
				<el-tab-pane label="基础配置" name="info">
				<el-form :model="category" style="padding:15px;" label-width="80px" v-loading="loading">
					<el-form-item label="名称">
						<el-input v-model="category.name" placeholder="请输入名称" clearable style="width: 380px"></el-input>
					</el-form-item>
					<el-form-item label="上级分类">
						<el-select v-model="category.parent_id" style="width: 280px" placeholder="请选择">
							<el-option v-for="item in category_lists" :key="item.id" :label="item.name" :value="item.id"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="状态"> <el-switch v-model="category.status"></el-switch></el-form-item>
					<el-form-item label="封面">
						<div style="float:left;">
							<div style="margin:6px 6px;float:left;overflow: hidden;width:120px;" v-for="(item, index) in category.thumb" :key="index">
								<div class="material-img" v-on:mouseenter="visibleEven(category.thumb, item)" @mouseleave="visibleEven(category.thumb, item)">
									<img class="images" :src="item.url" />
									<div class="item-selected" v-if="item.selected" @click="removeTap(category.thumb, index)"><i class="el-icon-delete"/></div>
								</div>
							</div>
						</div>
						<div class="material-upload" style="margin:6px 6px;">
							<span @click="uploadTap()">添加图片</span>
						</div>
						<span class="sub-title"><i style="padding-right: 5px;color: red;">*</i>建议尺寸：800*800</span>
					</el-form-item>
				</el-form>
				</el-tab-pane>
				<el-tab-pane label="商品信息" name="spec">
					<el-table v-loading="loading" :data="category.goods" stripe style="width: 100%">
						<el-table-column label="商品名称">
							<template #default="scope">
								<span>{{ scope.row.goods_name }}</span>
							</template>
						</el-table-column>
						<el-table-column label="封面">
							<template #default="scope">
								<el-avatar shape="square" :size="50" :src="scope.row.goods_thumb"></el-avatar>
							</template>
						</el-table-column>
						<el-table-column label="商品价格">
							<template #default="scope">
								<span>{{ scope.row.goods_price }}</span>
							</template>
						</el-table-column>
						<el-table-column label="商品原价">
							<template #default="scope">
								<span>{{ scope.row.line_price }}</span>
							</template>
						</el-table-column>
						<el-table-column label="销量">
							<template #default="scope">
								<span>{{ scope.row.limit_num }}</span>
							</template>
						</el-table-column>
						<el-table-column label="库存">
							<template #default="scope">
								<span>{{ scope.row.stock_num }}</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="65"  fixed="right">
							<template #default="scope">
								<el-button size="mini" type="text" @click="removeTap(scope.row)">移除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
			</el-tabs>
		</div>
		<div class="bottom-fixed">
			<div class="box-item">
				<el-button size="medium" @click="closeEvent()">取 消</el-button>
				<el-button size="medium" type="primary" @click="submitTap()">立即提交</el-button>
			</div>
		</div>
		
		<material v-if="is_material" :selected="selected" :type="material_type" :limit="limit" @materialTap="materialTap" @closeTap="showMaterialTap"></material>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { Session } from '@/utils/storage';
import material from '@/components/dialog/material.vue';
import { category_post, category_get, category_list } from '@/api/goods';
export default {
	name: 'category_detail',
	components: {
		material
	},
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: ()=>({}),
		},
	},
	data() {
		return {
			is_material: false,
			upload_url: process.env.VUE_APP_BASE_API + '/base/upload',
			headers: {
				"Authorization":  `Bearer ${Session.get('token')}`
			},
			category: {
				thumb:[]
			},
			selected:[],
			material_type:'image',
			limit:1,
			roles: [],
			loading: true,
			maxLimit: 1,
			activeName: 'info',
			category_lists: []
		};
	},
	created() {
		this.initData();
	},
	mounted() {
		if (Object.keys(this.data).length > 0) {
			category_get(this.data.id)
				.then((res) => {
					this.category = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
		}else{
			this.category = {};
		}
	},
	methods: {
		initData() {
			this.loading = false;
			category_list()
				.then((res) => {
					this.category_lists = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		beforeUpload(res) {
			if (res.data.statusCode === 200) {
				Message.error(res.message);
				return false;
			}
			this.category.thumb = [{name: res.data.file_name, url: res.data.file_url}];
		},
		imageRemove() {
			this.category.thumb = [];
        },
		closeEvent() {
			this.$emit('closeTap');
		},
		showMaterialTap(){
			this.is_material = !this.is_material;
		},
		removeTap(data, index){
			data.splice(index, 1);
		},
		visibleEven(data, row){
			let index = data.findIndex((item) => item.name === row.name);
			if (index !== -1) {
				data[index]['selected'] = !data[index]['selected'];
			};
			this.$set(data, index, data[index]);
		},
		materialTap(data) {
			this.category.thumb = data;
			this.is_material = false;
		},
		submitTap() {
			category_post(this.category)
				.then((res) => {
					if (res.statusCode === 200) {
						Message.success(res.message);
						this.$emit('closeTap');
					}
				});
		},
	},
};
</script>

<style scoped lang="scss">
.tb-edit .el-input,
.tb-edit .el-select {
	display: none;
}
.tb-edit .current-row .el-input,
.tb-edit .current-row .el-select {
	display: block;
}
.tb-edit .current-row .el-input + span,
.tb-edit .current-row .el-select + span {
	display: none;
}
.el-radio__input {
	display: none !important;
}
</style>
