<template>
	<div class="system-menu-container">
		<div :class="visible || group_visible ? 'box-modal-hide' : 'box-modal-show'" style="padding: 15px">
			<el-card shadow="never">
				<div style="padding-bottom: 15px">
					<el-row>
						<el-button type="primary" size="small" icon="el-icon-edit" @click="openGroup()">添加</el-button>
						<!-- <el-button type="primary" size="small" icon="el-icon-edit" @click="initData()">刷新</el-button> -->
					</el-row>
				</div>
				<el-table v-loading="loading" :data="tableData" stripe style="width: 100%">
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column label="名称">
						<template #default="scope">
							<span>{{ scope.row.name }}</span>
						</template>
					</el-table-column>
					<el-table-column label="摘要" sortable>
						<template #default="scope">
							<span>{{ scope.row.detail }}</span>
						</template>
					</el-table-column>
					<el-table-column label="描述" sortable>
						<template #default="scope">
							<span>{{ scope.row.remark }}</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="125">
						<template #default="scope">
							<el-button size="mini" type="text" @click="openGroup(scope.row)">修改</el-button>
							<el-button size="mini" type="text" @click="openVisible(scope.row)">概率</el-button>
							<el-button size="mini" class="color-danger" type="text" @click="deleteTap(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="block" style="margin-top: 15px">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="pageInfo.now"
						:page-sizes="[10, 20, 50, 100, 200]"
						:page-size="pageInfo.size"
						layout="total, sizes, prev, pager, next, jumper"
						:total="pageInfo.count"
					>
					</el-pagination>
				</div>
			</el-card>
		</div>

		<detail v-if="visible" :data="rowData" @closeTap="handleClose"></detail>
		<group v-if="group_visible" :data="rowData" @closeTap="handleGroupClose"></group>
	</div>
</template>

<script>
import detail from './component/detail.vue';
import group from './component/group.vue';
import { box_deal_group_list, box_deal_group_del } from '@/api/blind_box';
export default {
	name: 'box_deal_group',
	components: { detail, group },
	data() {
		return {
			tableData: [],
			box_deal: {},
			rowData: {},
			visible: false,
			group_visible: false,
			loading: true,
			pageInfo: {
				now: 1,
				size: 20,
			},
		};
	},
	created() {
		this.initData();
	},
	methods: {
		initData() {
			this.loading = true;
			var param = {
				pageSize: this.pageInfo.size || undefined,
				pageNow: this.pageInfo.now || undefined,
			};
			box_deal_group_list({ ...param })
				.then((res) => {
					if (res.statusCode !== 200) {
						this.$message({
							type: 'error',
							message: res.message,
						});
						return true;
					}
					this.pageInfo = res.page;
					this.tableData = res.lists;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		handleClose() {
			this.visible = false;
			this.initData();
		},
		handleGroupClose() {
			this.group_visible = false;
			this.initData();
		},
		openVisible(data = null) {
			if (data !== null) {
				this.rowData = data;
			} else {
				this.rowData = null;
			}
			this.visible = true;
		},
		openGroup(data = null) {
			if (data !== null) {
				this.rowData = data;
			} else {
				this.rowData = null;
			}
			this.group_visible = true;
		},
		handleSizeChange(val) {
			this.pageInfo.size = val;
			this.initData();
		},
		handleCurrentChange(val) {
			this.pageInfo.now = val;
			this.initData();
		},
		deleteTap(e) {
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				this.loading = true;
				box_deal_group_del(e.id)
					.then((res) => {
						if (res.statusCode == 200) {
							this.$message({
								type: 'success',
								message: res.message,
							});
							this.initData();
						} else {
							this.$message({
								type: 'error',
								message: res.message,
							});
						}
					})
					.finally(() => {
						this.loading = false;
					});
			});
		},
	},
};
</script>
