<template>
	<div>
		<div style="margin: 12px 12px 58px 12px">
			<el-tabs v-model="activeName" type="card" size="mini" @tab-click="handleClick">
				<el-tab-pane label="商品信息" name="info" v-loading="loading">
					<div style="padding: 15px">
						<el-form :model="goods" :rules="rules" ref="goodsForm" label-width="90px">
							<el-row :gutter="20">
								<el-col :span="24">
									<el-form-item label="商品名称" prop="goods_name">
										<el-input
											v-model="goods.goods_name"
											placeholder="请输入商品名称"
											clearable
											maxlength="35"
											show-word-limit
											style="width: 380px"
										></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item label="标记" prop="single_tag">
										<el-input
											v-model="goods.single_tag"
											placeholder="请输入商品标记"
											clearable
											maxlength="35"
											show-word-limit
											style="width: 380px"
										></el-input>
									</el-form-item>
								</el-col>
								<!-- <el-col :span="24">
									<el-form-item label="短标题">
										<el-input
											v-model="goods.title"
											placeholder="请输入短标题"
											clearable
											maxlength="25"
											show-word-limit
											style="width: 380px"
										></el-input>
									</el-form-item>
								</el-col> -->

								<el-col :span="24">
									<el-form-item label="货号编号" prop="goods_no">
										<el-input
											v-model="goods.goods_no"
											placeholder="请输入货号编号"
											clearable
											maxlength="25"
											show-word-limit
											style="width: 380px"
										></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item label="库存" prop="stock_num">
										<el-input v-model="goods.stock_num" style="width: 380px"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item label="排序">
										<el-input v-model="goods.sort" controls-position="right" style="width: 380px"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item label="状态">
										<el-radio-group v-model="goods.status" size="mini">
											<el-radio border :label="1">上架</el-radio>
											<el-radio border :label="0">下架</el-radio>
										</el-radio-group>
									</el-form-item>
								</el-col>
								<el-col :span="24">
									<el-form-item label="仅限盲盒">
										<el-radio-group v-model="goods.only_blind" size="mini">
											<el-radio border :label="1">是</el-radio>
											<el-radio border :label="0">否</el-radio>
										</el-radio-group>
									</el-form-item>
								</el-col>

								<!-- <el-col :span="24">
									<el-form-item label="限购数量">
										<el-input v-model="goods.limit_num" style="width: 380px"></el-input>
									</el-form-item>
								</el-col> -->

								<!-- <el-col :span="24">
									<el-form-item label="会员成长值">
										<el-input v-model="goods.gift_growth" style="width: 380px"></el-input>
									</el-form-item>
								</el-col> -->

								<el-col :span="24">
									<el-form-item label="积分商品">
										<el-radio-group v-model="goods.point_reward" size="mini">
											<el-radio border :label="1">是</el-radio>
											<el-radio border :label="0">否</el-radio>
										</el-radio-group>
									</el-form-item>
								</el-col>

								<el-col :span="24" v-if="goods.point_reward == 0">
									<el-form-item label="赠送积分值">
										<el-input v-model="goods.gift_point" style="width: 380px"></el-input>
									</el-form-item>
								</el-col>

								<el-col :span="24" v-if="goods.point_reward == 1">
									<el-form-item label="积分价格">
										<el-input v-model="goods.use_point_limit" style="width: 380px"></el-input>
									</el-form-item>
								</el-col>

								<!-- <el-col :span="24">
									<el-form-item label="可抵扣积分">
										<el-input v-model="goods.use_point_limit" style="width: 380px"></el-input>
									</el-form-item>
								</el-col> -->

								<el-col :span="24">
									<el-form-item label="商品价格" prop="goods_price">
										<el-input v-model="goods.goods_price" placeholder="请输入商品价格" clearable style="width: 380px"></el-input>
									</el-form-item>
								</el-col>

								<el-col :span="24">
									<el-form-item label="划线价" prop="line_price">
										<el-input v-model="goods.line_price" placeholder="请输入商品划线价" clearable style="width: 380px"></el-input>
										<br />
										<span class="sub-title">商品划线价必须大于商品价格</span>
									</el-form-item>
								</el-col>

								<el-col :span="24">
									<el-form-item label="运费模板" prop="freight_id">
										<el-select v-model="goods.freight_id" style="width: 380px" placeholder="请选择">
											<el-option v-for="item in freight" :key="item.id" :label="item.name" :value="item.id"> </el-option>
										</el-select>
									</el-form-item>
								</el-col>

								<el-col :span="24">
									<el-form-item label="服务">
										<el-checkbox-group v-model="goods.service_ids">
											<el-checkbox :label="item.name" name="service_ids" v-for="item in service_ids" :key="item.id"></el-checkbox>
										</el-checkbox-group>
									</el-form-item>
								</el-col>

								<!--							<el-col :span="24">
									<el-form-item label="促销时间">
										<el-input v-model="goods.use_point_limit" controls-position="right"></el-input>
									</el-form-item>
								</el-col>

								<el-col :span="24">
									<el-form-item label="活动限购数">
										<el-input v-model="goods.use_point_limit" controls-position="right"></el-input>
									</el-form-item>
								</el-col> -->
								<!-- <el-col :span="24">
									<el-form-item label="空白主图" prop="goods_cover">
										<div style="float: left">
											<div
												style="margin: 6px 6px; float: left; overflow: hidden; width: 120px"
												v-for="(item, index) in goods.goods_cover"
												:key="index"
											>
												<div
													class="material-img"
													v-on:mouseenter="visibleEven(goods.goods_cover, item)"
													@mouseleave="visibleEven(goods.goods_cover, item)"
												>
													<img class="images" :src="item.url" />
													<div class="item-selected" v-if="item.selected" @click="removeTap(goods.goods_cover, index)">
														<i class="el-icon-delete" />
													</div>
												</div>
											</div>
										</div>

										<div class="material-upload" style="margin: 6px 6px">
											<span @click="uploadTap(1, 'image')">添加图片</span>
										</div>
										<span class="sub-title">活动主题专用的空白主图</span>
									</el-form-item>
								</el-col> -->

								<el-col :span="24">
									<el-form-item label="视频">
										<div style="float: left">
											<div
												style="margin: 6px 6px; float: left; overflow: hidden; width: 120px"
												v-for="(item, index) in goods.goods_video"
												:key="index"
											>
												<div
													class="material-img"
													v-on:mouseenter="visibleEven(goods.goods_video, item)"
													@mouseleave="visibleEven(goods.goods_video, item)"
												>
													<video width="100%" :src="item.url" />
													<div class="item-selected" v-if="item.selected" @click="removeTap(goods.goods_video, index)">
														<i class="el-icon-delete" />
													</div>
												</div>
											</div>
										</div>
										<div class="material-upload" style="margin: 6px 6px">
											<span @click="uploadTap(2, 'video')">添加视频</span>
										</div>
										<span class="sub-title">手机端播放，建议时长：9-30秒，视频宽高比16:9</span>
									</el-form-item>
								</el-col>

								<el-col :span="24">
									<el-form-item label="主图">
										<div style="float: left">
											<div
												style="margin: 6px 6px; float: left; overflow: hidden; width: 120px"
												v-for="(item, index) in goods.goods_thumb"
												:key="index"
											>
												<div
													class="material-img"
													v-on:mouseenter="visibleEven(goods.goods_thumb, item)"
													@mouseleave="visibleEven(goods.goods_thumb, item)"
												>
													<img class="images" :src="item.url" />
													<div class="item-selected" v-if="item.selected" @click="removeTap(goods.goods_thumb, index)">
														<i class="el-icon-delete" />
													</div>
												</div>
											</div>
										</div>
										<div class="material-upload" style="margin: 6px 6px">
											<span @click="uploadTap(3, 'image')">添加图片</span>
										</div>
										<span class="sub-title">建议尺寸：800*800，可拖拽改变图片顺序，默认首张图为主图，最多上传10张</span>
									</el-form-item>
								</el-col>

								<el-col :span="24">
									<el-form-item label="商品详情" prop="md_content">
										<mavon-editor
											ref="mavonEditor"
											v-model="goods.md_content"
											:toolbars="markdown.toolbars"
											:subfield="false"
											:ishljs="true"
											:boxShadow="true"
											:externalLink="true"
											fontSize="14px"
											previewBackground="#fff"
											defaultOpen="edit"
											placeholder="请编辑你的商品详情 ..."
											class="editor full-height"
											@save="editorSave"
											@change="editorChange"
											@imgAdd="editorUploadImage"
										>
											<!-- 左工具栏后加入自定义按钮  -->
											<template slot="left-toolbar-after">
												<button
													type="button"
													@click="$refs.uploads.click()"
													class="op-icon fa fa-cloud-upload"
													aria-hidden="true"
													title="批量上传"
												></button>
												<input ref="uploads" multiple type="file" @change="upload" style="display: none" />
											</template>
										</mavon-editor>
									</el-form-item>
								</el-col>
							</el-row>
						</el-form>
						<!-- <el-divider content-position="left">Goods 数据</el-divider>
						<pre><code>{{ goods }}</code></pre> -->
					</div>
				</el-tab-pane>
				<el-tab-pane label="商品规格" name="spec" v-loading="loading">
					<el-input
						v-model="new_attribute"
						placeholder="请输入规格组名称(例如:颜色、款式)"
						size="medium"
						@keyup.enter.native="createSkuTap()"
						style="width: 280px"
					></el-input
					><el-button type="primary" style="margin-left: 12px" size="medium" @click="createSkuTap()">添加规格组</el-button>
					<SkuForm
						ref="skuForm"
						:source-attribute.sync="sourceAttribute"
						:structure="structure"
						:attribute.sync="goods.attribute"
						:sku.sync="goods.sku"
						v-if="sourceAttribute.length > 0"
					>
						<template #image="slotProps">
							<div class="image-upload-container">
								<el-image
									v-if="slotProps.row.image"
									:src="slotProps.row.image"
									:preview-src-list="[slotProps.row.image]"
									fit="cover"
									title="点击预览"
								/>
								<el-upload
									:show-file-list="false"
									:action="upload_url"
									:headers="headers"
									:before-upload="beforeUpload"
									:on-success="(res) => imageUpload(res, slotProps)"
									class="images-upload"
								>
									<el-button size="small" icon="el-icon-upload2">{{ slotProps.row.image ? '重新上传' : '上传图片' }}</el-button>
								</el-upload>
							</div>
						</template>
					</SkuForm>
					<el-row type="flex" :gutter="20">
						<el-col>
							<el-divider content-position="left">attribute 数据</el-divider>
							<pre><code>{{ goods.attribute }}</code></pre>
						</el-col>
						<el-col>
							<el-divider content-position="left">sku 数据</el-divider>
							<pre><code>{{ goods.sku }}</code></pre>
						</el-col>
					</el-row>
				</el-tab-pane>
			</el-tabs>
		</div>

		<div class="bottom-fixed" style="z-index: 2000">
			<div class="box-item">
				<el-button size="medium" @click="closeTap()">取 消</el-button>
				<el-button size="medium" type="primary" @click="submitTap()">立即提交</el-button>
			</div>
		</div>

		<material
			v-if="is_material"
			:selected="selected"
			:type="material_type"
			:limit="limit"
			@materialTap="materialTap"
			@closeTap="materialCloseTap"
		></material>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { goods_get, goods_post, freight_list } from '@/api/goods';
import { file_upload } from '@/api/common';
import { material_post } from '@/api/material';
import { mavonEditor } from 'mavon-editor';
import material from '@/components/dialog/material.vue';
import 'mavon-editor/dist/css/index.css';
import { Session } from '@/utils/storage';

export default {
	name: 'detail',
	components: {
		mavonEditor,
		material,
	},
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			current_type: 1,
			is_material: false,
			selected: [],
			material_type: 'image',
			limit: 1,
			dir_id: 0,
			upload_url: process.env.VUE_APP_BASE_API + '/base/upload',
			headers: {
				Authorization: `Bearer ${Session.get('token')}`,
			},
			loading: true,
			couponData: [],
			maxLimit: 1,
			// 编辑器相关信息
			markdown: {
				toolbars: {
					bold: true, // 粗体
					italic: true, // 斜体
					header: true, // 标题
					underline: true, // 下划线
					strikethrough: true, // 中划线
					mark: true, // 标记
					ol: true, // 有序列表
					ul: true, // 无序列表
					link: true, // 链接
					imagelink: true, // 图片链接
					code: true, // code
					table: true, // 表格
					alignleft: true, // 左对齐
					aligncenter: true, // 居中
					alignright: true, // 右对齐
					subfield: true,
					htmlcode: true,
				},
			},
			activeName: 'info',
			new_attribute: '',
			select: false,
			goods: {
				service_ids: [],
				sku: [],
				attribute: [],
				goods_thumb: [],
				goods_video: [],
				point_reward: 0,
				status: 1,
				only_blind: 1,
			},
			rules: {
				goods_name: [{ required: true, message: '请输入商品名称', trigger: 'change' }],
				goods_no: [{ required: true, message: '请填写货号', trigger: 'change' }],
				goods_price: [{ required: true, message: '请填写商品价格', trigger: 'change' }],
				line_price: [{ required: true, message: '请填写划线价格', trigger: 'change' }],
				freight_id: [{ required: true, message: '请选择运费模板', trigger: 'change' }],
				stock_num: [{ required: true, message: '请填写库存', trigger: 'change' }],
				md_content: [{ required: true, message: '请填写商品详情', trigger: 'change' }],
				// goods_cover: [{ required: true, message: '请选择主图上传', trigger: 'change' }],
			},
			dialogImageUrl: '',
			freight: [],
			dialogVisible: false,
			sourceAttribute: [],
			service_ids: [
				{
					id: 1,
					name: '无忧退货',
				},
				{
					id: 2,
					name: '快速退款',
				},
				{
					id: 3,
					name: '免费包邮',
				},
			],
			structure: [
				// {
				// 	name: 'goods_no',
				//     type: 'input',
				// 	label: '商品编码',
				// 	tip: '商品编码唯一不可重复',
				// 	required: true,
				// 	validate: (data, index, callback) => {
				// 		setTimeout(() => {
				// 			data.forEach((item) => {
				// 				if (data[index].code == item.code) {
				// 					callback(new Error('商品唯一编码已存在'))
				// 				}
				// 			});
				// 			callback()
				// 		}, 1000)
				// 	}
				// },
				{
					name: 'goods_price',
					type: 'input',
					label: '价格',
					required: true,
				},
				{
					name: 'line_price',
					type: 'input',
					label: '划线价',
					required: true,
				},
				{
					name: 'stock_num',
					type: 'input',
					label: '库存',
					required: true,
				},
				// {
				//     name: 'goods_sales',
				//     type: 'input',
				//     label: '商品销量'
				// },
				// {
				// 	name: 'goods_weight',
				// 	type: 'input',
				// 	label: '商品重量(Kg)',
				// },
				{
					name: 'image',
					type: 'slot',
					label: '图片',
					required: true,
				},
			],
		};
	},
	created() {
		this.initData();
	},
	mounted() {
		if (Object.keys(this.data).length > 0) {
			this.loading = true;
			goods_get(this.data.goods_id)
				.then((res) => {
					this.goods = res.data;
					this.sourceAttribute = this.goods.attribute;
					// 切记，必须在 attribute、sku 数据都加载后再执行 init() 方法、
					if (this.$refs.skuForm) {
						this.$refs.skuForm.init();
					}
				})
				.finally(() => {
					this.loading = false;
				});
		}
	},
	methods: {
		initData() {
			freight_list()
				.then((res) => {
					this.freight = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		handleClick() {
			// this.initData();
		},
		createSkuTap() {
			this.sourceAttribute.push({
				name: this.new_attribute,
				item: [],
			});
			// 切记，必须在 attribute、sku 数据都加载后再执行 init() 方法
			if (this.$refs.skuForm) {
				this.$refs.skuForm.init();
			}

			this.new_attribute = '';
		},
		// 编辑器保存回调事件
		editorSave() {
			this.triggerSave();
		},
		removeTap(data, index) {
			data.splice(index, 1);
		},
		visibleEven(data, row) {
			let index = data.findIndex((item) => item.name === row.name);
			if (index !== -1) {
				data[index]['selected'] = !data[index]['selected'];
			}
			this.$set(data, index, data[index]);
		},
		// 编辑器修改回调事件
		editorChange(value, render) {
			this.goods.content = render;
			this.goods.md_content = value;
		},
		uploadTap(current_type, type) {
			this.is_material = true;
			this.material_type = type;
			this.current_type = current_type;
		},
		upload(e) {
			if (e.target.files.length == 0) {
				return false;
			}
			let files = e.target.files;
			let formData = new FormData();
			files.forEach((file) => {
				formData.append('file[]', file);
			});
			formData.append('dir_id', this.dir_id);
			material_post(formData)
				.then((res) => {
					if (res.statusCode != 200) return false;
					res.data.forEach((file, index) => {
						this.$refs.mavonEditor.$img2Url(index + 1, file.path);
					});
				})
				.catch(() => {});
		},
		materialCloseTap() {
			this.is_material = false;
		},
		materialTap(data) {
			if (this.current_type === 1) {
				this.goods.goods_cover = data;
			}

			if (this.current_type === 2) {
				this.goods.goods_video = data;
			}

			if (this.current_type === 3) {
				this.goods.goods_thumb = data;
			}
			this.is_material = false;
		},
		// 编辑器上传图片回调事件
		editorUploadImage(pos, file) {
			let formdata = new FormData();
			formdata.append('file', file);
			let save_path = '';
			file_upload(formdata)
				.then((res) => {
					if (res.statusCode != 200) return false;
					this.$refs.mavonEditor.$img2Url(pos, res.data.file_url);
					save_path = res.data.file_url || '';
				})
				.catch(() => {
					this.$refs.mavonEditor.$img2Url(pos, save_path);
				});
		},
		removeThumbEven(file, fileList) {
			this.goods.goods_thumb = [];
			var thumbs = [];
			fileList.forEach(function (item) {
				thumbs.push({ name: item.name, url: item.url });
			});
			this.goods.goods_thumb = thumbs;
		},
		beforeUploadThumb(res) {
			var obj = {
				url: res.data.file_url,
				name: res.data.file_name,
			};
			this.goods.goods_thumb.push(JSON.parse(JSON.stringify(obj)));
		},
		removeCoverEven(file, fileList) {
			this.goods.cover = [];
			var covers = [];
			fileList.forEach(function (item) {
				covers.push({ name: item.name, url: item.url });
			});
			this.goods.cover = covers;
		},
		beforeUploadCover(res) {
			var obj = {
				url: res.data.file_url,
				name: res.data.file_name,
			};
			this.goods.cover.push(JSON.parse(JSON.stringify(obj)));
		},
		beforeUpload(file) {
			const ext = ['jpg', 'png', 'gif', 'bmp'];
			const size = 5;
			const fileName = file.name.split('.');
			const fileExt = fileName[fileName.length - 1];
			const isTypeOk = ext.indexOf(fileExt) >= 0;
			const isSizeOk = file.size / 1024 / 1024 < size;
			if (!isTypeOk) {
				this.$message.error(`上传图片只支持 ${ext.join(' / ')} 格式！`);
			}
			if (!isSizeOk) {
				this.$message.error(`上传图片大小不能超过 ${size}MB！`);
			}
			return isTypeOk && isSizeOk;
		},
		// 图片上传
		imageUpload(res, data) {
			data.row.image = res.data.file_url;
			this.$message.success('图片上传成功');
			this.$refs.skuForm.validateFieldByRows(data.$index, 'image', () => {});
		},
		imageRemove(data) {
			data.row.image = '';
		},
		closeTap() {
			this.$emit('closeTap');
		},
		submitTap() {
			this.$refs['goodsForm'].validate((valid, messages) => {
				if (valid) {
					goods_post(this.goods)
						.then((res) => {
							if (res.statusCode == 200) {
								Message.success(res.message);
								this.$emit('closeTap');
							} else {
								Message.error(res.message);
							}
						})
						.catch(() => {});
				} else {
					const [message] = Object.values(messages);
					const [first] = message;
					Message.error(first.message);
					return false;
				}
			});
		},
	},
};
</script>

<style lang="less" scoped>
/deep/ .el-upload-dragger {
	width: initial;
	height: initial;
	border: 0;
	border-radius: 0;
	background-color: initial;
	overflow: auto;
}
/deep/ .avatar {
	position: absolute;
	width: 148px;
	top: 35px;
	left: 0;
}
/deep/ .image-upload-container {
	.el-image {
		vertical-align: middle;
		margin: 0 5px;
		width: 30px;
		height: 30px;
	}
	.images-upload,
	> .el-button {
		display: inline-block;
		margin: 0 5px;
		vertical-align: middle;
	}
}
</style>
