<template>
	<div class="system-menu-container">
		<div :class="visible ? 'box-modal-hide' : 'box-modal-show'"  style="padding:15px;">
			<el-form :model="staffer" size="small" label-width="100px" class="mt15 mb15">
				<el-row>
					<el-col :span="6">
						<el-form-item label="姓名">
							<el-input v-model="staffer.name" placeholder="请输入姓名" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="邮箱">
							<el-input v-model="staffer.email" placeholder="请输入邮箱" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="手机">
							<el-input v-model="staffer.mobile" placeholder="请输入手机" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="状态">
							<el-select v-model="staffer.on_duty" placeholder="请选择状态" clearable class="w100">
								<el-option label="正常" value="1"></el-option>
								<el-option label="关闭" value="2"></el-option>
							</el-select>
						</el-form-item>
					</el-col>

					<el-col :span="24">
						<el-form-item align="right">
							<el-button icon="el-icon-refresh">清空</el-button>
							<el-button type="primary" icon="el-icon-search" @click="submitTap()">立即查询</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<el-card shadow="never">
				<div style="padding-bottom: 15px">
					<el-row>
						<el-button type="danger" size="small" icon="el-icon-delete" @click="batchDelTap()">批量删除</el-button>
						<el-button size="small" type="primary" icon="el-icon-edit" @click="openVisible()">添加账号</el-button>
					</el-row>
				</div>
				<el-table v-loading="loading" :data="tableData" stripe style="width: 100%">
					<el-table-column fixed type="selection" width="55"></el-table-column>
					<el-table-column fixed label="账号名称">
						<template #default="scope">
							<span>{{ scope.row.name }}</span>
						</template>
					</el-table-column>
					<el-table-column label="头像">
						<template #default="scope">
							<el-avatar shape="square" :size="50" :src="scope.row.avatar_url"></el-avatar>
						</template>
					</el-table-column>
					<el-table-column label="租户">
						<template #default="scope">
							<span class="color-info">{{scope.row.tenant.name}}</span>
						</template>
					</el-table-column>
					<el-table-column label="角色">
						<template #default="scope">
							<span>{{ scope.row.role_name }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="mobile" label="联系方式"></el-table-column>
					<el-table-column prop="email" label="邮箱地址"></el-table-column>
					<el-table-column label="状态">
						<template #default="scope">
							<span v-if="scope.row.status == 1" class="color-primary">正常</span>
							<span v-else class="color-info">关闭</span>
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="操作" width="105">
						<template #default="scope">
							<el-button size="mini" type="text" @click="openVisible(scope.row)">编辑</el-button>
							<el-button size="mini" class="color-danger" type="text" @click="deleteTap(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="block" style="margin-top: 15px">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="pageInfo.now"
						:page-sizes="[10, 20, 50, 100, 200]"
						:page-size="pageInfo.size"
						layout="total, sizes, prev, pager, next, jumper"
						:total="pageInfo.count"
					>
					</el-pagination>
				</div>
			</el-card>
		</div>
		<detail v-if="visible" :type="type" :data="rowData" @closeTap="handleCloseTap"></detail>
	</div>
</template>

<script>
import { staffer_index, staffer_del } from '@/api/staffer';
import detail from './component/detail.vue';
export default {
	components: { detail },
	name: 'staffer',
	data() {
		return {
			staffer: {},
			tableData: [],
			pageInfo: {
				now: 1,
				size: 20
			},
			type: 1,
			rowData: {},
			rowId: 0,
			loading: true,
			visible: false,
			jobsList: [],
		};
	},
	created() {
		this.initData();
	},
	watch: {
		$route(to) {
			if (to.query.stafferId !== undefined) {
				this.checkDetail(to.query.stafferId);
			}
		},
	},
	methods: {
		initData() {
			var param = {
				pageSize: this.pageInfo.size || undefined,
				pageNow: this.pageInfo.now || undefined,
			};
			staffer_index({ ...param })
				.then((res) => {
					if (res.statusCode !== 200) {
						this.$message({
							type: 'error',
							message: res.message,
						});
						return true;
					}
					this.pageInfo = res.page;
					this.tableData = res.lists;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		checkDetail(id) {
			this.rowId = parseInt(id);
			this.visible = true;
		},
		openVisible(data = null) {
			if (data !== null) {
				this.type = 1;
				this.rowData = data;
			} else {
				this.rowData = {};
			}
			this.visible = true;
		},
		deleteTap(data) {
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.loading = true;
				staffer_del(data.id).then((res) => {
					if (res.statusCode === 200) {
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
						this.loading = false;
						this.initData();
					}
				}).catch(() => {});
			});
		},
		batchDelTap() {
			this.loading = true;
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.loading = false;
			});
		},
		handleCheckTap(data) {
			this.stafferData = data;
			this.visible = true;
		},
		handleEditTap() {
			this.checkShow = true;
		},
		submitTap(){
			this.loading = true;
			this.initData();
		},
		handleCloseTap() {
			this.visible = false;
			this.loading = true;
			this.initData();
		},
		handleSizeChange(val) {
			this.loading = true;
			this.pageInfo.size = val;
			this.initData();
		},
		handleCurrentChange(val) {
			this.loading = true;
			this.pageInfo.now = val;
			this.initData();
		},
	},
};
</script>
