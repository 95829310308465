var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"15px"}},[_c('el-container',{staticClass:"note-container",attrs:{"slot":"container"},slot:"container"},[_c('el-aside',{staticClass:"el-aside-one lum-scrollbar",attrs:{"width":"230px"}},[_c('el-header',{staticClass:"btn-header"},[_c('el-dropdown',{staticClass:"btn-dropdown-menu",attrs:{"split-button":"","type":"primary"},on:{"click":_vm.insertNote,"command":_vm.handleCommand}},[_c('div',[_vm._v("+ 添加文档")]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"a"}},[_vm._v("新建文档")]),_c('el-dropdown-item',{attrs:{"command":"b"}},[_vm._v("新建分类")])],1)],1)],1),_c('div',{staticClass:"note-headline"},[_vm._v("我的文档")]),_c('el-scrollbar',{staticClass:"note-aside",attrs:{"native":false,"tag":"section"}},_vm._l((_vm.menus),function(menu,i){return _c('div',{key:i},[_c('div',{staticClass:"note-list-first",class:{ 'note-list-active': menu.isActive },on:{"click":function($event){return _vm.clickNoteMenu(1, i)},"contextmenu":function($event){$event.preventDefault();return _vm.noteClassMenu($event, i)}}},[_c('i',{class:menu.icon,style:({ color: menu.color })}),_c('span',[_vm._v(_vm._s(menu.name))]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(menu.submenus.length),expression:"menu.submenus.length"}],staticClass:"icon-menu-nav",class:{
								'el-icon-arrow-down': menu.isShowSub,
								'el-icon-arrow-up': !menu.isShowSub,
							},staticStyle:{"float":"right","padding":"14px 5px"}})]),_vm._l((menu.submenus),function(submenu,i2){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(menu.isShowSub),expression:"menu.isShowSub"}],key:i2,staticClass:"note-list-two",class:{ 'note-list-active': submenu.isActive }},[(submenu.isEdit)?_c('p',[_c('input',{directives:[{name:"focus",rawName:"v-focus"}],attrs:{"type":"text"},domProps:{"value":submenu.name},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.editNoteMenu($event, i, i2)},"blur":function($event){return _vm.editNoteMenu($event, i, i2)}}})]):_c('p',{on:{"click":function($event){return _vm.clickNoteMenu(2, i, i2)},"contextmenu":function($event){$event.preventDefault();return _vm.noteClassMenu($event, i, i2)}}},[(i > 1 && i < 3)?_c('span',[_c('i',{staticClass:"el-icon-folder",staticStyle:{"margin-right":"5px","color":"red"}}),_c('span',[_vm._v(_vm._s(submenu.name))]),(submenu.count)?_c('span',[_vm._v("("+_vm._s(submenu.count)+")")]):_vm._e(),_c('i',{staticClass:"icon-menu-nav",class:{
										'el-icon-arrow-down': submenu.isShowSub,
										'el-icon-arrow-up': !submenu.isShowSub,
									},staticStyle:{"float":"right","padding":"14px 5px"}})]):_c('span',{on:{"click":function($event){return _vm.catNote(submenu)}}},[_c('i',{staticClass:"iconfont icon-dian"}),_c('span',[_vm._v(_vm._s(submenu.name))])])]),_vm._l((submenu.children),function(child,i3){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(submenu.isShowSub),expression:"submenu.isShowSub"}],key:i3,staticClass:"note-list-three",class:{ 'note-list-active': child.isActive },on:{"click":function($event){return _vm.clickNoteMenu(3, i, i2, i3)},"contextmenu":function($event){$event.preventDefault();return _vm.noteClassMenu($event, i, i2, i3)}}},[(child.isEdit)?_c('p',[_c('input',{directives:[{name:"focus",rawName:"v-focus"}],attrs:{"type":"text"},domProps:{"value":child.name},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.editNoteMenu($event, i, i2, i3)},"blur":function($event){return _vm.editNoteMenu($event, i, i2, i3)}}})]):_c('p',{on:{"click":function($event){return _vm.catNote(child)}}},[_c('i',{staticClass:"iconfont icon-dian"}),_c('span',[_vm._v(_vm._s(child.name))])])])})],2)})],2)}),0)],1),_c('preview',{ref:"refNotePreview",on:{"create":(note_id) => {
					this.note_id = note_id;
					this.loadNoteClass();
					this.loadNoteList();
				},"recover":(note_id) => {
					this.loadNoteClass();
					this.removeListNote(note_id);
				},"change":(note) => {
					this.notes.forEach((value, index) => {
						if (value.id == note.id) {
							value.title = note.title;
							value.abstract = note.abstract;
						}
					});
				},"recycle":(note_id) => {
					this.loadNoteClass();
					this.removeListNote(note_id);
				},"del":(note_id) => {
					this.removeListNote(note_id);
				}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }