<template>
	<div>
		<div style="padding: 15px;margin-bottom:25px;">
			<el-form :model="tenant" label-width="80px" v-loading="loading">
				<el-tabs v-model="activeName" type="card"  size="mini">
					<el-tab-pane label="基础配置" name="info">
						<el-form-item label="名称">
							<el-input v-model="tenant.name" placeholder="请输入名称" clearable style="width: 380px"></el-input>
						</el-form-item>
						<el-form-item label="域名前缀">
							<el-input v-model="tenant.domain" :disabled="true" style="width: 380px"></el-input>
						</el-form-item>
						<el-form-item label="版权">
							<el-input v-model="tenant.copyright" placeholder="请输入租户版权" clearable style="width: 380px"></el-input>
						</el-form-item>
						<el-form-item label="有效期">
							<el-date-picker
								v-model="tenant.valid_time"
								type="daterange"
								value-format="yyyy-MM-dd"
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="状态"> <el-switch v-model="tenant.status"></el-switch></el-form-item>
						<el-form-item label="Logo">
							<div style="float:left;">
								<div style="margin:6px 6px;float:left;overflow: hidden;width:120px;" v-for="(item, index) in tenant.logo" :key="index">
									<div class="material-img" v-on:mouseenter="visibleEven(tenant.logo, item)" @mouseleave="visibleEven(tenant.logo, item)">
										<img class="images" :src="item.url" />
										<div class="item-selected" v-if="item.selected" @click="removeTap(tenant.logo, index)"><i class="el-icon-delete"/></div>
									</div>
								</div>
							</div> 
							<div class="material-upload" style="margin:6px 6px;">
								<span @click="showMaterialTap()">添加图片</span>
							</div>
							<span class="sub-title"><i style="padding-right: 5px;color: red;">*</i>建议尺寸：800*800</span>
						</el-form-item>
						<el-form-item label="账号简介">
							<el-input type="textarea" v-model="tenant.remark" clearable :autosize="{ minRows: 4, maxRows: 6 }" maxlength="150" show-word-limit style="width: 530px"></el-input>
						</el-form-item>
					</el-tab-pane>
					<el-tab-pane label="基础信息" name="spec">
						<div class="header">
							<div class="mark-title" style="font-size: 13px; height: 36px; line-height: 36px;">数据库配置</div>
						</div>
						<el-form-item label="Host">
							<el-input v-model="tenant.host" placeholder="请输入Host" clearable style="width: 380px"></el-input>
						</el-form-item>
						<el-form-item label="数据库名">
							<el-input v-model="tenant.database" placeholder="请输入数据库名" clearable style="width: 380px"></el-input>
						</el-form-item>
						<el-form-item label="Port">
							<el-input v-model="tenant.port" placeholder="请输入端口号" clearable style="width: 380px"></el-input>
						</el-form-item>
						<el-form-item label="账号">
							<el-input v-model="tenant.account" placeholder="请输入账号" clearable style="width: 380px"></el-input>
						</el-form-item>
						<el-form-item label="密码">
							<el-input v-model="tenant.password" type="password" placeholder="请输入密码" clearable :show-password="true" style="width: 380px"></el-input>
						</el-form-item>
						<div class="header">
							<div class="mark-title" style="font-size: 13px; height: 36px; line-height: 36px;">微信配置</div>
						</div>
						<el-form-item label="AppId">
							<el-input v-model="tenant.wx_appid" placeholder="请输入AppId" clearable style="width: 380px"></el-input>
						</el-form-item>
						<el-form-item label="Secret">
							<el-input v-model="tenant.wx_secret" type="password"  placeholder="请输入Secret" :show-password="true" clearable style="width: 380px"></el-input>
						</el-form-item>
					</el-tab-pane>
				</el-tabs>
			</el-form>
		</div>
		<div class="bottom-fixed">
			<div class="box-item">
				<el-button size="medium" @click="closeEvent()">取 消</el-button>
				<el-button size="medium" type="primary" @click="submitTap()">立即提交</el-button>
			</div>
		</div>
		
		<material v-if="is_material" :selected="selected" :type="material_type" :limit="limit" @materialTap="materialTap" @closeTap="showMaterialTap"></material>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { Session } from '@/utils/storage';
import material from '@/components/dialog/material.vue';
import { tenant_post, tenant_get } from '@/api/tenant';
export default {
	name: 'tenant_detail',
	components: {
		material
	},
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: ()=>({}),
		},
	},
	data() {
		return {
			is_material: false,
			selected:[],
			material_type:'image',
			limit:1,
			upload_url: process.env.VUE_APP_BASE_API + '/base/upload',
			headers: {
				"Authorization":  `Bearer ${Session.get('token')}`
			},
			tenant: {
				logo:[]
			},
			roles: [],
			loading: true,
			dialogImageUrl: '',
			dialogVisible: false,
			maxLimit: 1,
			treeData: [],
			activeName: 'info'
		};
	},
	created() {
		this.initData();
	},
	mounted() {
		if (Object.keys(this.data).length > 0) {
			tenant_get(this.data.id)
				.then((res) => {
					this.tenant = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
		}else{
			this.tenant = {};
		}
	},
	methods: {
		initData() {
			this.loading = false;
		},
		beforeUpload(res) {
			if (res.data.statusCode === 200) {
				Message.error(res.message);
				return false;
			}
			this.tenant.logo = [{name: res.data.file_name, url: res.data.file_url}];
		},
		imageRemove() {
			this.tenant.logo = [];
        },
		closeEvent() {
			this.$emit('closeTap');
		},
		showMaterialTap(){
			this.is_material = !this.is_material;
		},
		removeTap(data, index){
			data.splice(index, 1);
		},
		visibleEven(data, row){
			let index = data.findIndex((item) => item.name === row.name);
			if (index !== -1) {
				data[index]['selected'] = !data[index]['selected'];
			};
			this.$set(data, index, data[index]);
		},
		materialTap(data) {
			this.tenant.logo = data;
			this.is_material = false;
		},
		submitTap() {
			tenant_post(this.tenant)
				.then((res) => {
					if (res.statusCode === 200) {
						Message.success(res.message);
						this.$emit('closeTap');
					}
				});
		},
	},
};
</script>

<style scoped lang="scss">
.tb-edit .el-input,
.tb-edit .el-select {
	display: none;
}
.tb-edit .current-row .el-input,
.tb-edit .current-row .el-select {
	display: block;
}
.tb-edit .current-row .el-input + span,
.tb-edit .current-row .el-select + span {
	display: none;
}
.el-radio__input {
	display: none !important;
}
</style>
