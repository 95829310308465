<template>
	<div>
		<div style="padding: 15px">
			<div class="header">
				<div class="mark-title" style="font-size: 16px; height: 36px; line-height: 36px;">基础信息</div>
			</div>
			<div  style="margin:0 12px 58px 12px;">
				<el-form :model="freight" label-width="92px" v-loading="loading">
					<el-form-item label="模板名称">
						<el-input v-model="freight.name" placeholder="请输入模板名称" style="width: 380px"></el-input>
					</el-form-item>
					<el-form-item label="发货地">
						<el-input v-model="freight.ship_place" placeholder="请输入发货地" style="width: 380px"></el-input>
					</el-form-item>
					<el-form-item label="状态"> <el-switch v-model="freight.status"></el-switch></el-form-item>
					<el-form-item label="运费设置">
						<el-radio-group v-model="freight.freight_type">
							<el-radio :label="1">阶梯计价</el-radio>
							<el-radio :label="2">固定运费</el-radio>
							<el-radio :label="3">卖家包邮</el-radio>
						</el-radio-group>
					</el-form-item>
					<div class="header">
						<div class="mark-title" style="font-size: 13px; height: 36px; line-height: 36px;">不配送区域</div>
					</div>
					<el-form-item label="不配送区域">
						<el-select v-model="freight.not_delivery" multiple style="width: 580px" placeholder="请选择">
							<el-option v-for="item in area_data" :key="item.key" :label="item.label" :value="item.label"> </el-option>
						</el-select>
					</el-form-item>

					<div class="header">
						<div class="mark-title" style="font-size: 13px; height: 36px; line-height: 36px;">卖家付邮费区域<span>（未设置的地区将默认设置为包邮）</span></div>
					</div>
					<el-form-item label="计费方式">
						<el-radio-group v-model="freight.freight_type">
							<el-radio :label="1">按件数计费</el-radio>
							<el-radio :label="2">按重量计费</el-radio>
						</el-radio-group>
					</el-form-item>

					<div style="display: flex; justify-content: flex-start;margin-bottom:12px;width:950px;">
						<!-- <div style="width: 80px;height:80px;display: flex;align-items: center;padding-right:12px;">
							<span>{{freight.name}}</span>
						</div> -->
						<div style="display: inline-block;">
							<div style="display: flex; justify-content: flex-start;">
								<el-input-number v-model="freight.figure" controls-position="right" :min="1" :max="10" style="width: 140px"></el-input-number>
								<span style="line-height: 34px; padding:0 8px 0 8px;"><span v-if="freight.type == 1">件</span><span v-else>kg </span>内，</span>
								<el-input v-model="freight.amount" placeholder="请输入金额" style="width: 140px"></el-input>
								<span style="line-height: 34px; padding:0 8px 0 8px;">元; 每增加</span>
								<el-input v-model="freight.increase_figure" placeholder="请输入数量" style="width: 140px"></el-input>
								<span style="line-height: 34px; padding:0 8px 0 8px;"><span v-if="freight.type == 1">件</span><span v-else>kg </span>，增加运费</span>
								<el-input v-model="freight.increase_amount" placeholder="请输入金额" style="width: 140px"></el-input>
								<span style="line-height: 34px; padding:0 8px 0 8px;">元</span>
							</div>
							<div style="margin-top: 8px;">
								<el-checkbox v-model="freight.cond">指定条件：满 <el-input-number v-model="freight.cond_figure" controls-position="right" :min="0" style="width: 140px"></el-input-number> <span v-if="freight.type == 1">件</span><span v-else>kg </span>包邮</el-checkbox>
							</div>
						</div>
					</div>

					<el-transfer
						style="text-align: left; display: inline-block"
						v-model="freight.postage_free"
						filterable
						:titles="['选择区域', '包邮区域']"
						:props="{key:'label'}"
						:button-texts="['到左边', '到右边']"
						:format="{
							noChecked: '${total}',
							hasChecked: '${checked}/${total}'
						}"
						:data="area_data">
						<span slot-scope="{ option }">{{ option.label }}</span>
					</el-transfer>

					<!-- <el-divider content-position="left">delivery_arr 数据</el-divider>
					<pre><code>{{ freight }}</code></pre> -->
				</el-form>
			</div>
		</div>
		<div class="bottom-fixed">
			<div class="box-item">
				<el-button size="medium" @click="closeEvent()">取 消</el-button>
				<el-button size="medium" type="primary" @click="submitTap()">立即提交</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { freight_post, freight_get } from '@/api/goods';
export default {
	name: 'freight_detail',
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: ()=>({}),
		},
	},
	data() {
		return {
			freight: {},
			roles: [],
			loading: true,
			dialogImageUrl: '',
			dialogVisible: false,
			maxLimit: 1,
			treeData: [],
			delivery:[{}],
			area_data: [{
				key: 1,
				label: '北京市'
			}, {
				key: 2,
				label: '天津市'
			}, {
				key: 3,
				label: '河北省'
			}, {
				key: 4,
				label: '山西省'
			}, {
				key: 5,
				label: '内蒙古自治区'
			}, {
				key: 6,
				label: '辽宁省'
			}, {
				key: 7,
				label: '吉林省'
			}, {
				key: 8,
				label: '黑龙江省'
			}, {
				key: 9,
				label: '上海市'
			}, {
				key: 10,
				label: '江苏省'
			}, {
				key: 11,
				label: '浙江省'
			}, {
				key: 12,
				label: '安徽省'
			}, {
				key: 13,
				label: '福建省'
			}, {
				key: 14,
				label: '江西省'
			}, {
				key: 15,
				label: '山东省'
			}, {
				key: 16,
				label: '河南省'
			}, {
				key: 17,
				label: '河北省'
			}, {
				key: 18,
				label: '广东省'
			}, {
				key: 19,
				label: '广西壮族自治区'
			}, {
				key: 20,
				label: '河南省'
			}, {
				key: 21,
				label: '重庆市'
			}, {
				key: 22,
				label: '四川省'
			}, {
				key: 23,
				label: '贵州省'
			}, {
				key: 24,
				label: '云南省'
			}, {
				key: 25,
				label: '西藏自治区'
			}, {
				key: 26,
				label: '陕西省'
			}, {
				key: 27,
				label: '青海省'
			}, {
				key: 28,
				label: '宁夏回族自治区'
			}, {
				key: 29,
				label: '新疆维吾尔自治区'
			}, {
				key: 30,
				label: '台湾省'
			}, {
				key: 31,
				label: '香港特别行政区'
			}, {
				key: 32,
				label: '澳门特别行政区'
			}],
			area_val: [],
			delivery_arr: [],
		};
	},
	created() {
		this.initData();
	},
	mounted() {
		if (Object.keys(this.data).length > 0) {
			freight_get(this.data.id)
				.then((res) => {
					this.freight = res.data;
					this.delivery_arr = this.freight.delivery_arr;
				})
				.finally(() => {
					this.loading = false;
				});
		}else{
			this.freight = {};
		}
	},
	methods: {
		initData() {
			this.loading = false;
		},
		deliveryTap(item) {
			this.delivery_arr = [];
			item.map((row) => {
				this.delivery_arr.push({
					name: row,
					figure: 1,
					amount: 0,
					increase_figure: 0,
				});
			});
		},
		closeEvent() {
			this.$emit('closeTap');
		},
		submitTap() {
			this.freight.delivery_arr = this.delivery_arr;
			freight_post(this.freight)
				.then((res) => {
					if (res.statusCode === 200) {
						Message.success(res.message);
						this.$emit('closeTap');
					}
				});
		},
	},
};
</script>

<style scoped lang="scss">
.tb-edit .el-input,
.tb-edit .el-select {
	display: none;
}
.tb-edit .current-row .el-input,
.tb-edit .current-row .el-select {
	display: block;
}
.tb-edit .current-row .el-input + span,
.tb-edit .current-row .el-select + span {
	display: none;
}
.el-radio__input {
	display: none !important;
}
</style>
