import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import Contextmenu from 'vue-contextmenujs'
import Particles from 'vue-particles';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { VueOkrTree } from 'vue-okr-tree';
import CryptoJS from "crypto-js"
import 'vue-okr-tree/dist/vue-okr-tree.css'
import '@/theme/index.scss';
import '@/utils/functions';
import { i18n } from '@/i18n/index.js';
import { globalComponentSize } from '@/utils/componentSize.js';

import '@/utils/authority'
import '@/utils/directives'
import SkuForm from 'vue-sku-form'
// 引入自定义全局css
import '@/assets/css/global.less'

Vue.use(Contextmenu)
Vue.use(Particles);
Vue.use(CryptoJS);
Vue.use(Element, { i18n: (key, value) => i18n.t(key, value), size: globalComponentSize });
Vue.use(SkuForm);

Vue.config.productionTip = false;
Vue.prototype.bus = new Vue();

new Vue({
    router,
    store,
    i18n,
    VueOkrTree,
    render: (h) => h(App),
}).$mount('#app');