<template>
	<div class="system-menu-container">
		<div :class="visible ? 'box-modal-hide' : 'box-modal-show'" style="padding:15px;">
			<el-row>
				<el-col :span="4">
					<el-input placeholder="输入关键字进行过滤" size="medium" v-model="filterText"> </el-input>

					<el-tree
						class="filter-tree mt15"
						:data="treeData"
						:props="{ label: 'title' }"
						:filter-node-method="filterNode"
						ref="tree"
					>
					</el-tree>
				</el-col>
				<el-col :span="20">
					<el-form ref="form" :model="menu" size="small" label-width="100px" class="mt15 mb15">
						<el-row>
							<el-col :span="8">
								<el-form-item label="菜单名称">
									<el-input v-model="menu.title" placeholder="请输入菜单名称" clearable></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="是否启用">
									<el-select v-model="menu.status" placeholder="请选择状态" clearable class="w100">
										<el-option label="启用" value="1"></el-option>
										<el-option label="关闭" value="2"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="是否外链">
									<el-select v-model="menu.is_link" placeholder="请选择状态" clearable class="w100">
										<el-option label="是" value="1"></el-option>
										<el-option label="否" value="2"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="iframe">
									<el-select v-model="menu.iframe" placeholder="请选择状态" clearable class="w100">
										<el-option label="是" value="1"></el-option>
										<el-option label="否" value="2"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="标识">
									<el-input v-model="menu.auth_code" placeholder="请输入标识" clearable></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="是否内嵌">
									<el-select v-model="menu.is_affix" placeholder="请选择状态" clearable class="w100">
										<el-option label="是" value="1"></el-option>
										<el-option label="否" value="2"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item align="right">
									<el-button icon="el-icon-refresh">清空</el-button>
									<el-button type="primary" icon="el-icon-search" @click="searchTap()">立即查询</el-button>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
					<el-card shadow="never">
						<div style="padding-bottom: 15px">
							<el-row>
								<el-button type="danger" size="small" icon="el-icon-delete" @click="batchDelTap()">批量删除</el-button>
								<el-button type="primary" size="small" icon="el-icon-edit" @click="openVisible()">添加菜单</el-button>
							</el-row>
						</div>
						<el-table v-loading="loading" :data="tableData" stripe style="width: 100%">
							<el-table-column fixed type="selection" width="55" align="center"></el-table-column>
							<el-table-column fixed prop="title" label="菜单名称" show-overflow-tooltip width="150"></el-table-column>
							<el-table-column fixed prop="name" label="权限标识" show-overflow-tooltip width="150"></el-table-column>
							<el-table-column label="类型" width="110">
								<template #default="scope">
									<span v-if="scope.row.is_dir" class="color-info">目录</span>
									<span v-else class="color-info">菜单</span>
								</template>
							</el-table-column>
							<el-table-column label="状态" width="70">
								<template #default="scope">
									<span v-if="scope.row.status" class="color-primary">启用</span>
									<span v-else class="color-info">禁用</span>
								</template>
							</el-table-column>
							<!-- <el-table-column label="所属上级菜单" show-overflow-tooltip width="230">
								<template #default="scope">
									<span>{{ scope.row.parent_names }}</span>
								</template>
							</el-table-column>
							<el-table-column prop="action_names" show-overflow-tooltip label="子菜单" width="180"></el-table-column> -->
							<el-table-column prop="path" label="路由名称"></el-table-column>
							<el-table-column label="组件地址">
								<template #default="scope">
									<span>{{ scope.row.component }}</span>
								</template>
							</el-table-column>
							<el-table-column label="隐藏" width="70">
								<template #default="scope">
									<span v-if="scope.row.is_hide" class="color-primary">是</span>
									<span v-else class="color-info">否</span>
								</template>
							</el-table-column>
							<!-- <el-table-column label="固定" width="70">
								<template #default="scope">
									<span v-if="scope.row.is_affix" class="color-primary">是</span>
									<span v-else class="color-info">否</span>
								</template>
							</el-table-column> -->
							<!-- <el-table-column label="外链" show-overflow-tooltip width="70">
								<template #default="scope">
									<span v-if="scope.row.is_link && !scope.row.is_iframe" class="color-primary">是</span>
									<span v-else class="color-danger">否</span>
								</template>
							</el-table-column> -->
							<el-table-column label="iframe" fixed="right" show-overflow-tooltip width="70">
								<template #default="scope">
									<span v-if="scope.row.is_link && scope.row.is_iframe" class="color-primary">是</span>
									<span v-else class="color-info">否</span>
								</template>
							</el-table-column>
							<el-table-column label="操作" fixed="right" width="105">
								<template #default="scope">
									<el-button size="mini" type="text" @click="openVisible(scope.row)">编辑</el-button>
									<el-button size="mini" class="color-danger" type="text" @click="deleteTap(scope.row)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
						<div class="block" style="margin-top: 15px">
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								:current-page="pageInfo.now"
								:page-sizes="[10, 20, 50, 100, 200]"
								:page-size="pageInfo.size"
								layout="total, sizes, prev, pager, next, jumper"
								:total="pageInfo.count"
							>
							</el-pagination>
						</div>
					</el-card>
				</el-col>
			</el-row>
		</div>

		<detail v-if="visible" :type="type" :data="rowData" @closeTap="handleClose"></detail>
	</div>
</template>

<script>
import { menu_list, menu_tree, menu_del } from '@/api/auth';
import detail from './component/detail.vue';
export default {
	components: { detail },
	name: 'menu',
	data() {
		return {
			tableData: [],
			treeData: [],
			menu: {},
			rowData:{},
			loading: true,
			filterText: '',
			type: 1,
			visible: false,
			pageInfo: {
	now: 1,
	size: 20
},
		};
	},
	created() {
		this.initData();
	},
	watch: {
		filterText(val) {
			this.$refs.tree.filter(val);
		},
	},
	methods: {
		initData() {
			var param = {
				title: this.menu.title || undefined,
				chind: this.menu.chind || undefined,
				is_hide: this.menu.is_hide || undefined,
				status: this.menu.status || undefined,
				is_link: this.menu.is_link || undefined,
				iframe: this.menu.iframe || undefined,
				is_affix: this.menu.is_affix || undefined,
				pageSize: this.pageInfo.size || undefined,
				pageNow: this.pageInfo.now || undefined,
			};
			menu_list({ ...param })
				.then((res) => {
					if (res.statusCode !== 200) {
						this.$message({
							type: 'error',
							message: res.message
						});
						return true;
					}
					this.pageInfo = res.page;
					this.tableData = res.lists;
				}).finally(() => {
					this.loading = false;
				});

			menu_tree({ ...param })
				.then((res) => {
					if (res.statusCode !== 200) {
						this.$message({
							type: 'error',
							message: res.message
						});
						return true;
					}
					this.treeData = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		filterNode(value, data) {
			if (!value) return true;
			return data.label.indexOf(value) !== -1;
		},
		handleClose() {
			this.loading = true;
			this.initData();
			this.visible = false;
		},
		openVisible(data = null) {
			if (data !== null) {
				this.type = 1;
				this.rowData = data;
			} else {
				this.rowData = {};
			}
			this.visible = true;
		},
		searchTap() {
			this.loading = true;
			this.initData();
		},
		// resetTap(form) {
		// 	this.$refs[form].resetFields()
		// },
		batchDelTap() {
			this.loading = true;
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.loading = false;
			});
		},
		deleteTap(data) {
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.loading = true;
				menu_del(data.id).then((res) => {
					if (res.statusCode === 200) {
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
						this.initData();
					}
				}).finally(() => {
					this.loading = false;
				});
			});
		},
		handleSizeChange(val) {
			this.loading = true;
			this.pageInfo.size = val;
			this.initData();
		},
		handleCurrentChange(val) {
			this.loading = true;
			this.pageInfo.now = val;
			this.initData();
		},
	},
};
</script>