import request from '@/utils/request';

export function order_index(params) {
    return request({
        url: '/box_order',
        method: 'get',
        params: params,
    });
}

export function order_get(id, params) {
    return request({
        url: '/box_order/' + id,
        method: 'get',
        params: params,
    });
}

export function order_del(id, params = '') {
    return request({
        url: '/box_order/' + id,
        method: 'delete',
        params: params,
    });
}

export function order_put(params) {
    return request({
        url: '/box_order',
        method: 'put',
        params: params,
    });
}

export function order_ship(id, params) {
    return request({
        url: '/box_order/ship/' + id,
        method: 'put',
        params: params,
    });
}
