<template>
	<div class="system-menu-container">
		<div :class="visible ? 'box-modal-hide' : 'box-modal-show'" style="padding: 15px">
			<el-form :model="role" size="small" label-width="100px" class="mt15 mb15">
				<el-row>
					<el-col :span="6">
						<el-form-item label="角色名称">
							<el-input v-model="role.name" placeholder="请输入角色名称" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="上级组织">
							<el-input v-model="role.name" placeholder="请输入角色名称" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="状态">
							<el-select v-model="role.status" placeholder="请选择状态" clearable class="w100">
								<el-option label="启用" value="0"></el-option>
								<el-option label="禁用" value="1"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="上级岗位">
							<el-select v-model="role.parent_id" placeholder="请选择岗位">
								<el-option :label="role.name" v-for="role in roleList" :key="role.id" :value="role.id"> </el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item align="right">
							<el-button icon="el-icon-refresh">清空</el-button>
							<el-button type="primary" icon="el-icon-search" @click="submitTap()">立即查询</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<el-card shadow="never">
				<div style="padding-bottom: 15px">
					<el-row>
						<el-button type="danger" size="small" icon="el-icon-delete" @click="batchDelTap()">批量删除</el-button>
						<el-button type="primary" size="small" icon="el-icon-edit" @click="openVisible()">添加角色</el-button>
					</el-row>
				</div>
				<el-table v-loading="loading" :data="tableData" stripe style="width: 100%">
					<el-table-column fixed type="selection" width="55"></el-table-column>
					<el-table-column label="角色">
						<template #default="scope">
							<span class="color-info">{{scope.row.name}}</span>
						</template>
					</el-table-column>
					<el-table-column label="租户">
						<template #default="scope">
							<span class="color-info">{{scope.row.tenant.name}}</span>
						</template>
					</el-table-column>
					<el-table-column label="是否启用">
						<template #default="scope">
							<span v-if="scope.row.status" class="color-primary">是</span>
							<span v-else class="color-danger">否</span>
						</template>
					</el-table-column>
					<el-table-column prop="remark" show-overflow-tooltip label="备注" width="260"></el-table-column>
					<el-table-column label="创建时间">
						<template #default="scope">
							<span class="color-info">{{scope.row.created_at}}</span>
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="操作" width="125">
						<template #default="scope">
							<el-button size="mini" type="text" @click="openVisible(scope.row)">编辑</el-button>
							<el-button size="mini" class="color-danger" type="text" @click="deleteTap(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="block" style="margin-top: 15px">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="pageInfo.now"
						:page-sizes="[10, 20, 50, 100, 200]"
						:page-size="pageInfo.size"
						layout="total, sizes, prev, pager, next, jumper"
						:total="pageInfo.count"
					>
					</el-pagination>
				</div>
			</el-card>
		</div>
		<detail v-if="visible" :type="type" :data="rowData" @closeTap="handleClose"></detail>
	</div>
</template>

<script>
import { role_index, role_list, role_del } from '@/api/auth';
import detail from './component/detail.vue';
export default {
	components: { detail },
	name: 'role',
	data() {
		return {
			visible: false,
			role: {},
			roleList: {},
			pageInfo: {
	now: 1,
	size: 20
},
			tableData: [],
			loading: true,
		};
	},
	created() {
		this.initData();
	},
	methods: {
		initData() {
			var param = {
				name: this.role.name || undefined,
				status: this.role.status || undefined,
				parent_id: this.role.parent_id || undefined,
				pageSize: this.pageInfo.size || undefined,
				pageNow: this.pageInfo.now || undefined,
			};
			role_index({ ...param })
				.then((res) => {
					if (res.statusCode !== 200) {
						this.$message({
							type: 'error',
							message: res.message,
						});
						return true;
					}
					this.pageInfo = res.page;
					this.tableData = res.lists;
				})
				.finally(() => {
					this.loading = false;
				});

			// role_list()
			// 	.then((res) => {
			// 		this.roleList = res.data;
			// 	})
			// 	.catch(() => {});
		},
		handleClose() {
			this.visible = false;
			this.loading = true;
			this.initData();
		},
		checkDetail(data) {
			
			this.rowData = data;
			this.visible = true;
		},
		openVisible(data = null) {
			if (data !== null) {
				this.type = 1;
				this.rowData = data;
			} else {
				this.rowData = {};
			}
			this.visible = true;
		},
		submitTap() {
			this.loading = true;
			this.initData();
		},
		handleSizeChange(val) {
			this.loading = true;
			this.pageInfo.size = val;
			this.initData();
		},
		handleCurrentChange(val) {
			this.loading = true;
			this.pageInfo.now = val;
			this.initData();
		},
		batchDelTap() {
			this.loading = true;
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.loading = false;
			});
		},
		deleteTap(e) {
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.loading = true;
				role_del(e.id).then((res) => {
					if (res.statusCode === 200) {
						this.$message({
							type: 'success',
							message: '删除成功!'
						});
						this.initData();
						this.loading = false;
					}
				}).catch(() => {});
			});
		}
	},
};
</script>