<template>
	<div>
		<div style="padding: 15px">
			<div class="header">
				<div class="mark-title" style="font-size: 16px; height: 36px; line-height: 36px;">账号信息</div>
			</div>
			<el-form :model="staffer" style="padding:15px;" label-width="80px" v-loading="loading">
				<el-form-item label="真实姓名">
					<el-input v-model="staffer.name" placeholder="请输入真实姓名" clearable style="width: 380px"></el-input>
				</el-form-item>
				<el-form-item label="昵称">
					<el-input v-model="staffer.nickname" placeholder="请输入昵称" clearable style="width: 380px"></el-input>
				</el-form-item>
				<el-form-item label="邮箱">
					<el-input v-model="staffer.email" placeholder="请输入邮箱" clearable style="width: 380px"></el-input>
				</el-form-item>
				<el-form-item label="密码">
					<el-input v-model="staffer.password" placeholder="请输入密码" show-password clearable style="width: 380px"></el-input>
					<div class="sub-title">如不填默认密码为: 123456</div>
				</el-form-item>
				<el-form-item label="所属租户">
					<el-select v-model="staffer.tenant_id" style="width: 380px" placeholder="请选择">
						<el-option v-for="tenant in tenants" :key="tenant.id" :label="tenant.name" :value="tenant.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="角色">
					<el-select v-model="staffer.role_ids" style="width: 380px" multiple placeholder="请选择">
						<el-option v-for="role in roles" :key="role.id" :label="role.name" :value="role.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="联系方式">
					<el-input v-model="staffer.mobile" placeholder="请输入联系方式" clearable style="width: 380px"></el-input>
				</el-form-item>
				<el-form-item label="状态"><el-switch v-model="staffer.status"></el-switch></el-form-item>
				<el-form-item label="头像">
					<div style="float:left;">
						<div style="margin:6px 6px;float:left;overflow: hidden;width:120px;" v-for="(item, index) in staffer.avatar_url" :key="index">
							<div class="material-img" v-on:mouseenter="visibleEven(staffer.avatar_url, item)" @mouseleave="visibleEven(staffer.avatar_url, item)">
								<img class="images" :src="item.url" />
								<div class="item-selected" v-if="item.selected" @click="removeTap(staffer.avatar_url, index)"><i class="el-icon-delete"/></div>
							</div>
						</div>
					</div> 
					<div class="material-upload" style="margin:6px 6px;">
						<span @click="showMaterialTap()">添加图片</span>
					</div>
					<span class="sub-title"><i style="padding-right: 5px;color: red;">*</i>建议尺寸：800*800</span>
				</el-form-item>
				<el-form-item label="账号简介">
					<el-input type="textarea" v-model="staffer.remark" clearable :autosize="{ minRows: 4, maxRows: 6 }" maxlength="150" show-word-limit style="width: 530px"></el-input>
				</el-form-item>
			</el-form>
		</div>
		<div class="bottom-fixed">
			<div class="box-item">
				<el-button size="medium" @click="closeEvent()">取 消</el-button>
				<el-button size="medium" type="primary" @click="submitTap()">立即提交</el-button>
			</div>
		</div>
		
		<material v-if="is_material" :selected="selected" :type="material_type" :limit="limit" @materialTap="materialTap" @closeTap="showMaterialTap"></material>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { Session } from '@/utils/storage';
import { role_list} from '@/api/auth';
import { tenant_list} from '@/api/tenant';
import material from '@/components/dialog/material.vue';
import { staffer_post, staffer_get } from '@/api/staffer';
export default {
	name: 'staffer_detail',
	components: {
		material
	},
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: ()=>({}),
		},
	},
	data() {
		return {
			is_material: false,
			upload_url: process.env.VUE_APP_BASE_API + '/base/upload',
			headers: {
				"Authorization":  `Bearer ${Session.get('token')}`
			},
			selected:[],
			material_type:'image',
			limit:1,
			staffer: {
				avatar_url:[]
			},
			maxLimit: 1,
			roles: [],
			tenants: [],
			loading: true,
			treeData: [],
		};
	},
	created() {
		this.initData();
	},
	mounted() {
		if (Object.keys(this.data).length > 0) {
			staffer_get(this.data.id)
				.then((res) => {
					this.staffer = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
		}else{
			this.staffer = {};
		}
	},
	methods: {
		initData() {
			role_list()
				.then((res) => {
					this.roles = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
			tenant_list()
				.then((res) => {
					this.tenants = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		beforeUpload(res) {
			if (res.data.statusCode === 200) {
				Message.error(res.message);
				return false;
			}
			this.staffer.avatar_url = [{name: res.data.file_name, url: res.data.file_url}];
		},
		imageRemove() {
			this.staffer.avatar_url = [];
        },
		closeEvent() {
			this.$emit('closeTap');
		},
		showMaterialTap(){
			this.is_material = !this.is_material;
		},
		materialTap(data) {
			this.staffer.avatar_url = data;
			this.is_material = false;
		},
		removeTap(data, index){
			data.splice(index, 1);
		},
		visibleEven(data, row){
			let index = data.findIndex((item) => item.name === row.name);
			if (index !== -1) {
				data[index]['selected'] = !data[index]['selected'];
			};
			this.$set(data, index, data[index]);
		},
		submitTap() {
			staffer_post(this.staffer)
				.then((res) => {
					if (res.statusCode === 200) {
						Message.success(res.message);
						this.$emit('closeTap');
					}
				});
		},
	},
};
</script>

<style scoped lang="scss">
.tb-edit .el-input,
.tb-edit .el-select {
	display: none;
}
.tb-edit .current-row .el-input,
.tb-edit .current-row .el-select {
	display: block;
}
.tb-edit .current-row .el-input + span,
.tb-edit .current-row .el-select + span {
	display: none;
}
.el-radio__input {
	display: none !important;
}
</style>
