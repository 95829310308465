import request from '@/utils/request';

export function feedback_index(params) {
    return request({
        url: '/feedback',
        method: 'get',
        data: params,
    });
}

export function feedback_get(id, params) {
    return request({
        url: '/feedback/' + id,
        method: 'get',
        params: params,
    });
}

export function feedback_post(params) {
    return request({
        url: '/feedback',
        method: 'post',
        data: params,
    });
}

export function feedback_del(id) {
    return request({
        url: '/feedback/' + id,
        method: 'delete',
    });
}