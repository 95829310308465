<template>
	<div>
		<div style="margin:12px 12px 58px 12px;">
			<el-tabs v-model="activeName" type="card"  size="mini">
				<el-tab-pane label="角色信息" name="info">
					<el-form :model="role" label-width="80px" style="padding: 15px;margin-bottom:36px;">
						<el-row :gutter="20">
							<el-col :span="24">
								<el-form-item label="角色名称">
									<el-input v-model="role.name" placeholder="请输入角色名称" clearable style="width: 380px"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="租户">
									<el-select v-model="role.tenant_id" style="width: 380px" placeholder="请选择">
										<el-option v-for="tenant in tenants" :key="tenant.id" :label="tenant.name" :value="tenant.id"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="状态">
									<el-switch v-model="role.status"></el-switch>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="角色职责">
									<el-input type="textarea" :rows="4" v-model="role.remark" clearable style="width: 480px"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="模块权限" name="auth">
					<div style="padding:10px 38px;">
						<!-- <el-input
							style="width: 380px;margin-bottom:28px;"
							placeholder="输入关键字进行过滤"
							v-model="filterText">
						</el-input> -->
						<el-tree
							:data="treeData"
							show-checkbox
							default-expand-all
							node-key="id"
							:props="{ label: 'title' }"
							:filter-node-method="filterNode"
  							ref="tree"
							highlight-current>
						</el-tree>
					</div>
				</el-tab-pane>
			</el-tabs>
		</div>
		<div class="bottom-fixed">
			<div class="box-item">
				<el-button size="medium" @click="closeTap()">取 消</el-button>
				<el-button size="medium" type="primary" @click="submitTap()">立即提交</el-button>
			</div>
		</div>
	</div>
</template>


<script>
import { Message } from 'element-ui';
import { tenant_list} from '@/api/tenant';
import { role_post, role_list, role_get, menu_tree } from '@/api/auth';
export default {
	name: 'role',
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: () => ({}),
		},
	},
	watch: {
        filterText(val) {
          this.$refs.tree.filter(val);
        }
    },
	data() {
		return {
			activeName: 'info',
			role: {
				menus: []
			},
			filterText: '',
			role_line: {},
			role_data: {},
			tenants: [],
			treeData: [],
		};
	},
	created() {
		this.initData();
	},
	mounted() {
		if (Object.keys(this.data).length > 0) {
			role_get(this.data.id)
				.then((res) => {
					this.role = res.data;
					this.$refs.tree.setCheckedKeys(this.role.menus);
				})
				.finally(() => {
					this.loading = false;
				});
		}
	},
	methods: {
		initData() {
			var param = {};
			role_list({ ...param })
				.then((res) => {
					this.role_data = res.data;
				})
				.catch(() => {});

			tenant_list()
				.then((res) => {
					this.tenants = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
								
			menu_tree({ ...param })
				.then((res) => {
					if (res.statusCode !== 200) {
						this.$message({
							type: 'error',
							message: res.message
						});
						return true;
					}
					this.treeData = res.data;
				})
				.catch(() => {});
		},
		closeTap() {
			this.$emit('closeTap');
		},
		filterNode(value, data) {
			if (!value) return true;
			return data.label.indexOf(value) !== -1;
		},
		submitTap() {
			console.log(this.$refs.tree.getCheckedKeys());
			this.role.menus = this.$refs.tree.getCheckedKeys();
			role_post(this.role)
				.then((res) => {
					if (res.statusCode == 200) {
						Message.success(res.message);
						this.$emit('closeTap');
					}
				})
				.catch(() => {});
		},
	},
};
</script>
<style lang="less">
.card-header {
	height: 18px;
	display: flex;

	justify-content: space-between;
	align-items: center;
}
/deep/.el-card__body {
	padding: 0px;
}
</style>
