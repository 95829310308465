<template>
	<div>
		<div style="padding: 15px;margin-bottom:20px;">
			<el-tabs v-model="activeName" type="card"  size="mini">
				<el-tab-pane label="基础配置" name="info">
				<el-form :model="coupon" style="padding:15px;" label-width="90px" v-loading="loading">
					<el-form-item label="名称">
						<el-input v-model="coupon.name" maxlength="15" show-word-limit placeholder="请输入名称" clearable style="width: 380px"></el-input>
					</el-form-item>
					<el-form-item label="简介">
						<el-input v-model="coupon.remark" maxlength="25" show-word-limit placeholder="请输入简介" clearable style="width: 380px"></el-input>
					</el-form-item>
					<el-form-item label="状态"> <el-switch v-model="coupon.status"></el-switch></el-form-item>
					<el-form-item label="优惠券类型">
						<el-radio-group v-model="coupon.coupon_type">
							<el-radio label="1">直减现金券</el-radio>
							<el-radio label="2">满减现金券</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="发放数量">
						<el-input-number v-model="coupon.quantity" controls-position="right" :min="1" :max="10" style="width: 280px"></el-input-number>
					</el-form-item>
					<el-form-item label="限领数量">
						<el-input-number v-model="coupon.repeat_quantity" controls-position="right" :min="1" :max="10" style="width: 280px"></el-input-number>
					</el-form-item>
					<el-form-item label="优惠券金额">
						<el-input v-model="coupon.coupon_amount" placeholder="请输入金额" clearable style="width: 380px"></el-input>
					</el-form-item>
					<el-form-item label="满减金额" v-if="coupon.coupon_type == 2">
						<el-input v-model="coupon.full_amount" placeholder="请输入金额" clearable style="width: 380px"></el-input>
					</el-form-item>
					<el-form-item label="发放时间">
						<el-date-picker
							v-model="coupon.grant_time"
							type="datetimerange"
							value-format="yyyy-MM-dd HH:mm:ss"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="生效时间">
						<el-date-picker
							v-model="coupon.effective_time"
							type="datetimerange"
							value-format="yyyy-MM-dd HH:mm:ss"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期">
						</el-date-picker>
					</el-form-item>
				</el-form>
				</el-tab-pane>
				<el-tab-pane label="商品信息" name="spec">
					<el-table v-loading="loading" :data="coupon.goods" stripe style="width: 100%">
						<el-table-column label="商品名称">
							<template #default="scope">
								<span>{{ scope.row.goods_name }}</span>
							</template>
						</el-table-column>
						<el-table-column label="封面">
							<template #default="scope">
								<el-avatar shape="square" :size="50" :src="scope.row.goods_thumb"></el-avatar>
							</template>
						</el-table-column>
						<el-table-column label="商品价格">
							<template #default="scope">
								<span>{{ scope.row.goods_price }}</span>
							</template>
						</el-table-column>
						<el-table-column label="商品原价">
							<template #default="scope">
								<span>{{ scope.row.line_price }}</span>
							</template>
						</el-table-column>
						<el-table-column label="销量">
							<template #default="scope">
								<span>{{ scope.row.limit_num }}</span>
							</template>
						</el-table-column>
						<el-table-column label="库存">
							<template #default="scope">
								<span>{{ scope.row.stock_num }}</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="65"  fixed="right">
							<template #default="scope">
								<el-button size="mini" type="text" @click="removeTap(scope.row)">移除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
			</el-tabs>
		</div>
		<div class="bottom-fixed">
			<div class="box-item">
				<el-button size="medium" @click="closeEvent()">取 消</el-button>
				<el-button size="medium" type="primary" @click="submitTap()">立即提交</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { Session } from '@/utils/storage';
import { coupon_post, coupon_get, coupon_list } from '@/api/goods';
export default {
	name: 'coupon_detail',
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: ()=>({}),
		},
	},
	data() {
		return {
			upload_url: process.env.VUE_APP_BASE_API + '/base/upload',
			headers: {
				"Authorization":  `Bearer ${Session.get('token')}`
			},
			coupon: {
				thumb:[]
			},
			roles: [],
			loading: true,
			maxLimit: 1,
			activeName: 'info',
			coupon_lists: []
		};
	},
	created() {
		this.initData();
	},
	mounted() {
		if (Object.keys(this.data).length > 0) {
			coupon_get(this.data.id)
				.then((res) => {
					this.coupon = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
			coupon_list()
				.then((res) => {
					this.coupon_lists = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
		}else{
			this.coupon = {};
		}
	},
	methods: {
		initData() {
			this.loading = false;
		},
		beforeUpload(res) {
			if (res.data.statusCode === 200) {
				Message.error(res.message);
				return false;
			}
			this.coupon.thumb = [{name: res.data.file_name, url: res.data.file_url}];
		},
		imageRemove() {
			this.coupon.thumb = [];
        },
		closeEvent() {
			this.$emit('closeTap');
		},
		submitTap() {
			coupon_post(this.coupon)
				.then((res) => {
					if (res.statusCode === 200) {
						Message.success(res.message);
						this.$emit('closeTap');
					}
				});
		},
	},
};
</script>

<style scoped lang="scss">
.tb-edit .el-input,
.tb-edit .el-select {
	display: none;
}
.tb-edit .current-row .el-input,
.tb-edit .current-row .el-select {
	display: block;
}
.tb-edit .current-row .el-input + span,
.tb-edit .current-row .el-select + span {
	display: none;
}
.el-radio__input {
	display: none !important;
}
</style>
