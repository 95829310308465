<template>
	<div>
		<div  style="padding: 15px;margin-bottom:25px;">
			<div class="header">
				<div class="mark-title" style="font-size: 16px; height: 36px; line-height: 36px;">会员权益</div>
			</div>
			<el-form :model="useful" label-width="80px" v-loading="loading">
				<el-form-item label="名称">
					<el-input v-model="useful.name" placeholder="请输入名称" clearable style="width: 380px"></el-input>
				</el-form-item>
				<el-form-item label="icon">
					<div style="float:left;">
						<div style="margin:6px 6px;float:left;overflow: hidden;width:120px;" v-for="(item, index) in useful.icon" :key="index">
							<div class="material-img" v-on:mouseenter="visibleEven(useful.icon, item)" @mouseleave="visibleEven(useful.icon, item)">
								<img class="images" :src="item.url" />
								<div class="item-selected" v-if="item.selected" @click="removeTap(useful.icon, index)"><i class="el-icon-delete"/></div>
							</div>
						</div>
					</div> 
					<div class="material-upload" style="margin:6px 6px;">
						<span @click="showMaterialTap()">添加图片</span>
					</div>
					<span class="sub-title"><i style="padding-right: 5px;color: red;">*</i>建议尺寸：800*800</span>
				</el-form-item>
				<el-form-item label="状态"> <el-switch v-model="useful.status"></el-switch></el-form-item>
				<el-form-item label="简介">
					<el-input type="textarea" v-model="useful.remark" clearable :autosize="{ minRows: 4, maxRows: 6 }" maxlength="150" show-word-limit style="width: 530px"></el-input>
				</el-form-item>
			</el-form>
		</div>
		<div class="bottom-fixed">
			<div class="box-item">
				<el-button size="medium" @click="closeEvent()">取 消</el-button>
				<el-button size="medium" type="primary" @click="submitTap()">立即提交</el-button>
			</div>
		</div>

		<material v-if="is_material" :selected="selected" :type="material_type" :limit="limit" @materialTap="materialTap" @closeTap="showMaterialTap"></material>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { Session } from '@/utils/storage';
import material from '@/components/dialog/material.vue';
import { useful_post, useful_get } from '@/api/user';
export default {
	name: 'useful_detail',
	components: {
		material
	},
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: ()=>({}),
		},
	},
	data() {
		return {
			is_material: false,
			selected:[],
			material_type:'image',
			limit:1,
			upload_url: process.env.VUE_APP_BASE_API + '/base/upload',
			headers: {
				"Authorization":  `Bearer ${Session.get('token')}`
			},
			useful: {
				icon:[]
			},
			roles: [],
			loading: true,
			dialogImageUrl: '',
			dialogVisible: false,
			maxLimit: 1,
			treeData: [],
			activeName: 'info'
		};
	},
	created() {
		this.initData();
	},
	mounted() {
		if (Object.keys(this.data).length > 0) {
			useful_get(this.data.id)
				.then((res) => {
					this.useful = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
		}else{
			this.useful = {};
		}
	},
	methods: {
		initData() {
			this.loading = false;
		},
		beforeUpload(res) {
			if (res.data.statusCode === 200) {
				Message.error(res.message);
				return false;
			}
			this.useful.icon = [{name: res.data.file_name, url: res.data.file_url}];
		},
		imageRemove() {
			this.useful.icon = [];
        },
		closeEvent() {
			this.$emit('closeTap');
		},
		showMaterialTap(){
			this.is_material = !this.is_material;
		},
		removeTap(data, index){
			data.splice(index, 1);
		},
		visibleEven(data, row){
			let index = data.findIndex((item) => item.name === row.name);
			if (index !== -1) {
				data[index]['selected'] = !data[index]['selected'];
			};
			this.$set(data, index, data[index]);
		},
		materialTap(data) {
			this.useful.icon = data;
			this.is_material = false;
		},
		submitTap() {
			useful_post(this.useful)
				.then((res) => {
					if (res.statusCode === 200) {
						Message.success(res.message);
						this.$emit('closeTap');
					}
				});
		},
	},
};
</script>

<style scoped lang="scss">
	.tb-edit .el-input,
	.tb-edit .el-select {
		display: none;
	}
	.tb-edit .current-row .el-input,
	.tb-edit .current-row .el-select {
		display: block;
	}
	.tb-edit .current-row .el-input + span,
	.tb-edit .current-row .el-select + span {
		display: none;
	}
	.el-radio__input {
		display: none !important;
	}
</style>
