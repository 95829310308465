import request from '@/utils/request';

export function menu_auth() {
    return request({
        url: '/menu/lists',
        method: 'get',
    });
}

export function menu_tree(params) {
    return request({
        url: '/menu/tree',
        method: 'get',
        params: params,
    });
}

export function menu_list(params) {
    return request({
        url: '/menu',
        method: 'get',
        params: params,
    });
}

export function menu_post(params) {
    return request({
        url: '/menu',
        method: 'post',
        data: params,
    });
}

export function menu_get(id, params) {
    return request({
        url: '/menu/' + id,
        method: 'get',
        params: params,
    });
}

export function menu_del(id, params = '') {
    return request({
        url: '/menu/' + id,
        method: 'delete',
        params: params,
    });
}

export function role_index(params) {
    return request({
        url: '/role',
        method: 'get',
        params: params,
    });
}


export function role_list(params) {
    return request({
        url: 'role/lists',
        method: 'get',
        params: params,
    });
}

export function role_post(params) {
    return request({
        url: '/role',
        method: 'post',
        data: params,
    });
}

export function role_get(id, params) {
    return request({
        url: '/role/' + id,
        method: 'get',
        params: params,
    });
}

export function role_del(id, params) {
    return request({
        url: '/role/' + id,
        method: 'delete',
        params: params,
    });
}

export function auth_lists(params) {
    return request({
        url: '/auth/lists',
        method: 'get',
        params: params,
    });
}

export function auth_nodes(params) {
    return request({
        url: '/auth/nodes',
        method: 'get',
        params: params,
    });
}

export function auth_assign(params) {
    return request({
        url: '/auth/assign',
        method: 'post',
        data: params,
    });
}