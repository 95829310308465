<template>
	<div>
		<div style="margin: 12px 12px 58px 12px">
			<div style="border-radius: 4px; border: 1px solid #ebeef5; height: calc(100% - 10px)">
				<el-divider content-position="left">订单信息</el-divider>
				<div style="padding: 0 15px 0 15px">
					<el-descriptions class="margin-top" :column="3">
						<el-descriptions-item label="订单号">{{ order.order_no }}</el-descriptions-item>
						<el-descriptions-item label="订单状态">{{ order.status_name }}</el-descriptions-item>
						<el-descriptions-item label="应付金额">{{ order.raw_amount }}</el-descriptions-item>
						<el-descriptions-item label="实付金额">{{ order.amount }}</el-descriptions-item>
						<el-descriptions-item label="优惠券">{{ order.coupon_discount }}</el-descriptions-item>
						<!-- <el-descriptions-item label="抵扣积分">{{order.deductt_point}}</el-descriptions-item> -->
						<el-descriptions-item label="奖励积分">{{ order.gift_point }}</el-descriptions-item>
						<el-descriptions-item label="奖励成长值">{{ order.gift_growth }}</el-descriptions-item>
						<el-descriptions-item label="收件人">{{ order.contact }}</el-descriptions-item>
						<el-descriptions-item label="联系方式">{{ order.moblie }}</el-descriptions-item>
						<el-descriptions-item label="收件地址">{{ order.address }}</el-descriptions-item>
						<el-descriptions-item label="备注">{{ order.remark }}</el-descriptions-item>
					</el-descriptions>
				</div>
				<el-tabs v-model="activeName" type="card" size="mini">
					<el-tab-pane label="商品信息" name="info">
						<div style="padding: 0 15px 0 15px">
							<el-table v-loading="loading" :data="order.order_goods" stripe style="width: 100%">
								<el-table-column label="商品名称">
									<template #default="scope">
										<span>{{ scope.row.goods_name }}</span>
									</template>
								</el-table-column>
								<el-table-column label="封面">
									<template #default="scope">
										<el-avatar shape="square" :size="50" :src="scope.row.goods_thumb"></el-avatar>
									</template>
								</el-table-column>
								<el-table-column label="实付金额">
									<template #default="scope">
										<span>{{ scope.row.goods_price }}</span>
									</template>
								</el-table-column>
								<el-table-column label="原价">
									<template #default="scope">
										<span>{{ scope.row.line_price }}</span>
									</template>
								</el-table-column>
								<el-table-column label="优惠券">
									<template #default="scope">
										<span>{{ scope.row.coupon_discount }}</span>
									</template>
								</el-table-column>
								<!-- <el-table-column label="抵扣积分">
									<template #default="scope">
										<span>{{ scope.row.deductt_point }}</span>
									</template>
								</el-table-column> -->
								<el-table-column label="奖励积分">
									<template #default="scope">
										<span>{{ scope.row.gift_point }}</span>
									</template>
								</el-table-column>
								<el-table-column label="奖励成长值">
									<template #default="scope">
										<span>{{ scope.row.gift_growth }}</span>
									</template>
								</el-table-column>
								<el-table-column label="Sku">
									<template #default="scope">
										<span>{{ scope.row.sku_name }}</span>
									</template>
								</el-table-column>
								<el-table-column label="购买数量">
									<template #default="scope">
										<span>{{ scope.row.number }}</span>
									</template>
								</el-table-column>
								<!-- <el-table-column label="操作" width="165"  fixed="right">
									<template #default="scope">
										<el-button size="mini" type="text" @click="checkDetail(scope.row)">查看订单</el-button>
										<el-button size="mini" type="text" @click="checkDetail(scope.row)">发货</el-button>
										<el-button size="mini" type="text" @click="checkDetail(scope.row)">售后</el-button>
									</template>
								</el-table-column> -->
							</el-table>
						</div>
					</el-tab-pane>
					<el-tab-pane label="物流信息" name="spec">
						<el-divider content-position="left">order 数据</el-divider>
						<pre><code>{{ order }}</code></pre>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>

		<div class="bottom-fixed">
			<div class="box-item">
				<el-button size="medium" @click="closeTap()">取 消</el-button>
				<el-button size="medium" type="primary" @click="submitTap()">立即提交</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import { order_get, order_post } from '@/api/order';
import { Session } from '@/utils/storage';

export default {
	name: 'detail',
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},
		data: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			upload_url: process.env.VUE_APP_BASE_API + '/base/upload',
			headers: {
				Authorization: `Bearer ${Session.get('token')}`,
			},
			loading: true,
			couponData: [],
			activeName: 'info',
			new_attribute: '',
			select: false,
			order: {},
		};
	},
	mounted() {
		if (Object.keys(this.data).length > 0) {
			order_get(this.data.order_id)
				.then((res) => {
					this.order = res.data;
				})
				.finally(() => {
					this.loading = false;
				});
		}
	},
	methods: {
		closeTap() {
			this.$emit('closeTap');
		},
		submitTap() {
			this.$emit('closeTap');
		},
	},
};
</script>

<style lang="less" scoped>
/deep/ .el-upload-dragger {
	width: initial;
	height: initial;
	border: 0;
	border-radius: 0;
	background-color: initial;
	overflow: auto;
}
/deep/ .image-upload-container {
	.el-image {
		vertical-align: middle;
		margin: 0 5px;
		width: 30px;
		height: 30px;
	}
	.images-upload,
	> .el-button {
		display: inline-block;
		margin: 0 5px;
		vertical-align: middle;
	}
}
</style>
