<template>
	<div class="system-menu-container">
		<div :class="visible || shipVisible ? 'box-modal-hide' : 'box-modal-show'" style="padding:15px;">
			<el-form :model="order" size="small" label-width="100px" class="mt15 mb15">
				<el-row>
					<el-col :span="6">
						<el-form-item label="订单号">
							<el-input v-model="order.order_order_no" placeholder="请输入订单号" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="商品名称">
							<el-input v-model="order.goods_name" placeholder="请输入商品名称" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="账号编号">
							<el-input v-model="order.staffer_no" placeholder="请输入账号编号" clearable></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="状态">
							<el-select v-model="order.status" placeholder="请选择状态" clearable class="w100">
								<el-option label="启用" value="0"></el-option>
								<el-option label="禁用" value="1"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item align="right">
							<el-button icon="el-icon-refresh">清空</el-button>
							<el-button type="primary" icon="el-icon-search" @click="submitTap()">立即查询</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<el-tabs v-model="status" type="card" @tab-click="handleClick">
				<el-tab-pane :label="type.label" :name="type.name" v-for="(type, i) in types" :key="i"></el-tab-pane>
			</el-tabs>
			<el-table v-loading="loading" :data="tableData" stripe style="width: 100%">
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column label="订单号">
					<template #default="scope">
						<span>{{ scope.row.order_no }}</span>
					</template>
				</el-table-column>
				<el-table-column label="下单用户">
					<template #default="scope">
						<span>{{ scope.row.user.nickname }}</span>
					</template>
				</el-table-column>
				<el-table-column label="状态">
					<template #default="scope">
						<span>{{ scope.row.status_name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="总金额">
					<template #default="scope">
						<span>{{ scope.row.amount }}</span>
					</template>
				</el-table-column>
				<el-table-column label="收件人">
					<template #default="scope">
						<span>{{ scope.row.contact }}</span>
					</template>
				</el-table-column>
				<el-table-column label="下单时间">
					<template #default="scope">
						<span>{{ scope.row.create_time }}</span>
					</template>
				</el-table-column>
				<el-table-column label="支付时间">
					<template #default="scope">
						<span>{{ scope.row.payment_time }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="165"  fixed="right">
					<template #default="scope">
						<el-button size="mini" type="text" @click="checkDetail(scope.row)">查看订单</el-button>
						<el-button size="mini" type="text" v-if="scope.row.status >= 2" @click="shipTap(scope.row)">发货</el-button>
						<el-button size="mini" type="text" v-if="scope.row.status > 1" @click="checkDetail(scope.row)">售后</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="block" style="margin-top: 15px">
				<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="pageInfo.now"
					:page-sizes="[10, 20, 50, 100, 200]"
					:page-size="pageInfo.size"
					layout="total, sizes, prev, pager, next, jumper"
					:total="pageInfo.count"
				>
				</el-pagination>
			</div>
		</div>
		<ship v-if="shipVisible" :data="rowData" @closeTap="closeTap"></ship>
		<detail v-if="visible" :data="rowData" @closeTap="closeTap"></detail>
	</div>
</template>

<script>
import { order_index, order_del } from '@/api/order';
import ship from './component/ship.vue';
import detail from './component/detail.vue';
export default {
	components: { ship,detail },
	name: 'order',
	data() {
		return {
			visible: false,
			shipVisible: false,
			order: {},
			pageInfo: {
				now: 1,
				size: 20
			},
			tableData: [],
			loading: true,
			status: '1',
			types:[{
				label: '全部',
				name: '1',
			},{
				label: '待付款',
				name: '2',
			},{
				label: '待发货',
				name: '3',
			},{
				label: '待收货',
				name: '4',
			},{
				label: '已完成',
				name: '5',
			},{
				label: '关闭',
				name: '6',
			}]
		};
	},
	created() {
		this.initData();
	},
	methods: {
		initData() {
			var param = {
				status: this.status || undefined,
				pageSize: this.pageInfo.size || undefined,
				pageNow: this.pageInfo.now || undefined,
			};
			order_index({ ...param })
				.then((res) => {
					if (res.statusCode !== 200) {
						this.$message({
							type: 'error',
							message: res.message
						});
						return true;
					}
					this.pageInfo = res.page;
					this.tableData = res.lists;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		closeTap() {
			this.loading = true;
			this.visible = false;
			this.shipVisible = false;
			this.initData();
		},
		checkDetail(data) {
			this.loading = true;
			this.rowData = data;
			this.visible = true;
		},
		submitTap() {
			this.loading = true;
			this.initData();
		},
		handleClick(tab) {
			this.status = tab.name;
			this.initData();
		},
		batchDelTap() {
			this.loading = true;
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.loading = false;
			});
		},
		deleteTap(e) {
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.loading = true;
				order_del(e.order_id)
					.then((res) => {
						if (res.statusCode == 200) {
							this.$message({
								type: 'success',
								message: res.message
							});
							this.initData();
						}
					})
					.finally(() => {
						this.loading = false;
					});
			});
		},
		shipTap(data){
			this.loading = true;
			this.rowData = data;
			this.shipVisible = true;
		},
		handleSizeChange(val) {
			this.loading = true;
			this.pageInfo.size = val;
			this.initData();
		},
		handleCurrentChange(val) {
			this.loading = true;
			this.pageInfo.now = val;
			this.initData();
		},
	},
};
</script>
