import Vue from 'vue'
import store from '@/store';
import { Session } from '@/utils/storage.js';
/**
 * 用户权限指令
 * @directive 单个权限验证（v-auth="xxx"）
 */

/**权限指令**/
Vue.directive('auth', {
    inserted: function(el, binding) {
        // 判断是否超管
        if (store.state.userInfos.userInfos.is_liable !== 1) {
            // 判断是否有权限
            if (!Session.get('auths').some((v) => v === binding.value)) el.parentNode.removeChild(el);
        }
    }
})