<template>
	<div class="system-menu-container">
		<div :class="visible ? 'box-modal-hide' : 'box-modal-show'" style="padding: 15px">
			<el-form :model="user" size="small" label-width="100px" class="mt15 mb15">
				<el-row>
					<el-col :span="6">
						<el-form-item label="手机">
							<el-input v-model="user.mobile" placeholder="请输入手机" clearable></el-input>
						</el-form-item>
					</el-col>

					<el-col :span="24">
						<el-form-item align="right">
							<el-button icon="el-icon-refresh">清空</el-button>
							<el-button type="primary" icon="el-icon-search" @click="submitTap()">立即查询</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<el-card shadow="never">
				<div style="padding-bottom: 15px">
					<el-row>
						<el-button size="small" type="primary" icon="el-icon-edit" @click="memberTap()">会员权益</el-button>
					</el-row>
				</div>
				<el-table v-loading="loading" :data="tableData" stripe style="width: 100%">
					<el-table-column fixed type="selection" width="55"></el-table-column>
					<el-table-column fixed label="用户姓名">
						<template #default="scope">
							<span>{{ scope.row.nickname }}</span>
						</template>
					</el-table-column>
					<el-table-column label="头像">
						<template #default="scope">
							<el-avatar shape="square" :size="50" :src="scope.row.avatar_url"></el-avatar>
						</template>
					</el-table-column>
					<!-- <el-table-column label="性别">
						<template #default="scope">
							<span v-if="scope.row.sex == 1" class="color-primary">男</span>
							<span v-else>女</span>
						</template>
					</el-table-column> -->
					<el-table-column label="唯一码">
						<template #default="scope">
							<span>{{ scope.row.invite_code }}</span>
						</template>
					</el-table-column>
					<el-table-column label="openid">
						<template #default="scope">
							<span>{{ scope.row.openid }}</span>
						</template>
					</el-table-column>
					<el-table-column label="手机号">
						<template #default="scope">
							<span>{{ scope.row.mobile }}</span>
						</template>
					</el-table-column>
					<el-table-column label="注册时间">
						<template #default="scope">
							<span>{{ scope.row.created_at }}</span>
						</template>
					</el-table-column>
					<el-table-column fixed="right" label="操作" width="95">
						<template #default="scope">
							<el-button size="mini" type="text" @click="openVisible(scope.row)">查看</el-button>
							<el-button size="mini" class="color-danger" type="text" @click="deleteTap(scope.row)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="block" style="margin-top: 15px">
					<el-pagination
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange"
						:current-page="pageInfo.now"
						:page-sizes="[10, 20, 50, 100, 200]"
						:page-size="pageInfo.size"
						layout="total, sizes, prev, pager, next, jumper"
						:total="pageInfo.count"
					>
					</el-pagination>
				</div>
			</el-card>
		</div>
		<detail v-if="visible" :type="type" :data="rowData" @closeTap="handleCloseTap"></detail>
	</div>
</template>

<script>
import detail from './component/detail.vue';
import { user_index, user_del } from '@/api/user';
export default {
	components: { detail },
	name: 'user',
	data() {
		return {
			user: {},
			tableData: [],
			pageInfo: {
				now: 1,
				size: 20,
			},
			type: 1,
			rowData: {},
			rowId: 0,
			loading: true,
			visible: false,
			userList: [],
		};
	},
	created() {
		this.initData();
	},
	watch: {
		$route(to) {
			if (to.query.uid !== undefined) {
				this.checkDetail(to.query.uid);
			}
		},
	},
	methods: {
		initData() {
			var param = {
				name: this.user.name || undefined,
				uid: this.user.uid || undefined,
				mobile: this.user.mobile || undefined,
				pageSize: this.pageInfo.size || undefined,
				pageNow: this.pageInfo.now || undefined,
			};
			user_index({ ...param })
				.then((res) => {
					if (res.statusCode !== 200) {
						this.$message({
							type: 'error',
							message: res.message,
						});
						return true;
					}

					this.pageInfo = res.page;
					this.tableData = res.lists;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		checkDetail(uid) {
			this.rowId = parseInt(uid);
			this.visible = true;
		},
		openVisible(data = null) {
			if (data !== null) {
				this.type = 1;
				this.rowData = data;
			} else {
				this.rowData = {};
			}
			this.visible = true;
		},
		deleteTap(e) {
			this.$confirm('此操作将永久删除, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				this.loading = true;
				user_del(e.uid)
					.then((res) => {
						if (res.statusCode == 200) {
							this.$message({
								type: 'success',
								message: res.message,
							});
							this.initData();
						}
					})
					.finally(() => {
						this.loading = false;
					});
			});
		},
		handleCheckTap(data) {
			this.userData = data;
			this.visible = true;
		},
		handleEditTap() {
			this.checkShow = true;
		},
		submitTap() {
			this.loading = true;
			this.initData();
		},
		memberTap() {
			this.$router.push('/member');
		},
		handleCloseTap() {
			this.visible = false;
			this.loading = true;
			this.initData();
		},
		handleSizeChange(val) {
			this.loading = true;
			this.pageInfo.size = val;
			this.initData();
		},
		handleCurrentChange(val) {
			this.loading = true;
			this.pageInfo.now = val;
			this.initData();
		},
	},
};
</script>
